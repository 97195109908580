import React, { useState, useEffect, useContext, useRef } from 'react'
import {useTranslation} from "react-i18next"
import { useHistory } from 'react-router-dom'
// Firebase
import { FirebaseContext } from "../../../firebase/"
// Redux
import { useDispatch } from "react-redux"
// Components
import LayoutDashboard from 'components/layout/LayoutDashboard'
import ContentIsEmpty from 'components/elements/divContainer/ContentIsEmpty'
import Headings from 'components/elements/Typography/Headings'
import Button from 'components/elements/button/Button'
import TableProjects from 'components/applicationUi/TableProjects'
import Pagination from 'components/elements/pagination/Pagination'
import InputSearch from 'components/forms/search/InputSearch'
// import Alert from 'components/modals/Alert'

import {RiDeleteBin5Line, RiBuildingLine} from 'react-icons/ri'
// Utils
import { makeCaseAndAccentInsensitiveString } from 'utils/stringUtils'

import {
  PROJECT,
  MAX_USER_SELECTION,
  MAX_PAGINATION_DASHBOARD_EXTENDED,
  PROJECTS_MENU
} from 'utils/constantsBackOffice'
import { sortListDashboard } from 'utils/sortListDashboard'
// Hooks
import useSearchItemsProcess from 'hooks/useSearchItemsProcess'
import usePagination from 'hooks/usePagination'
// Others
import 'assets/style/dashboard.css'


const DashboardProjects = ({selectedMenu, currentUser}) => {
  // Translations
  const { t } = useTranslation(['common', 'dashboard', 'messages'])

  const firebase = useContext(FirebaseContext)
  const history = useHistory()

  const refInputName = useRef()

  const [ 
    searchingList,
    setSearchingList, 
    makeItemSearch
  ] = useSearchItemsProcess()

  const [
    currentPage,
    paginatedData,
    totalPages,
    isLoadingPage,
    tableMetrics,
    ,
    returnPrevPage,
    returnNextPage,
    selectPage,
    setDataPagination
  ] = usePagination(MAX_PAGINATION_DASHBOARD_EXTENDED)
  
  const [dataProjects, setDataProjects] = useState([])

  const [dataLoaded, setDataLoaded] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertSelectLength, setAlertSelectLength] = useState(null)
  const [displayUserForm, setDisplayUserForm] = useState(false)
  const [displayUserInvitation, setDisplayUserInvitation] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [filteredList, setFilteredList] = useState([])

  const [selectedProject, setSelectedProject] = useState(undefined)
  const [selectedProjects, setSelectedProjects] = useState([])
  const [tableSelectedMetrics, setTableSelectedMetrics] = useState('')

  const [statusFilter, setStatusFilter] = useState(null)

  const getFilteredList = sortListDashboard(dataProjects, PROJECT)


  const getProjects = () => {
    if (currentUser) {
      setDataLoaded(false)

      const collectionRef = firebase.db.collection("projects");
      let query = collectionRef;
      // collectionRef.orderBy('subdomain');

      query.get()
        .then((snapshot) => {
          setDataLoaded(true)
          const users = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              name: doc.data()?.name ? doc.data().name : doc.id
            }
          })
          setDataProjects(users)
        })
    }
  }

  const clearSearch = () => {
    setSearchValue('')
    makeItemSearch('', filteredList, PROJECT)
  }

  const searchProjects = (e) => {
    let normalizedValue = makeCaseAndAccentInsensitiveString(e.target.value)
    let data = filteredList

    setSearchValue(normalizedValue)
    makeItemSearch(normalizedValue, data, PROJECT)
  }

  const openEditForm = (user) => {
    setSelectedProject(user)
    setDisplayUserForm(true)
  }

  // const closeForm = (refresh = false) => {
  //   setSelectedProject(undefined)
  //   setDisplayUserForm(false)
  //   setDisplayUserInvitation(false)
  //
  //   refresh && getProjects()
  // }

  const openInvitationModal = (user) => {
    setSelectedProject(user)
    setDisplayUserInvitation(true)
  }

  /* Get datas */
  useEffect(() => {
    setDataLoaded(false)
    setSearchingList(null)
    setSearchValue('')
    setSelectedProjects([])

    if( currentUser ){
      getProjects()
    } else {
      history.push('/dashboard/projects/projects')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  /* Update datas */
  useEffect(() => {
      let data = getFilteredList

      setFilteredList(data)

      if (searchingList !== null) {
        makeItemSearch(searchValue, data, PROJECT)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjects])

  /* Get datas with status filter */
  useEffect(() => {
    if( statusFilter ){
      getProjects()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter])

  /* Update metrics after select users */
  useEffect(() => {
    if( selectedProjects.length > MAX_USER_SELECTION ){
      setSelectedProjects(selectedProjects.slice(0,(MAX_USER_SELECTION)))
      setAlertSelectLength(true)
    }

    if( selectedProjects.length > 0 ){
      setTableSelectedMetrics(`${selectedProjects.length} ${selectedProjects.length > 1 ? t('selected_plural', {ns:'common'}) : t('selected', {ns:'common'}) }`)
    } else {
      setTableSelectedMetrics('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects])

  /* Pagination */
  useEffect(() => {
    setDataPagination(searchingList, filteredList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList, searchingList, currentPage])

  /* Autofocus input modal */
  useEffect(() => {
    displayUserForm === true ? refInputName?.current?.focus() : refInputName?.current?.blur()
  }, [displayUserForm])

  useEffect(() => {
    document.title = `Memory - ${t('projects.Projects-management', {ns: 'dashboard'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutDashboard
        currentUser={currentUser}
        // headerNav={SUB_NAV_MEMBERS}
        headerNavSelected={PROJECTS_MENU}
        selectedMenu={selectedMenu}
        // subNav={ {type: NAV_LINK_ROUTER, items: SUB_NAV_MEMBERS} }
        title={t('projects.Projects-management', {ns: 'dashboard'})}
        description={t('projects.Projects-global-text', {ns:'dashboard'})}
        noticeLink={{
          href: "https://beecomeio.notion.site/Configuration-de-votre-espace-adf43d3fcf564cd0a1d5a25ec3bb1508#9d72072d9c3647738c95962b549b5f53",
          label: t('projects.global-help', {ns:'dashboard'})
        }}
        enableScroll={true}
      >
        <header className="mb-2 sm:mb-8 pt-4">
          <ul className="flex justify-between items-center gap-2 mb-2">
            <li className="flex items-center gap-2">
              <RiBuildingLine className="w-5 h-5 min-w-5" />
              <Headings variant="h2" display="display-4">{t('projects.Projects', {ns:'dashboard'})}</Headings>
            </li>
            <li className="flex gap-4">
              {(currentUser?.is_administrator === true || currentUser?.authorizations?.administration?.projects?.delete === true) &&
                <Button
                  className="h-max justify-center sm:justify-items-start sm:self-center"
                  size="small"
                  icon={<RiDeleteBin5Line className="w-4 h-4"/>}
                  iconPosition="right"
                  actionType={"action"}
                  action={() => setAlert(true)}
                  theme={selectedProjects.length > 0 ? 'secondary' : 'disabled'}
                  animate={false}
                />
              }

              {/*{(currentUser?.is_administrator === true || currentUser?.authorizations?.administration?.projects?.create === true) &&*/}
              {/*  <ButtonDropDown*/}
              {/*    className="flex items-center"*/}
              {/*    buttonClassName={'h-max flex sm:justify-items-start sm:self-center items-center group font-bold min-w-max bg-purpulePrimary hover:bg-purpuleSecondary border-t border-l border-r border-purpulePrimary hover:border-purpulePrimary text-white text-xs py-1.5 px-4 rounded shadowMainButton'}*/}
              {/*    buttonShadow={true}*/}
              {/*    dropDownClassName="top-full"*/}
              {/*    position="sm:right-0"*/}
              {/*    type="action"*/}
              {/*    theme="color"*/}
              {/*    data={[*/}
              {/*      {*/}
              {/*        id: 1,*/}
              {/*        name: `${t('action.Create', {ns: 'common'})} ${t('user.user_account_one', {ns:'common'})}`,*/}
              {/*        action: () => setDisplayUserForm(true),*/}
              {/*      }*/}
              {/*    ]}*/}
              {/*  >*/}
              {/*    <div className="flex items-center">*/}
              {/*      <RiAddLine className="w-4 h-4"/>*/}
              {/*    </div>*/}
              {/*  </ButtonDropDown>*/}
              {/*}*/}
            </li>
          </ul>
          <article className="flex flex-col text-sm max-h-10 overflow-auto memoryScrollbar lg:max-h-full lg:overflow-hidden">
            {/*<p>{t('projects.Infos-projects', {ns:'dashboard'})}</p>*/}
            {/*<p>{t('projects.Infos-projects-tip', {ns:'dashboard'})}</p>*/}
          </article>
        </header>

        {/* SEARCH */}
        <>
          {(currentUser?.is_administrator === true ||
            currentUser?.authorizations?.administration?.projects?.list === true) &&
            <span className="flex flex-col gap-2 w-full mb-2 sm:flex-row">
              <InputSearch
                labelHidden
                name="search-user"
                loading={!dataLoaded}
                placeHolder={t('action.Search-in-list')}
                className="inline-flex"
                setstate={(e) => searchProjects(e)}
                state={searchValue}
                clear={true}
                setClear={() => clearSearch()}
              />
            </span>
          }
        </>

        {/* TABLE */}
        {(currentUser?.is_administrator === true ||
          currentUser?.authorizations?.administration?.projects?.list === true) ?

          (dataLoaded && dataProjects?.length === 0) ?
            <ContentIsEmpty>
              {t('projects.empty-projects', {ns:'dashboard'})}
            </ContentIsEmpty>
          :
            <>
              {/*<TableMetrics tableMetrics={tableMetrics} tableSelectedMetrics={tableSelectedMetrics} />*/}
              <div className={`flex items-center text-xs mb-2`}>
                <span className="">
                  {tableMetrics}
                  {tableSelectedMetrics?.length > 0 ?
                    <span> (<strong>{tableSelectedMetrics}</strong>)</span>
                    :
                    null
                  }
                </span>
              </div>

              <TableProjects
                list={paginatedData}
                openEditForm={openEditForm}
                openInvitationModal={openInvitationModal}
                dataLoaded={dataLoaded}
                setState={setSelectedProjects}
                state={selectedProjects}
                currentUser={currentUser}
              />

              {dataLoaded &&
                <div className="flex justify-center mt-3">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onClickPage={selectPage}
                    onClickPrevious={returnPrevPage}
                    onClickNext={returnNextPage}
                    isloading_card={isLoadingPage}
                  />
                </div>
              }
            </>
          :
          <ContentIsEmpty>
            {t('alert.access-denied-to-this-page', {ns: 'messages'})}
          </ContentIsEmpty>
        }
      </LayoutDashboard>

      {/*{displayUserForm &&*/}
      {/*  <UserForm*/}
      {/*    closeForm={(refresh) => closeForm(refresh)}*/}
      {/*    user={selectedProject}*/}
      {/*    refInput={refInputName}*/}
      {/*    roles={roles}*/}
      {/*  />*/}
      {/*}*/}

      {/*{displayUserInvitation &&*/}
      {/*  <UserSendInvitation*/}
      {/*    closeForm={(refresh) => closeForm(refresh)}*/}
      {/*    user={selectedProject}*/}
      {/*  />*/}
      {/*}*/}

      {/*{displayImportForm && */}
      {/*  <UserImportForm*/}
      {/*    closeForm={() => setDisplayImportForm(false)}*/}
      {/*    userType={USER}*/}
      {/*    roles={roles}*/}
      {/*  />*/}
      {/*}*/}
    </>
  )
}

export default DashboardProjects
