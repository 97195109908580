/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useEffect, useState} from 'react'
// Components
import { Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { RiArrowRightSLine } from "react-icons/ri"
// import {TrackGoogleAnalyticsEvent} from "utils/googleAnalytics";


export default function ButtonDropDown(props) {

  const {
    buttonShadow = false,
    children,
    className,
    data,
    defaultClassName = true,
    dropDownSize,
    enableTracking = false,
    maxHeight,
    position = 'right-0',
    state,
    setstate,
    setOpen = null,
    type
  } = props

  let {
    buttonClassName,
    dropDownClassName
  } = props

  const [componentReady, setComponentReady] = useState(false)
  const [firstOpen, setFirstOpen] = useState(false)

  if (!buttonClassName){
    buttonClassName = "rounded-md border border-gray-300 px-4 py-2 bg-white text-xs font-semibold text-grayPrimary hover:bg-gray-50"
  }

  const handleClick = (open) => {
    if(enableTracking === true && open === false){
      // TrackGoogleAnalyticsEvent(
      //   gaTrackingData.category ? gaTrackingData.category : '',
      //   gaTrackingData.action ? gaTrackingData.action : '',
      //   gaTrackingData.label ? gaTrackingData.label : ''
      // );
    }
  }

  const returnClassnames = (disabled) => {
    if (disabled) {
      return 'block px-4 py-2 text-sm w-full my-1 text-left text-gray-500 cursor-not-allowed'
    } else {
      return 'block px-4 py-2 text-sm w-full my-1 text-left'
    }
  }

  const isOpen = (open) =>{
    if(setOpen && componentReady === true) {
      if(!open && !firstOpen){
        return
      }

      setOpen(open)
      if(!firstOpen){
        setFirstOpen(true)
      }
    }
  }

  useEffect(() => {
    setComponentReady(true)
  }, [])

  const renderLink = (id, data) => {
    return (
      <div key={id}>
        <Menu.Item>
          {({ active }) => (
            <Link to={`${data.path}`}
              className="block text-sm text-grayPrimary hover:text-black bg-white hover:bg-gray-100 truncate animate"
            >
              <p className="truncate px-5 py-2">{data.tradKey ? data.tradKey : data.name}</p>
            </Link>
          )}
        </Menu.Item>
      </div>
    )
  }

  const renderAction = (id, data) => {
    return (
      <div key={id}>
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => data.action(data.actionObj ? data.actionObj : null)}
              className="block px-5 py-2 w-full text-left text-sm text-grayPrimary hover:text-black bg-white hover:bg-gray-100 truncate animate flex items-center"
            >
              {data.icon &&
                data.icon
              }
              {data.name}
            </button>
          )}
        </Menu.Item>
      </div>
    )
  }

  return (
    <Menu as="div" className={`${className} ${defaultClassName===true && 'relative inline-block text-left animate overflow-visible'}`}>
      {({ open }) => {
        isOpen(open)
        return (
          <>
              <Menu.Button
                className={`inline-flex justify-center w-full animate ${buttonClassName}`}
                onClick={() => {handleClick(open)}}
              >
                {children}
              </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className={`${dropDownClassName ? dropDownClassName : ''} ${maxHeight ? maxHeight : 'max-h-[450px]'} overflow-y-auto origin-top-left md:origin-top-right absolute z-60 ${position ? position : 'left-0 md:left-auto md:right-0'} mt-2 ${dropDownSize ? dropDownSize : 'w-56'} rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                <div className="py-1">
                  {type === "link" ?
                    data.map((data, id) => (
                      data && data.type === 'action' ?
                        renderAction(id, data)
                      : data && data.path ?
                        renderLink(id, data)
                      : data.type === 'submenu' && data.items?.length > 0 ?
                        <div key={id}>
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                className="flex relative overflow-visible text-sm text-grayPrimary hover:text-black bg-white hover:bg-gray-100 animate"
                              >
                                <span className="inline-flex items-center truncate px-5 py-2 w-full">
                                  {data.tradKey ? data.tradKey : data.name}
                                  <RiArrowRightSLine className="w-4 h-4 ml-2 inline-flex" />
                                </span>
                                <div
                                  className={`${active ? 'visible' : 'hidden'} inline-block top-0 relative`}
                                >
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      className={`${dropDownClassName} absolute z-60 top-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                    >
                                      <div className="py-1">
                                        {data.items.map((item) => (
                                          <div key={item.id}>
                                            <Menu.Item>
                                              <Link to={`${item.path}`}
                                                className="block text-sm text-grayPrimary hover:text-black bg-white hover:bg-gray-100 truncate animate"
                                              >
                                                <p className="truncate px-5 py-2">{item.tradKey ? item.tradKey : item.name}</p>
                                              </Link>
                                            </Menu.Item>
                                          </div>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </div>
                              </span>
                            )}
                          </Menu.Item>
                        </div>
                        :
                          null
                    ))
                    : null }

                  {type === "addFilters" ?
                    data.map((data, id) => (
                      <div key={id}>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={state.includes(data.name)}
                              onClick={() => setstate(data.collection)}
                              className={returnClassnames(state.includes(data.collection))}
                            >
                              {data.name}
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    ))
                  : null }

                  {type === "action" ?
                    data.map((data, id) => (
                      !data ? null :
                        renderAction(id, data)
                    ))
                  : null }

                  {type === "share" ?
                    data.map((button, id) => (
                      !button ? null :
                        <div key={id}>
                          <Menu.Item>
                            {button}
                          </Menu.Item>
                        </div>
                    ))
                  : null }
                  
                  {type === "icons" ?
                    <div className="grid grid-cols-5 px-2">
                      {data.map((icon, index) => (
                        !icon ? null :
                          <div 
                            key={index} 
                            className={`
                              group w-fit h-fit h p-1 rounded-full animate hover:bg-memoryBlueTertiary 
                              ${icon.props.selected ? 'bg-memoryBlueTertiary': 'bg-transparent cursor-pointer'}
                            `}
                          >
                            <Menu.Item>
                              {icon}
                            </Menu.Item>
                          </div>
                      ))}
                    </div>
                  : null }

                </div>
              </Menu.Items>
            </Transition>
          </>
        )}}
    </Menu>
  )
}
