import React, { useState } from "react"
import {RiCloseLine} from "react-icons/ri";


const InputText = ({
	characterLimits,
	className,
	clearEnable = false,
	clearFunction = null,
	id,
	inputClassName = '',
	label,
	labelHidden,
	loading,
	max,
	min,
	name,
  onBlur= null,
	onKeyPress= null,
	placeHolder,
	refInput,
	required,
	setstate,
	size = 'default',
	state,
	type,
}) => {

  const [defaultState, setDefaultState] = useState("")

  const defaultHandleChange = (e) => {
    setDefaultState(e.target.value)
  }

  const clearInput = () => {
		refInput.current.value = '';

		if(clearFunction){
			clearFunction();
		}
  }

  // if props state & setstate is not defined
  if (!state) state = defaultState
  if (!setstate) setstate = defaultHandleChange


  return (
		<div className={`${className}`}>
			{labelHidden ? null : (
				<div className="flex items-center justify-between gap-4">
					<label htmlFor={name} className="block text-sm font-medium">
						{label} {required ? " *" : null}
					</label>
					{characterLimits ? (
						<span className="text-grayPrimary text-sm">
							{type !== "number" ? `${state.length}/${characterLimits}` : ``}
						</span>
					) : null}
				</div>
			)}
			<div className="mt-1 relative rounded">
				<input
					disabled={loading}
					onChange={(e) => setstate(e)}
					onKeyPress={onKeyPress ? e => onKeyPress(e) : null}
					value={state}
					type={type ? type : "text"}
					name={name}
					id={id ? id : name}
					className={`block shadow-sm ${size === 'small' ? 'w-1/3' : 'w-full'} h-10 px-5 sm:text-sm border border-grayLight rounded-md disabled:bg-grayLight ${loading && "cursor-not-allowed"} ${inputClassName}`}
					placeholder={placeHolder}
					maxLength={characterLimits ? characterLimits : null}
					autoComplete="off"
					ref={refInput}
					required={required ? required : false}
					min={min ? min : null}
					max={max ? max : null}
					onBlur={onBlur ? e => onBlur(e) : null}
				/>

				{(state && clearEnable) &&
					<span
						onClick={() => clearInput()}
						className="absolute inset-y-0 right-0 flex items-center cursor-pointer"
					>
						<div className="absolute z-10 inset-y-0 right-0 p-3 flex items-center">
							<span className="text-black sm:text-sm" >
								<RiCloseLine className="w-4 h-4" />
							</span>
						</div>
					</span>
				}
			</div>
		</div>
  )
}

export default InputText
