import React from 'react'
// Components
import { Link } from 'react-router-dom'
import LoaderComponent from '../loader/Loader'

/**
 * Possibilité de props :
 * Type : flat, borderBottom, gradiant, disabled
 * Theme : Primary, secondary, simple, label  
 * size : small, medium, big
 * Label : true, false (pas de texte juste une icone)
 * ico : possibilité d'indiquer une icône
 * ico position : left, right 
 * buttonPosition : left, center, right
 * actionType : route, link, action
 * action : route, link, function
 * loading : intègre un loader
 */

const ButtonIcon = (props) => {

  const {
    children, // label button
    className,
    icon,
    theme,
    size,
    loading,
    border
  } = props

  let { actionType, action }= props

  const label = children
  const Icon = icon

  const handleClick = (e) => {
    e.preventDefault()
    if( actionType === "actionWithEvent" ){
      action(e)
    } else {
      action()
    }
  }

  if (!action) action = "/"
  if (!actionType) actionType = "route"

  // Theme = Style de bouton
  let borderBottom, themeClass
  switch (theme) {   
    case 'primary' : 
      themeClass = "bg-memoryBluePrimary hover:bg-memoryBlueSecondary border border-memoryBluePrimary hover:border-memoryBluePrimary text-white"
      borderBottom = !border ? false : true
      break 
    case 'secondary' : 
      themeClass = "bg-white hover:bg-memoryBluePrimary border-t border-l border-r border-memoryBluePrimary hover:border-memoryBluePrimary text-memoryBluePrimary hover:text-white"
      borderBottom = true
      break
    case 'simple' : 
      themeClass = "bg-white hover:bg-memoryBluePrimary border border-memoryBluePrimary hover:border-memoryBluePrimary text-memoryBluePrimary hover:text-white"
      borderBottom = !border ? false : true
      break
    case 'simple-gray' : 
      themeClass = "bg-white hover:bg-memoryBluePrimary border border hover:border-memoryBluePrimary text-memoryBluePrimary hover:text-white"
      borderBottom = !border ? false : true
      break
    case 'simple-icon' :
      themeClass = "text-grayLight hover:text-grayPrimary p-0"
      borderBottom = false
      break
    case 'transparent' : 
      themeClass = "bg-transparent hover:bg-grayLight text-grayPrimary hover:text-white"
      borderBottom = false
      break
    case 'disabled' : 
      themeClass = "bg-grayLight text-grayPrimary cursor-not-allowed"
      borderBottom = false
      break
    default : 
      themeClass = "bg-memoryBluePrimary hover:bg-memoryBlueSecondary border-t border-l border-r border-memoryBluePrimary hover:border-memoryBluePrimary text-white"
      borderBottom = true
  }

  // Size = Taille bouton
  let sizeClass
  switch (size) {
    case 'small' : 
      sizeClass = "text-xs p-1 rounded"
      break      
    case 'big' : 
      sizeClass = "text-base p-5 rounded"
      break
    default : 
      sizeClass = "text-sm p-3 rounded"
  }

  if (actionType === 'action' || actionType === 'actionWithEvent') {
    return (
      <button 
        onClick={theme !== 'disabled' ? (e) => handleClick(e) : null}
        className={`${className} flex items-center group font-bold ${themeClass} ${sizeClass} animate `}
        style={{ boxShadow: borderBottom ? '0px 3px 0px 0px #272670' : null }}
      >
        {loading ?  <LoaderComponent size={5} color="#ffffff" /> : 
          <>
            {Icon}
            {label} 
          </>
        }
      </button>
    )
  }
  else if (actionType === 'form') {
    return (
      <button 
        className={`${className} flex items-center group font-bold ${themeClass} ${sizeClass} animate`}
        style={{ boxShadow: borderBottom ? '0px 3px 0px 0px #272670' : null }}
      >
        {loading ?  <LoaderComponent size={5} color="#ffffff" /> : 
          <>
            {Icon}
            {label} 
          </>
        }
      </button>
    )
  }
  else if (actionType === 'externalLink'){
    return (
      <button className={`${className} block`}>
        <a 
          href={action} 
          target="_blank"
          rel="noreferrer"
          // download
          className={`group flex items-center font-bold ${themeClass} ${sizeClass}`}
          style={{ boxShadow: borderBottom ? '0px 3px 0px 0px #272670' : null }}
        >
          {Icon} {label}
        </a> 
      </button>
    )
  }
  else {
    return (
      <button className={`${className} block`}>
        <Link 
          to={action} 
          className={`group flex items-center font-bold ${themeClass} ${sizeClass}`}
          style={{ boxShadow: borderBottom ? '0px 3px 0px 0px #272670' : null }}
        >
          {Icon} {label}
        </Link> 
      </button>
    )
  }
}

export default ButtonIcon
