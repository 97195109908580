import React, {useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom'
import LogoApp from 'assets/images/app/svg/Logo.svg'
// import {TrackGoogleAnalyticsEvent} from "utils/googleAnalytics";


const Logo = (props) => {

  const {
    entity,
    enableTracking = false,
    gaTrackingData = {},
    link = '/',
    logoVisible = 'logo',
    size,
    tooltip,
    type
  } = props

  const [logo, setLogo] = useState('');

  useEffect(() => {
    if(type === 'avatar'){
      setLogo(entity && entity.custom?.general?.avatar && entity.custom.general.avatar.length > 0 ? entity.custom.general.avatar : entity && entity.custom?.general?.logo && entity.custom.general.logo.length > 0 ? entity.custom.general.logo : '')
    } else if(logoVisible === 'logo_header_landing'){
      setLogo(entity && entity.custom?.general?.logo_header_landing && entity.custom.general.logo_header_landing.length > 0 ? entity.custom.general.logo_header_landing : entity && entity.custom?.general?.logo && entity.custom.general.logo.length > 0 ? entity.custom.general.logo : '')
    } else {
      setLogo(entity && entity.custom?.general?.logo && entity.custom.general.logo.length > 0 ? entity.custom.general.logo : '')
    }
  }, [logoVisible]);

  const handleClickTracking = () => {
    if(enableTracking === true){
      // TrackGoogleAnalyticsEvent(
      //   gaTrackingData.category ? gaTrackingData.category : '',
      //   gaTrackingData.action ? gaTrackingData.action : '',
      //   gaTrackingData.label ? gaTrackingData.label : ''
      // );
    }
  }

  return (
    <NavLink
      to={link}
      onClick={handleClickTracking}
    >
      <img 
        src={logo.length > 0 ? logo : LogoApp}
        alt=""
        className={size}
        title={tooltip}
        tabIndex={0}
      />
    </NavLink>
  )
}

export default Logo