import React from 'react'
// Components
import Container from '../layout/Container'
import Headings from '../Typography/Headings'
// Others
import Logo from 'assets/images/app/svg/pictoElephant.svg'

const ContentIsEmpty = ({margin, children, button}) => {


  return (
    <Container>
      <div className={margin ? `${margin} max-w-sm mx-auto` : "mt-40 max-w-sm mx-auto"}>
        <div className="flex flex-col items-center p-10 text-center">
          <img src={Logo} alt="" className="w-12 h-12 opacity-50" />
          <Headings
            variant="h1"
            display="display-5"
            className="mt-2 text-grayPrimary"
            weight="bold"
          >
            {children}
          </Headings>
          {button && button}
        </div>
      </div>
    </Container>
  )
}

export default ContentIsEmpty