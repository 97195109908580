/**
 * Page de récupération de mot de passe
*/
import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
// Firebase
import { FirebaseContext } from '../../firebase'
// Redux
import { useDispatch } from 'react-redux'
import { PlayNotifications } from '../../redux/notifications/PlayNotifications'
// Components
import InputEmail from '../../components/forms/email/InputEmail'
import Button from '../../components/elements/button/Button'
// import Navigation from '../../components/marketing/navigation/NavBarAuthentication'
import { RiArrowLeftLine, RiCheckLine } from 'react-icons/ri'


const ForgetPassword = ({entity}) => {

  // Translations
  const { t } = useTranslation(['common', 'messages'])

  const dispatch = useDispatch()
  const firebase = useContext(FirebaseContext)
  
  const [email, setemail] = useState("")
  const [loading, setloading] = useState(false)

  useEffect(() => {
    document.title = `${entity && entity.custom?.general?.name && entity.custom?.general?.name.length > 0 ? entity.custom?.general?.name : 'Memory'} - ${t('password.password-recovery', {ns: 'common'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const handleSubmit = (e) => {

    e.preventDefault()

    if (email !== '') {

      setloading(true)

      const resetPassword = firebase.functions.httpsCallable("resetPassword")

      resetPassword({
        email: email,
      })
      .then((result) => {
        if(result.data?.success){
          dispatch(
            PlayNotifications(
              {
                display:true,
                theme:"success",
                message: t('success.email-password-recovery', {ns: 'messages', val: email}),
              }
            )
          )
          setemail("")
          setloading(false)
        } else {
          const error = result.data?.error ? result.data.error : null
          console.error("Error reset password: ", result.data?.error ? result.data.error : '', result.data?.message ? result.data.message : '' )

          let errorMessage
          if (error.code === "auth/too-many-requests") {
            errorMessage =  t('error.account-temporarily-disabled', {ns: 'messages'})
          }
          else if (error.code === "auth/network-request-failed") {
            errorMessage =  t('error.network-authentification-error', {ns: 'messages'})
          }
          else errorMessage = t('error.something-wrong-try-again-later', {ns: 'messages'})

          dispatch(
            PlayNotifications(
              {
                display: true,
                theme: "danger",
                message: errorMessage,
              }
            )
          )

          setloading(false)
        }
      })
      .catch((error) => {
        console.error("Error reset password: ", error)
        let errorMessage
        if (error.code === "auth/user-not-found") {
          errorMessage = t('error.no-account-associated-with-this-email', {ns: 'messages'})
        }
        else if (error.code === "auth/too-many-requests") {
          errorMessage =  t('error.account-temporarily-disabled', {ns: 'messages'})
        }
        else if (error.code === "auth/network-request-failed") {
          errorMessage =  t('error.network-authentification-error', {ns: 'messages'})
        }
        else errorMessage = t('error.something-wrong-try-again-later', {ns: 'messages'})

        dispatch(
          PlayNotifications(
            {
              display: true,
              theme: "danger",
              message: errorMessage,
            }
          )
        )
        setloading(false)
      });
    }
  }

  return (
    <div className="h-screen flex flex-col">
      {/*<Navigation />*/}
      <div className="h-full flex items-center justify-center">
        <div className="w-full h-full px-7">
          <div className="relative">
            <Button
              action="/login/"
              theme="simple"
              size="small"
              icon={<RiArrowLeftLine className="w-4 h-4 mr-1" />}
              className="absolute z-10 top-5 md:top-7 md:left-16"
            >
              {t('action.Back', {ns: 'common'})}
            </Button>
          </div>

          <div className="max-w-md mx-auto relative h-full md:py-36 flex flex-col justify-around">
            <div>
              <h4
                className="text-center mb-4"
              >
                {t('password.password-recovery', {ns: 'common'})}
              </h4>
              <p className="text-xs text-grayPrimary text-center mb-4">
                {t('password.password-recovery-infos', {ns: 'common'})}
              </p>

              <form onSubmit={handleSubmit}>
                <InputEmail 
                  className="mb-4" 
                  state={email}
                  setstate={setemail}
                  loading={loading}
                />
                <Button
                  actionType="form" 
                  className="mx-auto mt-4"
                  theme={!email ? 'disabled' : null}
                  loading={loading}
                  icon={<RiCheckLine className="mr-1" />}
                >
                  {t('action.Validate', {ns: 'common'})}
                </Button>
              </form>
            </div>
            <div>
              <a href="mailto:contact@my-memory.io"
                className="text-sm text-grayPrimary block text-center mt-2"
              >
                <span className="border-b">{t('action.Contact-an-administrator', {ns: 'common'})}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
}

export default ForgetPassword
