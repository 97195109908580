import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { RiArrowDownSLine, RiCheckLine } from 'react-icons/ri'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Select = ({
  name,
  label, 
  className,  
  data, 
  icon,
  state,  
  setstate,
  labelHidden,
  required,
  disabled = false
}) => {

  return (
    <div className={`${className} relative z-20`}>
      <Listbox value={state} onChange={setstate} disabled={disabled}>
        {({ open }) => (
          <>
            
            {labelHidden ? null :
              <div className="flex items-center justify-between">
                <label htmlFor={name} className="block text-sm font-medium">
                  {label}{required ? ' *' : null}
                </label>
              </div>
            }

            <div className={`${labelHidden ? '' : 'mt-1'} relative`}>
              <Listbox.Button className={`relative w-full h-10 pl-3 pr-10 sm:text-sm border border-grayLight rounded-md text-left ${disabled ? 'bg-grayLight' : 'bg-white'}`}>
                <span className="flex items-center truncate">
                  <span className="text-grayPrimary">
                    {icon}
                  </span>
                  {state.name ? state.name : ''}
                  </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <RiArrowDownSLine className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {data.length > 0 && data.map((data) => (

                    <Listbox.Option
                      key={data.id}
                      className={({ active }) =>
                      classNames(
                        active ? 'bg-grayPrimary bg-opacity-10' : 'text-gray-900',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9 animate'
                      )
                      }
                      value={data}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center')}>
                            <span className="text-grayPrimary">
                              {icon}
                            </span>
                            {data.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? null : 'text-grayPrimary',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <RiCheckLine className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

export default Select
