import React from 'react'
// Components
import { Link } from 'react-router-dom'
import LoaderComponent from '../loader/Loader'
import {Fade, Tooltip} from "@mui/material";

/**
 * Possibilité de props :
 * Type : flat, borderBottom, gradiant, disabled
 * Theme : Primary, secondary, simple, label
 * size : small, medium, big
 * Label : true, false (pas de texte juste une icone)
 * ico : possibilité d'indiquer une icône
 * ico position : left, right
 * buttonPosition : left, center, right
 * actionType : route, link, action
 * action : route, link, function
 * loading : intègre un loader
 */

const Button = (props) => {
	
	const { 
		children, 
		className = '',
		classNameLink = '',
		icon, 
		iconPosition, 
		theme, 
		size,
		loading, 
		animate = true ,
		tooltip = false,
		tooltipText
	} = props

  let { actionType, action, type } = props

	const label = children
	const Icon = icon

	if (!action) action = "/"
	if (!actionType) actionType = "route"
	if (!type) type = "button"

	// Theme = Style de bouton
	let borderBottom, themeClass
	switch (theme) {
		case "secondary":
			themeClass =
				"bg-white hover:bg-purpulePrimary border-t border-l border-r border-purpulePrimary hover:border-purpulePrimary text-purpulePrimary hover:text-white"
			borderBottom = true
			break
		case "simple":
			themeClass =
				"bg-white hover:bg-purpulePrimary border border-grayLight hover:border-purpulePrimary text-grayPrimary hover:text-white"
			borderBottom = false
			break
		case "disabled":
			themeClass = "bg-grayLight text-grayPrimary cursor-not-allowed"
			borderBottom = false
			break
		case "danger":
			themeClass =
				"bg-redPrimary hover:bg-redSecondary border-t border-l border-r border-redPrimary hover:border-redSecondary text-white hover:text-white"
			borderBottom = false
			break
		case "success":
			themeClass =
				"bg-greenPrimary hover:bg-greenSecondary border-t border-l border-r border-greenPrimary hover:border-greenSecondary text-white hover:text-white"
			borderBottom = false
			break
		case "primaryNoBorder":
			themeClass =
			"bg-purpulePrimary hover:bg-purpuleSecondary border-t border-l border-r border-purpulePrimary hover:border-purpulePrimary text-white"
			borderBottom = false
			break
		default:
			themeClass =
				"bg-purpulePrimary hover:bg-purpuleSecondary border-t border-l border-r border-purpulePrimary hover:border-purpulePrimary text-white"
			borderBottom = true
	}

	// Size = Taille bouton
	let sizeClass
	switch (size) {
		case "nosize":
			sizeClass = "text-xs rounded"
			break
		case "small":
			sizeClass = "text-xs py-1.5 px-4 rounded"
			break
		case "small-icon":
			sizeClass = "py-3 px-1.5 rounded"
			break
		case "small-xs":
			sizeClass = "text-xs py-2 px-4 rounded-md"
			break
		case "medium-icon":
			sizeClass = "py-4 px-2 rounded-md"
			break
		case "big":
			sizeClass = "text-base py-2.5 px-6 rounded-md"
			break
		default:
			sizeClass = "text-sm py-2 px-5 rounded-md"
	}

	if (actionType === "action") {
		const renderButton = <button
			onClick={theme !== "disabled" ? () => action() : null}
			className={`${className} flex items-center group font-bold min-w-max ${themeClass} ${sizeClass} ${animate ? 'animate' : ''}`}
			style={{boxShadow: borderBottom ? "0px 3px 0px 0px #272670" : null}}
			type={type}
		>
			{loading ? (
				<LoaderComponent size={5} color="#ffffff" />
			) : (
				<>
					{!iconPosition ? (
						<>
							{Icon} {label}
						</>
					) : (
						<>
							{iconPosition === 'left' && Icon} {label} {iconPosition === 'right' && Icon}
						</>
					)}
				</>
			)}
		</button>

		if (tooltip === true) {
			return (
				<Tooltip
					title={tooltipText}
					arrow={true}
					placement="bottom"
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 300 }}
				>
					{renderButton}
				</Tooltip>
			)
		}

		return renderButton
	} else if (actionType === "form") {
		return (
			<button
				className={`${className} flex items-center group font-bold min-w-max ${themeClass} ${sizeClass} ${animate ? 'animate' : ''}`}
				style={{	boxShadow: borderBottom ? "0px 3px 0px 0px #272670" : null}}
			>
				{loading ? (
					<LoaderComponent size={5} color="#ffffff" />
				) : (
					<>
						{!iconPosition ? (
							<>
								{Icon} {label}
							</>
						) : (
							<>
								{iconPosition === 'left' && Icon} {label} {iconPosition === 'right' && Icon}
							</>
						)}
						</>
				)}
			</button>
		)
	} else if (actionType === "externalLink") {
		return (
			/* Basic link */
			<button className={`${className} block`} type={type}>
				<a
					href={action}
					target="_blank"
					rel="noreferrer"
					className={`${classNameLink} group flex items-center font-bold ${themeClass} ${sizeClass}`}
					style={{boxShadow: borderBottom ? "0px 3px 0px 0px #272670": null}}
				>
					{!iconPosition ? (
						<>
							{Icon} {label}
						</>
					) : (
						<>
							{iconPosition === 'left' && Icon} {label} {iconPosition === 'right' && Icon}
						</>
					)}
				</a>
			</button>
		)
	} else {
		return (
			/* Client navigation link */
			<button className={`${className} block min-w-max`} type={type}>
				<Link
					to={action}
					className={`${classNameLink} group flex items-center font-bold ${themeClass} ${sizeClass}`}
					style={{boxShadow: borderBottom ? "0px 3px 0px 0px #272670": null}}
				>
					{!iconPosition ? (
						<>
							{Icon} {label}
						</>
					) : (
						<>
							{iconPosition === 'left' && Icon} {label} {iconPosition === 'right' && Icon}
						</>
					)}
				</Link>
			</button>
		)
	}
}

export default Button
