export const preSlugifyMemory = (str) => {
  const match = str.match(/[\p{Letter}\p{Mark}\s-]+/gu);
  // console.log('match ? : ', match, typeof match);

  if(match?.length > 0){
    return match.join('');
  }

  return '';
}

export const generateRandomString = (num) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789&&(§!,?;.:/=+*^¨#@-_<>";
  let randomString = ""
  const charactersLength = characters.length
  for (let i = 0; i < num; i++) {
    randomString += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    )
  }

  return randomString
}

export const makeCaseAndAccentInsensitiveString = (param) => {
  if( param?.length > 0 ){
    return param.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  return ''
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isValidUrl = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-zA-Z\\d%_.~+=\\-\\[\\],]*)?'+ // query string
  '(\\#[-a-zA-Z\\d_]*)?$','i') // fragment locator
  
  return !!urlPattern.test(urlString)
}

export const isValidLocalUrl = urlString => {
  if(isValidUrl(urlString)){
    return false
  }

  const urlPattern = new RegExp('^(\\/?[;&a-zA-Z\\d%_.~+=\\-\\[\\],\\/]*)?'+ // path
  '(\\?[;&a-zA-Z\\d%_.~+=\\-\\[\\],]*)?'+ // query string
  '(\\#[-a-zA-Z\\d_]*)?$','i') // fragment locator

  return !!urlPattern.test(urlString)
}

export const urlHasError = (url) => {
  if(url === undefined || url.length === 0){
    return false
  } else {
    return !isValidLocalUrl(url)
  }
}

export const isReactQuillEmpty = (value) => {
  if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
    return true;
  }
  return false;
}

export const getLocalCustomLink = (url, defaultUrl) => {
  if(url === undefined || url.length === 0){
    return defaultUrl
  } else if(isValidLocalUrl(url)) {
    return `${url.charAt(0)!=='/' ? '/' : ''}${url}`
  }

  return defaultUrl
}

export const getDefaultLink = (entity) => {
  // if(entity?.custom?.enable_custom_home === true){
  //   return '/focus';
  // }

  return '/catalogue/';
}

export const getLocalizedFromData = (localizedData, defaultData, currentLocale, defaultLocale) => {
  if(localizedData && currentLocale && localizedData[currentLocale]) {
    return localizedData[currentLocale];
  } else if(localizedData && defaultLocale && localizedData[defaultLocale]) {
    return localizedData[defaultLocale];
  } else if(defaultData) {
    return defaultData;
  }

  return '';
}