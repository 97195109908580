import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { RiEyeCloseLine, RiEyeLine, RiLock2Line, RiLockUnlockLine } from "react-icons/ri"


const InputPassword = (props) => {

  // Translations
  const { t } = useTranslation('common')

  const {
    label,
    labelHidden,
    className,
    state,
    setstate,
    loading,
    placeOlder,
    inputClassName

  } = props

  const [hidePassword, sethidePassword] = useState(false)

  let inputName

  if (hidePassword){
    inputName = ""
  }else {
    inputName = "password"
  }

  return (
    <div className={`${className}`}>
      {labelHidden ? null :
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      }
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="sm:text-sm  text-black">
            
            {!state ? <RiLock2Line className="w-4 h-4" /> : <RiLockUnlockLine className="w-4 h-4" /> }
            
          </span>
        </div>

        <input
          disabled={loading}
          onChange={(e) => setstate(e.target.value)}
          value={state}
          type={inputName}
          name="password"
          id="password"
          className={`block w-full h-10 pl-8 pr-5 sm:text-sm border border-grayLight rounded-md ${inputClassName} animate`}
          placeholder={`${placeOlder ? placeOlder : t('password.Password')}`}
        />
        {state ?
          <span onClick={() => sethidePassword(!hidePassword)} className="absolute inset-y-0 right-0 flex items-center cursor-pointer">
            <div className="absolute z-10 inset-y-0 right-0 p-3 flex items-center">
              <span className="text-black sm:text-sm" >
                {!hidePassword ?
                  <RiEyeLine className="w-4 h-4" />
                :
                  <RiEyeCloseLine className="w-4 h-4" />
                }
              </span>
            </div>
          </span>
        : null }

      </div>
    </div>
  )
}

export default InputPassword