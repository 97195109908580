/**
 * Redux Store liste de tous les etats disponibles
 */
import { createStore, combineReducers } from 'redux'
import ReducerNotifications from './notifications/ReducerNotifications'
import ReducerUserData from './userData/ReducerUserData'


const rootReducer = combineReducers({
  notifications: ReducerNotifications,
  userData: ReducerUserData
})

const store = createStore(rootReducer)

export default store;  