/*
REDUX ACTION : get users
*/
import {SELECT_TAB} from "./Type";

export const SelectTab = (type) => {

  return {
    type: SELECT_TAB,
    data: type,
  };

}