import React from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { RiCloseLine, RiMailLine } from "react-icons/ri"


const InputEmail = (props) => {

  // Translations
  const { t } = useTranslation('common')

  const {
    label,
    labelHidden,
    className,
    state,
    setstate,
    loading
  } = props


  return (
    <div className={`${className}`}>
      {labelHidden ? null :
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      }
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="sm:text-sm  text-black">
            <RiMailLine className="w-4 h-4" />
          </span>
        </div>

        <input
          disabled={loading}
          onChange={(e) => setstate(e.target.value)}
          value={state}
          type="email"
          name="email"
          id="email"
          className="block w-full h-10 pl-8 pr-12 sm:text-sm border border-grayLight rounded-md"
          placeholder={t('email.Email-adress')}
        />
        {state ?
          <span onClick={() => setstate("")} className="absolute inset-y-0 right-0 flex items-center cursor-pointer">
            <div className="absolute z-10 inset-y-0 right-0 p-3 flex items-center">
              <span className="text-black sm:text-sm" >
                <RiCloseLine className="w-4 h-4" />
              </span>
            </div>
          </span>
        : null}

      </div>
    </div>
  )
}

export default InputEmail