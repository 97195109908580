import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// Firebase
import { FirebaseContext } from '../../firebase'
// Redux
import { useDispatch } from 'react-redux'
import { PlayNotifications } from '../../redux/notifications/PlayNotifications'
// Components
import Logo from '../../assets/images/app/svg/Logo.svg'
import InputEmail from '../../components/forms/email/InputEmail'
import InputPassword from '../../components/forms/password/InputPassword'
import Button from '../../components/elements/button/Button'
// import Navigation from '../../components/marketing/navigation/NavBarAuthentication'
import { NavLink } from 'react-router-dom'
import { RiHome4Line, RiThumbUpLine } from 'react-icons/ri'
// Utils
import history from '../../utils/history'


const Login = () => {

  // Translations
  const { t } = useTranslation(['common', 'messages'])

  const dispatch = useDispatch()
  const firebase = useContext(FirebaseContext)
  const user = firebase.auth.currentUser

  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [loading, setloading] = useState(false)


  const handleSubmit = (e) => {

    e.preventDefault()

    if (email !== '' && password.length >= 6) {

      setloading(true)

      firebase.signinUser(email, password)
        .then((userCredential) => {
          dispatch( 
            PlayNotifications( 
              {
                display: true,
                theme: "success",
                message: t('success.login-success', {ns: 'messages'}),
              }
            ) 
          )
          history.goBack();
          setloading(false)

          // const payload = {
          //   user_id: userCredential.user.uid
          // };

          // const createLogOnUserLogin = firebase.functions.httpsCallable("createLogOnUserLogin");
          // createLogOnUserLogin(payload)
          // .then(() => {})
          // .catch((error) => {
          //   console.error('login logs failed', error);
          // });
        })
        .catch((error) => {
          let errorMessage
          if (error.code === "auth/user-not-found") {
            errorMessage = t('error.email-error', {ns: 'messages'})
          } else if (error.code === "auth/wrong-password") {
            errorMessage = t('error.password-error', {ns: 'messages'})
          } else if (error.code === "auth/too-many-requests") {
            errorMessage = t('error.account-temporarily-disabled', {ns: 'messages'})
          }
          else if (error.code === "auth/network-request-failed") {
            errorMessage = t('error.netword-authentification-error', {ns: 'messages'})
          }
          else errorMessage = t('error.something-wrong-try-again-later', {ns: 'messages'})

          dispatch( 
            PlayNotifications( 
              {
                display: true,
                theme: "danger",
                message: errorMessage,
              }
            ) 
          )
          
          setloading(false)
        })
    } else return
  }

  if (!user){
    return (
      <div className="h-screen flex flex-col">
        {/*<Navigation */}
        {/*  connexion={false}*/}
        {/*/>*/}
        <div className="h-full flex items-center justify-center">
          <div className="w-full h-full px-7">
            <div className="max-w-md mx-auto h-full md:py-36 flex flex-col justify-around">
              <form onSubmit={handleSubmit}>
                <h4
                  className="text-center mb-4"
                >
                  {t('Welcome', {ns: 'common'})}
                </h4>

                <InputEmail 
                  className="mb-4" 
                  state={email}
                  setstate={setemail}
                  loading={loading}
                />
                <InputPassword 
                  state={password}
                  setstate={setpassword}
                  loading={loading}
                />
                <Button
                  actionType="form" 
                  className="mx-auto mt-4"
                  loading={loading}
                  theme={!email || password.length < 6 ? 'disabled' : null}
                >
                  {t('action.Log-in', {ns: 'common'})}
                </Button>
              </form>
              <div>
                <NavLink to="/password-forgotten/"
                  className="text-sm text-memoryBluePrimary block text-center"
                >
                  <span className="border-b">{t('password.forgot-password', {ns: 'common'})}</span>
                </NavLink>
                <a href="mailto:contact@my-memory.io"
                  className="text-sm text-grayPrimary block text-center mt-2"
                >
                    <span className="border-b">{t('action.Contact-an-administrator', {ns: 'common'})}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>  
    );
  }
  else {
    return(
      <>
        <div className="h-screen flex flex-col items-center justify-center">
          <img src={Logo} alt="" className="w-20 h-20 mb-2" />
          <span className="text-sm text-grayPrimary opacity-70 mt-1 flex items-center">
            <span>{t('Already-logged-in', {ns: 'common'})}</span> 
            <RiThumbUpLine className="w-4 h-4 ml-1" />
          </span>
          <Button
            actionType="route"
            action="/dashboard/projects/projects"
            icon={<RiHome4Line className="h-4 w-4 mr-1" />}
            className="mt-5"
            theme="simple"
          >
            {t('action.Back-home', {ns: 'common'})}
          </Button>
        </div>
      </>
    )
  }
}

export default Login
