import React from 'react'

const Headings = ({children, variant, display, className, weight, title='', style = {}}) => {

  // Font Size = Display Props
  let fontSize
  switch (display) {
    case 'display-1': fontSize = "text-3xl md:text-5xl"
      break
    case 'display-2': fontSize = "text-2xl md:text-4xl"
    break
    case 'display-2-bis': fontSize = "text-2xl md:text-3xl"
    break
    case 'display-3': fontSize = "text-lg sm:text-2xl" // 18px to 24px
      break
    case 'display-4': fontSize = "text-2xl" //24px
      break
    case 'display-5': fontSize = "text-lg" //18px
      break
    case 'display-base': fontSize = "text-base" //16px
      break
    case 'display-6': fontSize = "text-sm" //14px
      break
    case 'display-7': fontSize = "text-2sm" //14px
      break
    case 'display-custom': fontSize = ""
      break
    default : fontSize = "text-2xl" //24px
  }

  if (!weight){
    weight="font-bold"
  }

  // Variant = Type de Balise h1,h2,h3...
  switch (variant) {
    case 'h1' : 
      return(
        <h1 className={`${weight} ${fontSize} ${className}`} title={title} style={style}>
          {children}
        </h1>
      )
    case 'h3' : 
      return(
        <h3 className={`${weight} ${fontSize} ${className}`} title={title} style={style}>
          {children}
        </h3>
      )
    case 'h6' : 
      return(
        <h6 className={`${!weight ? 'weight' : weight} ${fontSize} ${className}`} title={title} style={style}>
          {children}
        </h6>
      )
    case 'div' : 
      return(
        <div className={`${!weight ? 'weight' : weight} ${fontSize} ${className}`} style={style}>
          {children}
        </div>
      )
    default : 
      return(
        <h2 className={`${weight} ${fontSize} ${className}`} title={title} style={style}>
          {children}
        </h2>
      )
  }

}

export default Headings
