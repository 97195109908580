import React, {useCallback, useEffect, useRef} from "react"
import ButtonPagination from "../button/ButtonPagination";

const Pagination = (props) => {

  const { 
    currentPage,
    keyListerner = false,
    totalPages, 
    onClickPage, 
    onClickPrevious, 
    onClickNext,
    mainColor = 'memoryBluePrimary'
  } = props

  const keyListenerTimeout = useRef(null)

  const onKeyUp = useCallback((e) => {
    if( keyListerner === true ){
      if (keyListenerTimeout.current) {
        clearTimeout(keyListenerTimeout.current)
      }

      keyListenerTimeout.current = setTimeout(() => {
        keyUpHandler(e)
        clearTimeout(keyListenerTimeout.current)
      }, 200);
    }
  },[currentPage]);

  useEffect(() => {
    if( keyListerner === true ){
      window.addEventListener('keyup', onKeyUp);
    }

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onKeyUp]);

  const onKeyUpp = (e) => {
    if( keyListerner === true ){
      if (keyListenerTimeout.current) {
        clearTimeout(keyListenerTimeout.current)
      }

      keyListenerTimeout.current = setTimeout(() => {
        keyUpHandler(e)
        clearTimeout(keyListenerTimeout.current)
      }, 200);
    }
  }

  const keyUpHandler = (e) => {
    if( keyListerner === true ){
      if (e.keyCode === 37 || e.key === 'ArrowLeft') {
        onClickPrevious()
      } else if (e.keyCode === 39 || e.key === 'ArrowRight') {
        onClickNext()
      }
    }
  }

  const styleOver = (e) => {
    if( mainColor && mainColor.charAt(0) === '#' ){
      e.target.style.background = mainColor
    }
  }

  const styleOut = (e) => {
    if( mainColor && mainColor.charAt(0) === '#' ){
      e.target.style.background = 'rgb(229 231 235)'
    }
  }

  const returnButtonsPage = () => {
    let buttons = []
    let minPage = currentPage - 2
    let maxPage = currentPage + 2
    let hasLeftDots = true
    let hasRightDots = true
    let displayFirstPage = true
    let displayLastPage = true

    if(minPage < 3){
      minPage = 1
      hasLeftDots = false
      displayFirstPage = false
    } else {
      if( currentPage !== totalPages ){
        minPage = currentPage - 1
      }
    }

    if(maxPage > totalPages - 2){
      maxPage = totalPages
      hasRightDots = false
      displayLastPage = false
    } else {
      if( currentPage !== 1 ){
        maxPage = currentPage + 1
      }
    }

    if( displayFirstPage ){
      buttons.push(
        <ButtonPagination
          key={1}
          active={currentPage === 1}
          className={`px-3 py-1 ${currentPage === 1 ? 'text-white' : 'text-gray-700'} ${currentPage === 1 ? `bg-${mainColor}` : 'bg-gray-200'} rounded-md hover:bg-${mainColor} hover:text-white`}
          mainColor={mainColor}
          onClickFunction={() => onClickPage(1)}
        >
          {1}
        </ButtonPagination>
      )
    }
    for (let i = 1; i <= totalPages; i++) {
      if(i < minPage){
        if( hasLeftDots ){
          hasLeftDots = false
          buttons.push(
            <button key={`dot-${i}`} className="px-3 py-1 text-gray-700 bg-gray-200 rounded-md animate" disabled>
              ...
            </button>
          )
        }
      } else if( i > maxPage) {
        if( hasRightDots ){
          hasRightDots = false
          buttons.push(
            <button key={i} className="px-3 py-1 text-gray-700 bg-gray-200 rounded-md animate" disabled>
              ...
            </button>
          )
        }
      } else {
        buttons.push(
          <ButtonPagination
            key={i}
            active={currentPage === i}
            className={`px-3 py-1 ${currentPage === i ? 'text-white' : 'text-gray-700'} ${currentPage === i ? `bg-${mainColor}` : 'bg-gray-200'} rounded-md hover:bg-${mainColor} hover:text-white animate`}
            mainColor={mainColor}
            onClickFunction={() => onClickPage(i)}
          >
            {i}
          </ButtonPagination>
        )
      }
    }
    if( displayLastPage ){
      buttons.push(
        <ButtonPagination
          key={totalPages}
          active={currentPage === totalPages}
          className={`px-3 py-1 ${currentPage === totalPages ? 'text-white' : 'text-gray-700'} ${currentPage === totalPages ? `bg-${mainColor}` : 'bg-gray-200'} rounded-md hover:bg-${mainColor} hover:text-white animate`}
          mainColor={mainColor}
          onClickFunction={() => onClickPage(totalPages)}
        >
          {totalPages}
        </ButtonPagination>
      )
    }
    return buttons
  }

  return (
    <div className="flex items-center space-x-1 text-sm">
      <ButtonPagination
        className={`px-1 py-1 text-gray-500 bg-gray-200 rounded-md hover:bg-${mainColor} hover:text-white animate`}
        // disabled={currentPage === 1}
        onClickFunction={() => onClickPrevious()}
        mainColor={mainColor}
        active={false}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round"strokeWidth="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
        </svg>
      </ButtonPagination>
      {totalPages > 0 && returnButtonsPage()}
      {totalPages === 0 && (
        <button className="px-3 py-1 text-gray-700 bg-gray-200 rounded-md animate" disabled>
          ...
        </button>
      )}
      <ButtonPagination
        className={`px-1 py-1 text-gray-500 bg-gray-200 rounded-md hover:bg-${mainColor} hover:text-white animate`}
        // disabled={currentPage + 1 > totalPages}
        onClickFunction={() => onClickNext()}
        mainColor={mainColor}
        active={false}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
        </svg>
      </ButtonPagination>
    </div>
  )
}

export default Pagination
