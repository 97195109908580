import React from 'react'
import { useTranslation } from 'react-i18next'

import { BarLoader } from "react-spinners"


const PageContentLoader = ({className}) => {

  // Translations
  const { t } = useTranslation('common')


  return (
    <div className={`mt-30 flex flex-col items-center justify-center ${className}`}>
      <BarLoader color={"#504fe4"} height={3} width={70} />
      <span className="text-xs text-grayPrimary opacity-70 mt-1">{`${t('Loading')}...`}</span>
    </div>
  );
}

export default PageContentLoader
