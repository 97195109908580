import {
  RiAddBoxLine,
  RiLayoutGridLine,
  RiUser6Line,
  RiSettings2Line,
  RiUserLine,
  RiUserSettingsLine,
  RiUserStarLine,
  RiBuildingLine,
  RiArticleLine,
  RiSettings4Line,
  RiSparkling2Line, RiListCheck3,
} from "react-icons/ri"
import {
  PROJECTS_MENU,
  MEMBERS_MENU, ROLES_MENU,
  SETTINGS_MENU, PROJECT_JOBS_MENU,
} from "./constantsBackOffice";

export function DataHome() {
  const obj = {
    name: `Focus`,
    name_locale: {key:'Focus', ns:'common'},
    path: "/focus/",
    icon: <RiSparkling2Line className="w-5 h-5 mr-1" />
  }
  return obj
}


export function DataCatalogue() {
  const obj = {
    name: `Browse`,
    name_locale: {key:'Browse', ns:'common'},
    path: "/catalogue/",
    icon: <RiLayoutGridLine className="w-4 h-4 mr-1" />
  }
  return obj
}

export function DataNewContent() {
  
  const obj = {
    name: `content.New-content`,
    name_locale: {key:'content.New-content', ns:'common'},
    path: "/content/add/",
    icon: <RiAddBoxLine className="w-4 h-4 mr-1" />
  }
  return obj
}

export function DataParameters() {
  const obj = {
    name: `Parameters`,
    name_locale: {key:'Parameters', ns:'common'},
    path: "/parameters/",
    icon: <RiSettings2Line className="w-4 h-4 mr-1" />
  }
  return obj
}


export function DataConnexion() {
  const obj = {
    name: `Login`,
    name_locale: {key:'Login', ns:'common'},
    path: "/connexion/",
    icon: <RiUser6Line className="w-4 h-4 mr-1" />
  }
  return obj
}

// Sub Navigation dashboard
export const SUB_NAV_MEMBERS = [
  {
    name: MEMBERS_MENU,
    name_locale: {key: 'members.Members', ns: 'dashboard'},
    path: '/dashboard/members/members',
    icon: <RiUserLine className="w-4 h-4 min-w-4 min-h-4" />
  }
]
export const SUB_NAV_PROJECTS = [
  {
    name: PROJECTS_MENU,
    name_locale: {key: 'projects.Projects', ns: 'dashboard'},
    path: '/dashboard/projects/projects',
    icon: <RiBuildingLine className="w-4 h-4 min-w-4 min-h-4" />
  },
  {
    id: 'jobs',
    name: PROJECT_JOBS_MENU,
    name_locale: {key: 'jobs.Jobs', ns: 'dashboard'},
    path: '/dashboard/projects/jobs',
    icon: <RiListCheck3 className="w-4 h-4 min-w-4 min-h-4" />
  }
]

export const SUB_NAV_SETTINGS = [
  {
    name: ROLES_MENU,
    name_locale: {key: 'roles.Roles', ns: 'dashboard'},
    path: '/dashboard/settings/roles',
    icon: <RiUserStarLine className="w-4 h-4 min-w-4 min-h-4" />
  },
]

export const DASHBOARD_MAIN_NAV = [
  {
    anchor: "#members",
    icon: <RiUserSettingsLine className="w-5 h-5 min-w-5" />,
    name: MEMBERS_MENU,
    name_locale: {key:'members.Members', ns:'dashboard'},
    path: "/dashboard/members/members",
    slug: "members",
    subnav: true,
    subnav_items: SUB_NAV_MEMBERS
  },
  {
    anchor: "#projects",
    icon: <RiBuildingLine className="w-5 h-5 min-w-5" />,
    name: PROJECTS_MENU,
    name_locale: {key:'projects.Projects', ns:'dashboard'},
    path: "/dashboard/projects/projects",
    slug: "projects",
    subnav: true,
    subnav_items: SUB_NAV_PROJECTS
  },
  {
    anchor: "#settings",
    icon: <RiSettings4Line className="w-5 h-5 min-w-5" />,
    name: SETTINGS_MENU,
    name_locale: {key:'Parameters', ns:'common'},
    path: "/dashboard/settings/roles",
    slug: "settings",
    subnav: true,
    subnav_items: SUB_NAV_SETTINGS
  },
]

export const EDIT_ROLE_NAV  = [
  {
    anchor: '#models-and-contents',
    icon: <RiArticleLine className="w-4 h-4 min-w-4 min-h-4" />,
    name_locale: {key: 'app.models', ns: 'roles'},
    path: '',
    slug: 'models_contents',
  },
  {
    anchor: '#app-structures',
    icon: <RiBuildingLine className="w-4 h-4 min-w-4 min-h-4" />,
    name_locale: {key: 'app.structures', ns: 'roles'},
    path: '',
    slug: 'app_structures',
  },
].concat(DASHBOARD_MAIN_NAV)
