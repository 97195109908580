import React, {Suspense, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
// Redux
import { useSelector } from 'react-redux'
// Components
import NavBarLink from './component/NavBarLink'
import ButtonIcon from 'components/elements/button/ButtonIcon'
import Button from 'components/elements/button/Button'
import {RiAddLine, RiArrowRightSLine, RiInputCursorMove, RiMore2Line} from "react-icons/ri"
// Utils
import {NAV_EMPTY} from 'utils/constantsFront'
import {Fade, Tooltip} from "@mui/material";


const SubNavDashboard = ({entity, active_menu = '', type, items, item_class, primaryButton, primaryAction, tooltip = true }) => {

  // Trasnslations
  const { t } = useTranslation('common')
  
  const selectedTab = useSelector((state) => state.users.selectedTab)

  const [isOpen, setIsOpen] = useState(true)

  const toggleMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const isSelected = (slug) => (
    slug === selectedTab ? true : false
  )
  // (isOpen === true && active_menu === item.name)
  return (
    <nav className={`flex items-center gap-4 mb-2 lg:relative lg:h-full lg:max-h-full lg:flex-col pr-0 lg:ml-0 lg:mt-0 lg:mb-0 mr-2`}>
      {primaryButton &&
        <div className="lg:sticky lg:top-0 lg:w-full bg-grayDashboard">
          <Button
            className="justify-center self-start gap-2 lg:mb-4"
            size="small"
            icon={<RiAddLine className="w-4 h-4 ml-1"/>}
            actionType={"action"}
            action={() => primaryAction()}
            theme="secondary"
            animate={false}
          >
            <span className="hidden min480:inline">{t('action.New', {ns: 'common'})}</span>
          </Button>
        </div>
      }
      <div className={`
        flex items-center max-w-full gap-4 pt-4 pb-2 pr-1 overflow-x-auto memoryScrollbar
        lg:flex-col lg:w-full lg:items-start lg:p-0 lg:overflow-x-hidden
      `}>
        {type && type !== NAV_EMPTY &&
          items.map((item, index) => (
            <div key={`item-sub-nav-${index}`} className={`relative w-full`}>
              <div className="flex justify-between gap-1 w-full group lg:w-full h-6">
                <NavBarLink
                  action={ active_menu === item.name ? () => { console.log('active') } : item.action ? item.action : null}
                  className={`group-link flex items-center w-fit pr-0 mr-0 text-sm
                    font-semibold cursor-pointer text-gray-500 hover:text-memoryBluePrimary lg:w-full ${item.edit === true ? 'lg:group-hover:max-w-4/5' : ''} lg:group-hover:text-memoryBluePrimary ${item_class ? item_class : ''}`}
                  link={item.path ? item.path : null}
                  selected={item.slug ? isSelected(item.slug) : null}
                  theme="subnav-secondary"
                  type={type}
                >
                  {item.icon ? item.icon : <RiInputCursorMove className="w-4 h-4 min-w-4" />}
                  {tooltip ?
                    <Tooltip
                      title={typeof item.name === "string" ? item.name : t(item.name_locale.key, {ns: item.name_locale.ns})}
                      arrow={true}
                      placement="bottom"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 300 }}
                      disableInteractive={true}
                    >
                      <p className="lg:inline truncate max-w-full ml-2">{typeof item.name === "string" && item.use_name ===true ? item.name : t(item.name_locale.key, {ns: item.name_locale.ns})}</p>
                    </Tooltip>
                  :
                    <p className="inline-flex items-center truncate max-w-full relative ml-2" onClick={(e) => {
                      if(active_menu === item.name){
                        toggleMenu(e)
                      }
                    }}>
                      {typeof item.name === "string" && item.use_name ===true ? item.name : t(item.name_locale.key, {ns: item.name_locale.ns})}
                      {(item.subnav === true && item.subnav_options?.type) &&
                        <RiArrowRightSLine className={`w-4 h-4 ml-2 animate ${(active_menu === item.name && isOpen) ? 'rotate-90' : 'group-hover/link:rotate-90'}`} />
                      }
                    </p>
                  }
                  {(item.suffix_function !== undefined
                    && item.suffix_position !== 'outside'
                    && ((item.suffix_on_active === true && active_menu === item.name) || item.suffix_on_active !== true)
                  ) && (
                    <Suspense fallback={<>...</>}>
                      <Suffix
                        getSuffix={item.suffix_function}
                      />
                    </Suspense>)
                  }
                </NavBarLink>
                {(item.suffix_function !== undefined
                  && item.suffix_position === 'outside'
                  && ((item.suffix_on_active === true && active_menu === item.name) || item.suffix_on_active !== true)
                ) && (
                  <Suspense fallback={<>...</>}>
                    <Suffix
                      getSuffix={item.suffix_function}
                    />
                  </Suspense>)
                }

                {item.edit === true &&
                  <div className="lg:opacity-0 lg:group-hover:opacity-100 lg:hidden lg:group-hover:block">
                    <ButtonIcon
                      actionType='action'
                      action={item.actionEdit}
                      icon={<RiMore2Line className="w-4 h-4 min-w-4" />}
                      theme="transparent"
                      size="small"
                      className="w-fit"
                    />
                  </div>
                }
              </div>
              {(item.subnav === true && item.subnav_options?.type && item.name === active_menu) &&
                <div
                  className={`animate ease-in max-h-[500px] overflow-y-auto w-[inherit] overflow-x-hidden memoryScrollbar
                    ${(item.toggle === true && active_menu === item.name && isOpen === true) ? 'mt-2 h-fit pb-3 border-b' : (item.toggle === true && active_menu === item.name && isOpen === false) ? 'h-0' : 'h-fit'}
                  `}
                >
                  <SubNavDashboard
                    entity={entity}
                    type={item?.subnav_options?.type}
                    item_class={`pl-4`}
                    items={item.subnav_items ? item.subnav_items : null}
                    labelButton={item.subnav_options.primaryButton ? item.subnav_options.primaryButton : null}
                    labelButtonAction={item.subnav_options.primaryAction ? item.subnav_options.primaryAction : null}
                    responsiveMenuClosed={null}
                    setResponsiveMenuClosed={() => console.log('responsiveMenuClosed')}
                    tooltip={item.subnav_options.tooltip ? item.subnav_options.tooltip : false}
                  />
                </div>
              }
            </div>
          ))
        } 
      </div>
    </nav>
  )
}


export default SubNavDashboard

const Suffix = ({getSuffix}) => {
  const [suffix, setSuffix] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const result = await getSuffix();

      setSuffix(result)
    }

    fetchData()
  }, [])

  return (
    <>
      {suffix && suffix}
    </>
  )
}