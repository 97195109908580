/**
 * Fonction permettant d'envoyer une notification système en transmettant un objet avec les propriétées : display / message & theme
 */
import { PLAY_NOTIFICATIONS } from './Type'

export const PlayNotifications = (data_to_add) => {
  console.log('PlayNotifications')
  return {
    type: PLAY_NOTIFICATIONS,
    data: data_to_add,
  }

} 