import React from 'react'
import { useTranslation } from 'react-i18next'
import { PulseLoader, BarLoader } from "react-spinners"


const LoaderComponent = ({color, size, type, className}) => {

  // Translations
  const { t } = useTranslation('common')

  if (!color){ color = "#504fe4"}
  if (!size){ size = 8}

  return (
    <>
      {!type ?
        <PulseLoader color={color} size={size} />
      :
        <div className={`mt-30 flex flex-col ${className}`}>
          <BarLoader color={"#504fe4"} height={3} width={70} />
          <span className="text-xs text-grayPrimary opacity-70 mt-1">{`${t('Loading')}...`}</span>
        </div> 
      }
    </>
  )
}

export default LoaderComponent
