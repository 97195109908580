import React from "react";
import {useTranslation} from "react-i18next"
import {LOCALES_OBJECT} from "utils/constantsFront";
import {Menu} from "@headlessui/react";
import {RiArrowRightSLine} from "react-icons/ri";


const LocalesSelector = (props) => {
  const {
    className,
    currentLocale,
    setCurrentLocale,
  } = props

  // Translations
  const { t } = useTranslation('common')

  const currentLocaleObject = LOCALES_OBJECT.filter(item => item.code === currentLocale).shift()

  return (
    <div className={`relative text-gray-600 text-sm group/dropdownnav ${className ? className : 'mb-4'}`}>
      <Menu>
        <Menu.Button className={`w-fit`}>
          <div className="flex justify-between items-center bg-white pl-4 pr-2 py-1 gap-x-8 font-semibold rounded border border-1 border-gray-300">
            <div className="flex justify-between items-center gap-x-2">
              {currentLocaleObject ? t(currentLocaleObject.name_locale.key, {ns: currentLocaleObject.name_locale.ns}) : t(LOCALES_OBJECT[0].name_locale.key, {ns: LOCALES_OBJECT[0].name_locale.ns})}
              {currentLocaleObject ? currentLocaleObject.icon : LOCALES_OBJECT[0].icon}
            </div>
            <RiArrowRightSLine className={`w-5 h-5 animate group-hover/dropdownnav:rotate-90`} />
          </div>
        </Menu.Button>
        <Menu.Items className="absolute z-20 left-0 mt-2 w-56 origin-top-right rounded-b bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {LOCALES_OBJECT.map((locale, id) => (
            <Menu.Item key={`locale_${id}`}>
              {({ close }) => (
                <div
                  className={`flex items-center gap-x-2 cursor-pointer px-4 py-2 hover:bg-gray-100`}
                  onClick={(e) => {
                    setCurrentLocale(locale.code)
                    close()
                  }}
                >
                  {t(locale.name_locale.key, {ns: locale.name_locale.ns})}
                  {locale.icon}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  )
}

export default LocalesSelector