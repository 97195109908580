import i18n from "i18next"
import resourcesToBackend from 'i18next-resources-to-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next"


i18n
	.use(resourcesToBackend((language, namespace, callback) => {
		import(`./locales/${language}/${namespace}.json`)
			.then((resources) => {
				callback(null, resources)
			})
			.catch((error) => {
				callback(error, null)
			})
	}))
	.use(LanguageDetector)
  .use(initReactI18next)
  .init({
		fallbackLng: 'fr_FR',
		supportedLngs: ['en_GB', 'fr_FR'], //, 'en_GB'
    debug: false,	
    interpolation: {
      escapeValue: false,
			skipOnVariables: false
    },
		react: {
			useSuspense: true
		}
  })


export default i18n