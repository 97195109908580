import React from 'react'

const Container = ({children, className = '', fullWidth = false, style = {}}) => {

  return (
    <div className={`${fullWidth ? `w-full px-0` : `max-w-6xl px-2`} mx-auto ${className} md:px-0 min-h-fit`} style={style}>
      {children}
    </div>
  )
}

export default Container
