import app from 'firebase/compat/app'
import 'firebase/compat/auth'
import { updateProfile } from "firebase/auth"

import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import config from './config.json'

class Firebase {
  constructor() {
    app.initializeApp(config)

    this.app = app;
    this.auth = app.auth();
    this.db = app.firestore()
    this.storage = app.storage()
    this.functions = app.app().functions(config.functions_region)
  }

  signinUser = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  signOutUser = () =>
    this.auth.signOut()

  resetPassword = (email) =>
    this.auth.sendPasswordResetEmail(email)

  verifyPasswordResetCode = (code) =>
    this.auth.verifyPasswordResetCode(code)

  confirmPasswordReset = (code, newPassword) =>
  this.auth.confirmPasswordReset(code, newPassword)

  updateProfile = (currentUser, data) =>
    updateProfile(currentUser, data)
}

export default Firebase;
