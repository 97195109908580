import React from 'react'
import Logo from "../assets/images/app/svg/Logo.svg";


const Error404 = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={Logo} className="App-logo" alt="logo" />
        <p>
          The page you are looking for doesn't exist
        </p>
        <a
          className="App-link"
          href="https://www.my-memory.io/"
          target="_self"
          rel="noopener noreferrer"
        >
          Memory home page
        </a>
      </header>
    </div>
  )
}

export default Error404