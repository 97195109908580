/**
 * Fonction permettant de fermer une notification sytème en réinitialisant les propriétées : display / message & theme
 */
import { CLOSE_NOTIFICATIONS } from './Type'

export const CloseNotifications = () => {

  const closeObject = {
    display: false,
    theme:"",
    message:""
  }

  return {
    type: CLOSE_NOTIFICATIONS,
    data: closeObject,
  }

} 