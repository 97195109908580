import React from "react"

const ButtonPagination = (props) => {

  const {
    children,
    active = false,
    className,
    disabled,
    mainColor,
    onClickFunction,
    secondaryColor
  } = props

  const getDefaultStyle = () => {
    const style = {}

    if( mainColor && mainColor.charAt(0) === '#' && active === true ){
      style.backgroundColor = mainColor
    }

    return style
  }

  const styleOver = (e) => {
    if( mainColor && mainColor.charAt(0) === '#' && active !== true ){
      e.target.style.background = mainColor
    }
  }

  const styleOut = (e) => {
    if( mainColor && mainColor.charAt(0) === '#' && active !== true ){
      e.target.style.background = 'rgb(229 231 235)'
    }
  }

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={onClickFunction}
      onMouseOver={(e) => styleOver(e)}
      onMouseOut={(e) => styleOut(e)}
      style={getDefaultStyle()}
    >
      {children}
    </button>
  )
}

export default ButtonPagination
