import React, {Fragment, useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../../firebase";
import {useTranslation} from "react-i18next";
import {Tabs, Tab} from "@mui/material";
import {Dialog, Transition} from "@headlessui/react";
import ClosePanelButton from "components/elements/button/ClosePanelButton";
import moment from "moment/moment";
import '../../assets/style/skeleton.css'

const JobPanel = ({open, setOpen, job}) => {
  const { t } = useTranslation(['common']);
  const firebase = useContext(FirebaseContext);

  const [fullTranscript, setFullTranscript] = useState('');
  const [fullTranscriptLoaded, setFullTranscriptLoaded] = useState(false);
  const [paragraphs, setParagraphs] = useState([]);
  const [paragraphsLoaded, setParagraphsLoaded] = useState(false);

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const close = () => {
    setOpen(false)
  }

  const getCreatedDate = (date) => {
    try{
      return moment(date.toDate()).format('DD/MM/YYYY - H:mm:ss')
    } catch (e) {
      return ''
    }
  }

  useEffect(() => {

    if(job?.id){
      setFullTranscriptLoaded(false)
      setParagraphsLoaded(false)

      const resultsRef = firebase.db
        .collection('projects_client_jobs')
        .doc(job.id)
        .collection('results');

      // full transcript
      let queryFullTranscript = resultsRef.where('type', '==', 'full_transcript');
      queryFullTranscript.get()
        .then((snapshots) => {
          const data = snapshots.docs.length > 0 ? snapshots.docs[0].data() : null;
          setFullTranscriptLoaded(true)
          setFullTranscript(data?.text ? data?.text : '');
        })

      // Speakers
      let querySpeakersTranscript = resultsRef.where('type', '==', 'all_paragraphs');
      querySpeakersTranscript.get()
        .then((snapshots) => {
          const data = snapshots.docs.length > 0 ? snapshots.docs[0].data() : null;
          setParagraphsLoaded(true)
          setParagraphs(data?.all_paragraphs ? data?.all_paragraphs : []);
        })
    }


    return () => {
      setFullTranscript('');
      setParagraphs([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job])

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className={`fixed inset-0 overflow-hidden ${open ? 'max-w-full' : 'max-w-max'} z-65`}
          onClose={close}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-md">
                  <aside className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-2xl">
                    <div className="h-0 flex-1 overflow-y-auto memoryScrollbar">
                      <header className="bg-gray-100 bg-opacity-40 border-b pt-6 pb-4 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium">
                            {t(`history.Update-history`, {ns: 'common'})}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <ClosePanelButton
                              close={() => close()}
                            >
                              {t('Close-panel', {ns: 'model'})}
                            </ClosePanelButton>
                          </div>
                        </div>
                      </header>

                      <div className="text-sm text-grayPrimary flex flex-col gap-y-8 items-center py-4">
                        <div className="flex flex-col space-y-2 px-8 w-full">
                          <p>Id: {job?.id}</p>
                          <p>Entity: {job?.project_id}</p>
                          <p>Type: {job?.type}</p>
                          <p>Date: {getCreatedDate(job?.createdAt)}</p>

                          <div className="flex flex-col space-y-2 w-full pt-4">
                            <div className={`font-semibold text-base`}>Contenu</div>
                            <p>Id: {job?.config?.content_id}</p>
                            <p>Titre: {job?.config?.content_title}</p>
                            <p>Video: {job?.config?.video_url}</p>
                          </div>
                        </div>

                        <div className={`w-full flex flex-col gap-4`}>
                          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Full transcript" value="0"/>
                            <Tab label="Speakers" value="1"/>
                            <Tab label="JSON" value="2"/>
                          </Tabs>

                          <CustomTabPanel value={value} index={0}>
                            <div className={`font-semibold text-base`}>full transcript</div>
                            {fullTranscriptLoaded ?
                              <div className={`mt-2`}>
                                {fullTranscript}
                              </div>
                              :
                              <div className={`font-semibold text-gray-900`}>
                                <Skeleton className="h-3 max-w-32 rounded mb-2"/>
                                <Skeleton className="h-3 max-w-16 rounded mb-2"/>
                              </div>
                            }
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={1}>
                            {paragraphsLoaded ?
                              <div className={`flex flex-col gap-y-4`}>
                                {paragraphs.map((paragraph, index) => (
                                  <div key={`speaker_${index}`}>
                                    <div className={`font-semibold text-base mb-2 flex gap-2`}>
                                      {paragraph.speaker}
                                      <span
                                        className={`font-normal text-blue-700`}>{Math.round(paragraph.start_time * 100) / 100}</span>
                                    </div>
                                    <div className={`text-sm`}>{paragraph.paragraph}</div>
                                  </div>
                                ))}
                              </div>
                              :
                              <div className={`font-semibold text-gray-900`}>
                                <Skeleton className="h-3 max-w-32 rounded mb-2"/>
                                <Skeleton className="h-3 max-w-16 rounded mb-2"/>
                              </div>
                            }
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={2}>
                            {paragraphsLoaded ?
                              <>
                                {JSON.stringify(paragraphs)}
                              </>
                              :
                              <div className={`font-semibold text-gray-900`}>
                                <Skeleton className="h-3 max-w-32 rounded mb-2"/>
                                <Skeleton className="h-3 max-w-16 rounded mb-2"/>
                              </div>
                            }
                          </CustomTabPanel>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default JobPanel

const CustomTabPanel = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={parseInt(value) !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={`w-full`}
    >
      {parseInt(value) === index && <div className="flex flex-col space-y-4 px-8 w-full">{children}</div>}
    </div>
  );
}

const Skeleton = ({className, absolute}) => {
  return (
    <div className={`skeleton ${absolute ? 'absolute' : 'relative'} ${className}`}></div>
  )
}