import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Firebase
import { FirebaseContext } from '../../../firebase'
// Redux
import { useDispatch } from 'react-redux'
import { PlayNotifications } from 'redux/notifications/PlayNotifications'
// Components
import Logo from 'components/elements/logo/Logo'
import Button from 'components/elements/button/Button'
import {Tooltip, Fade} from '@mui/material'
import WhiteTooltip from 'components/elements/tooltip/WhiteTooltip'
import {
  RiQuestionLine,
  RiRadarLine,
  RiLogoutCircleLine,
  RiArrowLeftSLine, RiChat3Line,
} from "react-icons/ri"
// Utils
import {DASHBOARD_MAIN_NAV} from 'utils/dataNavigation'
import {DEFAULT_HELP_LINK} from 'utils/constantsFront'
// import {getLocalCustomLink} from "utils/stringUtils";
// import {TrackGoogleAnalyticsEvent} from "../../../utils/googleAnalytics";
import _ from "lodash";


const NavBarDashboard = ({selectedMenu, responsiveMenuClosed, setResponsiveMenuClosed}) => {

  // Transltions
  const { t } = useTranslation(['common', 'messages'])

  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const history = useHistory()

  const [linkSelected, setLinkSelected] = useState(selectedMenu ? selectedMenu : 'users')
  const [menuItems, setMenuItems] = useState([])

  const signOut = () => {
    firebase.signOutUser()
    .then(() => {
      // TrackGoogleAnalyticsEvent(
      //   "user",
      //   "logout",
      //   window.location.pathname + window.location.search
      // );

      dispatch( 
        PlayNotifications( 
          {
            display: true,
            theme: "success",
            message: t('success.logout-success', {ns: 'messages'}),
          }
        ) 
      )
      history.push('/catalogue')
    })
    .catch(() => {
      dispatch( 
        PlayNotifications( 
          {
            display: true,
            theme: "danger",
            message: t('error.something-wrong', {ns: 'messages'}),
          }
        ) 
      )
    });
  }

  const handleClick = (linkSelected) => {
    setLinkSelected(linkSelected)
    setResponsiveMenuClosed(true)
  }

  const getLogoLink = () => {
    return '/'
  }
  
  useEffect(() => {
    const menuItemsActivated = DASHBOARD_MAIN_NAV.filter((item) => {
      if(item.option === undefined){
        return item
      } else {
        // const hasOption = _.get(entity, `options.${item.option}`) === true
        // if(hasOption){
        //   return item
        // }
      }
    });

    setMenuItems(menuItemsActivated);
  }, [])

  return (
    <div className={`
      fixed inset-0 z-50 flex flex-col gap-12 w-[80px] min-w-[80px] bg-white shadow transition duration-500 ease-out transform 
      lg:relative lg:transform-none 
      ${responsiveMenuClosed ? '-translate-x-full' : ''}
    `}>
      <div className="flex items-center justify-center py-6 border-b">
        <Logo size="w-40px min-w-40px" tooltip={''} type="avatar" link={'/'} />
      </div>
      <div className="flex flex-col justify-between gap-6 min-h-fit overflow-y-auto memoryScrollbar">
        {menuItems.map((menu, key) => (
            <div
              key={key}
            >
              <WhiteTooltip
                title={
                  menu.subnav === true ?
                    menu.subnav_items &&
                      <div className={`pb-2 font-default`}>
                        <ul>
                          <li className={`flex items-center gap-x-2 py-2 px-3 text-sm uppercase font-semibold text-gray-700`}>
                            {menu.icon}
                            {t(menu.name_locale.key, {ns:menu.name_locale.ns})}
                          </li>
                          <li className={`mb-4`}>
                            <span className={` block m-auto h-1 w-[70%] border-b border-1 border-gray-200`}></span>
                          </li>
                          {menu.subnav_items.map((subnav_item, sub_key) => (
                            <li key={`sub_${sub_key}`}>
                              <Link
                                to={subnav_item.path}
                                className={`flex items-center gap-x-2 py-2 px-3 text-sm text-gray-700 font-normal hover:bg-gray-100`}
                              >
                                {subnav_item.icon}
                                {t(subnav_item.name_locale.key, {ns:subnav_item.name_locale.ns})}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>

                  :
                    // <span className="uppercase text-grayPrimary font-bold px-2">{t(menu.name_locale.key, {ns:menu.name_locale.ns})}</span>
                    <span className={`font-default flex items-center gap-x-2 py-2 px-2 text-sm uppercase font-semibold text-grayPrimary`}>
                      {menu.icon}
                      {t(menu.name_locale.key, {ns:menu.name_locale.ns})}
                    </span>
                }
                arrow={true}
                placement={menu.subnav === true ? `right-start` : `right`}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
              >
                <Link
                  to={menu.path}
                  className={`flex items-center justify-center mx-0 py-3 text-base font-medium hover:text-memoryBluePrimary hover:bg-gray-100 animate ${linkSelected === menu.name ? `bg-gray-100 text-memoryBluePrimary` : 'text-grayPrimary'}`}
                  onClick={() => handleClick(menu.name)}
                >
                  {menu.icon}
                </Link>
              </WhiteTooltip>
            </div>
        ))}
      </div>
      <Button
        theme="simple"
        size="small-icon"
        className="flex items-center justify-center mt-auto mx-auto px-2 lg:hidden"
        icon={<RiArrowLeftSLine className="w-4 h-4 min-w-4" />}
        actionType="action"
        action={() => setResponsiveMenuClosed(true)}
      />
      <div id="bavbar-dashboard" className="flex flex-col items-center gap-4 w-full py-6 mt-auto border-t text-grayPrimary">
        <Tooltip
          title={t('News', {ns:'common'})}
          arrow={true}
          placement="right"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
        >
          <button id="olvy-target">
            <RiRadarLine className={`w-5 h-5 min-w-5 hover:text-memoryBluePrimary`} />
          </button>
        </Tooltip>
        <Tooltip
          title={t('Help', {ns: 'common'})}
          arrow={true}
          placement="right"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
        >
          <a
            href={DEFAULT_HELP_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <RiQuestionLine className={`w-5 h-5 min-w-5 hover:text-memoryBluePrimary`} />
          </a>
        </Tooltip>
        <Tooltip
          title={t('Chat', {ns: 'common'})}
          arrow={true}
          placement="right"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
        >
          <button onClick={() => {
            try {
              window.$crisp.push(['do', 'chat:show']);
              window.$crisp.push(['do', 'chat:open']);
            } catch (e) {
              console.error('Chat opening failed')
            }
          }}>
            <RiChat3Line className="w-5 h-5 min-w-5 hover:text-redPrimary" />
          </button>
        </Tooltip>
        <Tooltip
          title={t('Logout', {ns: 'common'})}
          arrow={true}
          placement="right"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
        >
          <button onClick={() => signOut()}>
            <RiLogoutCircleLine className="w-5 h-5 min-w-5 hover:text-redPrimary" />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

export default NavBarDashboard