import React from 'react'
import { useTranslation } from 'react-i18next'
// Components
import WhiteTooltip from "../elements/tooltip/WhiteTooltip";


const TagGroups = ({groups, tooltipPosition="right-start", labels}) => {

  // Translations
  const { t } = useTranslation('common')

  return (
    <>
      <WhiteTooltip
        title={
          <>
            <ul className="gap-y-2 px-1 py-1 text-grayPrimary">
              {groups.map((group, id) => (
                <li key={id} title={group.name} className="text-grayPrimary">
                  {group.name}
                </li>
              ))}
            </ul>
          </>
        }
        className=""
        arrow={true}
        placement={tooltipPosition}
      >
        <span className="cursor-pointer bg-gray-100 hover:bg-gray-200 border border-grayLight text-xs font-semibold text-grayPrimary inline rounded-full p-0.5 px-2">
          {`${groups.length} `}
          { groups.length > 1 ?
            labels?.plural ? labels.plural : t('group.groups')
          :
            labels?.singular ? labels.singular : t('group.group')
          }
        </span>
      </WhiteTooltip>
    </>
  )
}

export default TagGroups
