import React, {useState, useEffect, useContext, useRef} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
// Firebase
import { FirebaseContext } from '../../../../firebase'
// Redux
import {useDispatch, useSelector} from "react-redux"
import { PlayNotifications } from 'redux/notifications/PlayNotifications'
// Components
import LayoutDashboard from 'components/layout/LayoutDashboard'
import Headings from "components/elements/Typography/Headings"
import InputText from "components/forms/input/InputText"
import Alert from "components/modals/Alert"
import Select from "components/forms/select/Select"
import {
  RiArrowLeftLine,
  RiCheckLine,
  RiDeleteBin2Line,
  RiLoader4Line, RiStarFill, RiUserStarLine
} from "react-icons/ri"
// Utils
import { baseRole } from './formSetting/BaseRole'
import Button from "components/elements/button/Button"
import slugify from 'react-slugify'
import history from "utils/history"
import {DEFAULT_HELP_LINK, NAV_LINK, NAV_LINK_ROUTER} from "utils/constantsFront";
import {Fade, Tooltip} from "@mui/material";
import {EDIT_ROLE_NAV, SUB_NAV_SETTINGS} from "utils/dataNavigation";
import {ROLES_MENU} from "utils/constantsBackOffice";
import {SelectTab} from "redux/users/SelectTab";
import scrollDebounce from 'utils/scrollDebounce'
import _ from "lodash";
import SubNavDashboard from "components/elements/navigation/SubNavDashboard";

const AddRole = ({ selectedMenu, currentUser, entity }) => {

  // Translations
  const { t } = useTranslation(['common', 'messages', 'dashboard', 'roles'])

  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const currentUserData = useSelector(state => state.userData.state)
  const inputNameRef = useRef()
  const sectionsContainerRef = useRef()
  const { hash } = useLocation()

  const [ready, setReady] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [state, setState] = useState({})
  const [alert, setAlert] = useState(null)
  const [roles, setRoles] = useState([])
  const [authorizedRoles, setAuthorizedRoles] = useState([])
  const [switchRole, setSwitchRole] = useState({})

  const [anchors] = useState(EDIT_ROLE_NAV)
  const [subNav, setSubNav] = useState(null)

  // Edit role
  let currentUrl = useParams() // observes a change of url
  const [currentRoleId, ] = useState(currentUrl.id ? currentUrl.id : null)

  useEffect(() => {
    document.title = `Memory - ${t('users.Users-management', {ns: 'dashboard'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if( Object.keys(state).length ){
      setReady(true)
    }
  }, [state])

  useEffect(() => {
    if (ready) {
      inputNameRef?.current?.focus()

      changeTab(anchors[0].slug)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready])

  /* Scroll Event and style */
  useEffect(() => {
    if (hash) {
      scrollDebounce(scrollToLocation())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  useEffect(() => {
    let roleSnapUnsubscribe
    let rolesSnapUnsubscribe
    if(currentRoleId){
      setIsEdit(true)

      roleSnapUnsubscribe = firebase.db.collection('roles').doc(currentRoleId).onSnapshot( async doc => {
        if (doc.exists) {
          const role_ = doc.data()
          setState(role_)
          getSubNavItems()
        } else {
          setReady(true)
        }
      })

      rolesSnapUnsubscribe = firebase
        .db
        .collection('roles')
        .onSnapshot(snapshot => {
          const roles_ = snapshot.docs.map(doc => doc.data()).filter((item) => item.id!==currentRoleId)
          let data = roles_.sort((a,b) => a.name.localeCompare(b.name, { sensitivity: 'base' }))
          setRoles(data)

          let viewer = data.filter((item) => item.id === 'viewer')
          setSwitchRole(viewer.length > 0 ? viewer[0] : {})

          const rolesId = roles_.map(doc => doc.id)
          setAuthorizedRoles(rolesId)
        })

      return () => {
        roleSnapUnsubscribe()
        rolesSnapUnsubscribe()
      }
    } else {
      setReady(true)
      getSubNavItems()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoleId])

  const getSubNavItems = () => {
    const roleSubNav = anchors.reduce(function(results, obj){
      let hasOption = true
      if(obj.hasOwnProperty('option')){
        hasOption = _.get(entity, `options.${obj.option}`) === true
      }

      if(hasOption === true){
        results.push({
          name_locale: obj.name_locale,
          slug: obj.slug,
          icon: obj.icon,
          path: obj.anchor
        })
      }

      return results
    }, [])

    setSubNav(roleSubNav)
  }

  const setPath = (object, path, value) => {
    return path
      .split('.')
      .reduce((o, p, i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)
  }
  const resolvePath = (object, path, defaultValue = '') => {
    return path
      .split('.')
      .reduce((o, p) => o ? o[p] : defaultValue, object)
  }

  let index = 0
  const renderBaseForm = (data) => {
    const form = []
    let groupCount = 1

    for(const [key,val] of Object.entries(data)){
      if(val.type === 'main_fieldset_group') {
        form.push(
          <div key={index} className='flex flex-col gap-y-12'>
            { renderBaseForm(val.fields) }
          </div>
        )
      } else if(val.type === 'fieldset_group') {
        form.push(
          <div key={index} className=''>
            <Headings display="display-5" variant="div" className="z-20 bg-gray-200 w-full py-3 px-4 mb-2 sticky top-16">
              {groupCount} - {t(val.key, {ns: 'roles'})}
            </Headings>
            { renderBaseForm(val.fields) }
          </div>
        )
        groupCount++
      } else if(val.type === 'section'){
        form.push(
          <section id={val.anchor} key={index} className='mt-8'>
            <Headings display="display-5" variant="div" className="z-0 bg-gray-100 flex items-center gap-x-2 w-full py-2 px-4 mb-2 font-semibold  sticky top-28">
              {val.icon && val.icon}
              {t(val.key, {ns: 'roles'})}
            </Headings>
            { renderBaseForm(val.fields) }
          </section>
        )
      } else if(val.type === 'fieldset'){
        form.push(
          <div key={index} className={`${val.last === true ? 'min-h-100vh mb-20' : 'mb-6'}`}>
            {val.key &&
              val.optional === true ?
                <Tooltip
                  title={t('Optional-feature', {ns: 'common'})}
                  arrow={true}
                  placement="right"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 300 }}
                  disableInteractive={true}
                >
                  <div className="w-fit">
                    <Headings display="display-6" variant="div" className="flex flex-wrap items-center cursor-pointer">
                      {t(val.key, {ns: 'roles'})}
                      <RiStarFill className="w-4 h-4 ml-1 text-yellowPrimary" />
                    </Headings>
                  </div>
                </Tooltip>
              :
                <Headings display="display-6" variant="div">
                  {t(val.key, {ns: 'roles'})}
                </Headings>

            }
            <div key={index} className="border border-grayLight p-2 px-5 mt-2 mb-6 rounded-md bg-white">
              { renderBaseForm(val.fields) }
            </div>

            {val.last === true &&
              <div className="flex items-center gap-x-1 text-sm">
                <RiStarFill className="w-4 h-4 ml-1 text-yellowPrimary" /> {t('Optional-feature', {ns: 'common'})}
                <span>
                  -
                </span>
                <span>
                  {t('For-more-informations', {ns: 'common'})}: <a href="mailto:contact@my-memory.io" className="text-memoryBluePrimary">contact@my-memory.io</a>
                </span>
              </div>
            }
          </div>
        )
      } else if(val.type === 'field') {
        if(val.hasOwnProperty('option_dependency')){
          const hasOption = _.get(entity, `options.${val.option_dependency}`)

          if( hasOption !== true ){
            continue;
          }
        }


        if(val.input) {
          switch (val.input){
            case 'text':
              form.push(
                <div key={val.key}>
                  <InputText
                    id={val.key}
                    name={val.key}
                    label={t(val.key, {ns: 'roles'})}
                    placeHolder={t('Name', {ns: 'common'})}
                    state={state[val.key] ? state[val.key] : ''}
                    setstate={handleChangeText}
                    refInput={inputNameRef}
                    required
                  />
                </div>
              )
              break
            case 'checkbox':
              form.push(
                <div key={val.key} className={`pt-2 pb-2 ${val.fieldset === false ? 'mb-2 mt-2' : ''}`}>
                  <label className='text-sm font-medium inline-flex items-center mr-1'>
                    {t(val.key, {ns: 'roles'})}
                    {val.optional === true && <RiStarFill className="w-4 h-4 ml-1 text-yellowPrimary" />} :
                  </label>
                  <input type='checkbox' value='1' name={val.key} id={val.key} onChange={handleCheck} checked={resolvePath(state, val.key) ? true : false} />
                </div>
              )

              if(key !== 'all_rights' && !resolvePath(state, val.key)){
                let tmpState = state
                setPath(tmpState, 'all_rights', false)
              }
              break
            default:
              break
          }
        }
      } else if(val.type === 'title') {
        form.push(
          <Headings key={key} display="display-6" variant="div" className="pt-8 pb-2 underline" weight="font-normal">
            {t(val.key, {ns: 'roles'})}
          </Headings>
        )
      } else if(val.type === 'help' && key === 'app_model_help' ) {
        form.push(
          <div key={key}>
            <Headings display="display-6" variant="div" className="pt-8 pb-2 underline" weight="font-normal">
              {t('help.help', {ns: 'roles'})}
            </Headings>
            <a
              href={DEFAULT_HELP_LINK}
              target="_blank"
              rel="noreferrer"
              className={'w-full text-left py-2 text-sm text-gray-700 flex items-center'}
            >
              {t('help.what-is-collaborator', {ns: 'roles'})}
            </a>
            <a
              href={DEFAULT_HELP_LINK}
              target="_blank"
              rel="noreferrer"
              className={'w-full text-left py-2 text-sm text-gray-700 flex items-center'}
            >
              {t('help.what-is-participant', {ns: 'roles'})}
            </a>
            <a
              href={DEFAULT_HELP_LINK}
              target="_blank"
              rel="noreferrer"
              className={'w-full text-left py-2 text-sm text-gray-700 flex items-center'}
            >
              {t('help.what-is-parent-content', {ns: 'roles'})}
            </a>
            <a
              href={DEFAULT_HELP_LINK}
              target="_blank"
              rel="noreferrer"
              className={'w-full text-left py-2 text-sm text-gray-700 flex items-center'}
            >
              {t('help.what-is-child-content', {ns: 'roles'})}
            </a>
            <a
              href={DEFAULT_HELP_LINK}
              target="_blank"
              rel="noreferrer"
              className={'w-full text-left py-2 text-sm text-gray-700 flex items-center'}
            >
              {t('help.what-is-single-content', {ns: 'roles'})}
            </a>
          </div>
        )
      }

      index++
    }

    return form
  }

  const changeTab = (tabKey) => {
    dispatch(
      SelectTab({
        selectedTab: tabKey
      })
    )
  }

  const scrollToLocation = () => {
    let id = hash.replace('#', '')
    const element = document.getElementById(id)

    for(const anchor of anchors){
      if(anchor.path === hash) {
        changeTab(anchor.slug)
      }
    }

    if (element && id !== '') {
      const container = sectionsContainerRef.current
      const elementPosition = element.offsetTop
      const offsetPosition = elementPosition - container.offsetTop + 40

      container.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }

  const onScroll = () => {
    let sections = document.querySelectorAll('#sections-container section')
    let containerTop = sectionsContainerRef.current.offsetTop + 80

    if(sections) {
      sections.forEach((section, index) => {
        if(
          section.getBoundingClientRect().top < containerTop + 8 &&
          section.getBoundingClientRect().bottom > containerTop
        ) {
          if(anchors[index] && anchors[index]?.hasOwnProperty('slug')){
            changeTab(anchors[index].slug)
          }
        }
      })
    }
  }

  /* Scroll Event and style */
  useEffect(() => {
    let container = sectionsContainerRef.current
    container && container.addEventListener('scroll', () => scrollDebounce(onScroll()))

    return () => {
      container.removeEventListener('scroll', (e) => scrollDebounce(onScroll(e)))
    }
  })

  const stateCheckAll = (tmpState, data) => {
    for(const [,val] of Object.entries(data)){
      if(val.type === 'field' && val.input === 'checkbox') {
        setPath(tmpState, val.key, true)
      } else if(val.fields !== undefined){
        tmpState = stateCheckAll(tmpState, val.fields)
      }
    }

    return tmpState
  }

  const handleChangeText = (e) => {
    setState({...state, [e.target.id] : e.target.value})
  }

  const handleCheck = (e) => {
    let tmpState = state
    setPath(tmpState, e.target.id, e.target.checked)
    if( e.target.id === 'all_rights' && e.target.checked === true ){
      tmpState = stateCheckAll(tmpState, baseRole)
    }
    if( e.target.id !== 'all_rights' && !e.target.checked ){
      tmpState.all_rights = false
    }
    setState({...tmpState})
    // setState({...state, [resolvePath(state, e.target.id)] : e.target.checked})
  }

  const submitRole = () => {
    setFormLoading(true)

    if(isEdit){
      let newRole = state
      newRole.details.name = state.name
      newRole.details.locales.fr_FR = state.name

      const updateRole = firebase.functions.httpsCallable("manageRole");
      updateRole({
        action: 'update',
        roleData: newRole,
        roleId: state.id
      })
      .then((result) => {
        dispatch(
          PlayNotifications({
            display: true,
            theme: "success",
            message: t('success.modified_withVal', {ns: 'messages', val: t('role.Role', {ns: 'common'})}),
          })
        )

        history.push(`/dashboard/settings/roles`)
      })
      .catch((error) => {
        dispatch(
          PlayNotifications({
            display: true,
            theme: "danger",
            message: t('error.saved-error_withVal', {ns: 'messages', val: (t('role.role', {ns: 'common'}).toLowerCase())}),
          })
        )
        setFormLoading(false)
        console.error(`Error creating role`, error)
      })
    } else {
      const slug = slugify(state.name,{delimiter:"_"})
      const newRole = {
        ...state,
        id: slug
      }

      const createRole = firebase.functions.httpsCallable("manageRole");
      createRole({
        action: 'create',
        roleData: newRole,
        roleId: null
      })
      .then((result) => {
        dispatch(
          PlayNotifications({
            display: true,
            theme: "success",
            message: t('success.created_withVal', {ns: 'messages', val: t('role.Role', {ns: 'common'})}),
          })
        )

        history.push(`/dashboard/settings/roles`)
      })
      .catch((error) => {
        dispatch(
          PlayNotifications({
            display: true,
            theme: "danger",
            message: t('error.saved-error_withVal', {ns: 'messages', val: (t('role.role', {ns: 'common'}).toLowerCase())}),
          })
        )
        setFormLoading(false)
        console.error(`Error creating role`, error)
      })
    }
  }

  const deleteRole = () => {
    if(authorizedRoles.includes(switchRole.id)){
      setDeleteLoading(true)
      setAlert(false)

      const deleteRole = firebase.functions.httpsCallable("manageRole");
      deleteRole({
        action: 'delete',
        roleData: {switchRoleId: switchRole.id},
        roleId: state.id
      }).then((result) => {
        setDeleteLoading(false)
        dispatch(
          PlayNotifications({
            display: true,
            theme: "success",
            message: t('success.removed_withVal', {ns: 'messages', val: t('role.Role', {ns: 'common'})}),
          })
        )

        history.push(`/dashboard/settings/roles`)
      }).catch((error) => {
        dispatch(
          PlayNotifications({
            display: true,
            theme: "danger",
            message: t('error.saved-error_withVal', {ns: 'messages', val: (t('role.role', {ns: 'common'}).toLowerCase())}),
          })
        )
        setDeleteLoading(false)
        console.error(`Error creating role`, error)
      })
    }
  }

  return (
    <LayoutDashboard
      currentUser={currentUser}
      entity={entity}
      headerNav={SUB_NAV_SETTINGS}
      headerNavSelected={ROLES_MENU}
      selectedMenu={selectedMenu}
      subNav={{type: NAV_LINK_ROUTER, items: SUB_NAV_SETTINGS}}
      maxHeight={false}
      title={t('settings.Roles-management', {ns: 'dashboard'})}
      description={
        <Trans
          i18nKey="settings.roles-global-text"
          components={{ br: <br/>}}
          ns="dashboard"
        />
      }
      noticeLink={{
        href: "https://beecomeio.notion.site/Configuration-de-votre-espace-adf43d3fcf564cd0a1d5a25ec3bb1508#9d72072d9c3647738c95962b549b5f53",
        label: t('users.global-help', {ns:'dashboard'})
      }}
    >
      <div ref={sectionsContainerRef} id="sections-container" className="w-full max-w-100vw overflow-auto memoryScrollbar">
        <header className="z-40 bg-white w-full pb-6 sticky top-0">
          <ul className="flex items-center justify-between mb-2">
            <li className="flex items-center gap-2">
              <RiUserStarLine className="w-5 h-5 min-w-5" />
              <Headings variant="h2" className="flex items-center" display="display-4">{t('role.Role', {ns:'common'})}: {state.name}</Headings>
            </li>
          </ul>
        </header>

        <div className="flex pr-4 relative">
          <aside className="relative w-0 lg:w-1/4 hidden lg:block relative overflow-hidden">
            <div className="fixed w-fit gap-y-2 overflow-hidden">
              {subNav &&
                <SubNavDashboard
                  entity={entity}
                  type={NAV_LINK}
                  items={subNav ? subNav : null}
                  primaryButton={null}
                  primaryAction={null}
                  responsiveMenuClosed={``}
                  setResponsiveMenuClosed={() => {}}
                  tooltip={false}
                  // active_menu={headerNavSelected}
                />
              }
            </div>
          </aside>

          <div className="w-full lg:w-3/4 pb-5 space-y-2">
            <div className={`flex flex-col w-full h-full pb-2 pr-4 pb-0 bg-white overflow-visible`}>
              <div className="">
                {alert &&
                  <Alert
                    title={`${t('action.Delete', {ns: 'common'})} ${t('role.role_one', {ns: 'common'})}`}
                    state={alert}
                    setstate={setAlert}
                    actionPrimary={deleteRole}
                    btnPrimary={t('action.Confirm', {ns: 'common'})}
                    btnSecondary={t('action.Back', {ns: 'common'})}
                    overflow='overflow-y-show'
                  >
                    <p>{t('alert.delete-permanently_withVal', {ns: 'messages', val: t('role.role_one', {ns: 'common'})})} <span className="text-gray-600 font-semibold">{state.name}</span>.</p>
                    <p className='mt-6'>{t('role.Users-with-role-replace', {ns: 'common'})}</p>
                    <div>
                      {roles.length > 0 && (
                        <Select
                          name="import-user-type"
                          label={t('role.Replace-with', {ns:'common'})}
                          className=""
                          data={roles}
                          state={switchRole}
                          setstate={setSwitchRole}
                          required={true}
                          placeHolder={`${t('action.Select', {ns: 'common'})} ${t('user.type-user_one', {ns: 'common'})}`
                          }
                        />
                      )}
                    </div>
                    <p className='mt-6'>{t('alert.what-do-you-want-to-do', {ns: 'messages'})}</p>
                  </Alert>
                }

                {ready ?
                  <div className="pb-60">
                    {renderBaseForm(baseRole)}
                  </div>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="z-50 flex items-center mt-2 border-t-2 border-gray-400 bg-gray-100 justify-between pb-6 pt-6 px-4 space-x-2 sticky bottom-0">
        <Button
          actionType="route"
          action="/dashboard/settings/roles"
          theme={ deleteLoading || formLoading ? 'disabled' : 'simple'}
          size="small"
          icon={<RiArrowLeftLine className="mr-1" />}
        >
          {t('action.Back')}
        </Button>
        {isEdit ?
          <div className="flex flex-col gap-4 min480:flex-row">
            {state.updatable !== false && currentUserData.authorizations?.administration?.roles?.delete === true && (
              <Button
                theme={ deleteLoading || formLoading ? 'disabled' : 'secondary'}
                size="small"
                className="min480:mr-4"
                icon={deleteLoading ? <RiLoader4Line className="w-4 h-4 ml-1 animate-spin" /> : <RiDeleteBin2Line className="w-4 h-4 ml-1" />}
                iconPosition="right"
                actionType="action"
                action={() => setAlert(true)}
              >
                {t('action.Delete', {ns: 'common'})}
              </Button>
            )}

            {currentUserData.authorizations?.administration?.roles?.update === true && (
              <Button
                theme={ deleteLoading || formLoading || state.name===undefined || state.name?.length === 0 ? 'disabled' : ''}
                size="small"
                icon={formLoading ? <RiLoader4Line className="w-4 h-4 ml-1 animate-spin" /> : <RiCheckLine className="w-4 h-4 ml-1" />}
                iconPosition="right"
                actionType="action"
                action={() => submitRole()}
              >
                {t('action.Edit', {ns: 'common'})}
              </Button>
            )}
          </div>
          :
          currentUserData.authorizations?.administration?.roles?.create === true && (
            <Button
              theme={formLoading || state.name===undefined || state.name?.length === 0 ? 'disabled' : ''}
              size="small"
              icon={formLoading ? <RiLoader4Line className="w-4 h-4 ml-1 animate-spin" /> : <RiCheckLine className="w-4 h-4 ml-1" />}
              iconPosition="right"
              actionType="action"
              action={() => submitRole()}
            >
              {t('action.Create', {ns: 'common'})}
            </Button>
          )
        }
      </div>
    </LayoutDashboard>
  )
}

export default AddRole
