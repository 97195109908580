import React, { useState } from 'react'
// Components
import NavBarDashboard from "components/elements/navigation/NavBarDashboard"
import SubNavDashboard from 'components/elements/navigation/SubNavDashboard'
import {RiMenu2Line} from "react-icons/ri"
// import DashboardLayoutHeader from "../applicationUi/DashboardLayoutHeader";
// import {NAV_EMPTY} from "../../utils/constantsFront";


const LayoutDashboard = (props) => {

  const {
    asideClass,
    children,
    description,
    enableScroll = false,
    entity,
    headerNav = [],
    headerNavSelected = '',
    maxHeight = true,
    noticeLink,
    selectedMenu,
    subNav = 'NAV_EMPTY',
    title,
  } = props


  const [responsiveMenuClosed, setResponsiveMenuClosed] = useState(true)

  return (
    <>
      <main className="fixed flex w-screen min-w-100vw h-screen max-h-screen bg-grayDashboard overflow-hidden dashboard">
        <NavBarDashboard
          selectedMenu={selectedMenu}
          responsiveMenuClosed={responsiveMenuClosed}
          setResponsiveMenuClosed={setResponsiveMenuClosed}
        />

        <div className="w-full">
          {subNav ?
            <section className="flex flex-col w-full h-full p-6 overflow-hidden lg:pl-8 lg:pt-10 lg:pb-0 lg:pr-14 xl:pr-16">
              <div className={`flex flex-col h-full ${maxHeight ? 'pb-0' : 'pb-0'} lg:flex-row lg:overflow-hidden`}>
                <aside className={`flex flex-col w-full lg:w-1/5 lg:min-w-200px`}>
                  {/*<DashboardLayoutHeader*/}
                  {/*  title={title}*/}
                  {/*  description={description}*/}
                  {/*  headerNav={headerNav}*/}
                  {/*  headerNavSelected={headerNavSelected}*/}
                  {/*  noticeLink={noticeLink}*/}
                  {/*/>*/}

                  {/*<SubNavDashboard*/}
                  {/*  entity={entity}*/}
                  {/*  type={subNav.type}*/}
                  {/*  items={subNav.items ? subNav.items : null}*/}
                  {/*  primaryButton={subNav.primaryButton ? subNav.primaryButton : null}*/}
                  {/*  primaryAction={subNav.primaryAction ? subNav.primaryAction : null}*/}
                  {/*  responsiveMenuClosed={responsiveMenuClosed}*/}
                  {/*  setResponsiveMenuClosed={setResponsiveMenuClosed}*/}
                  {/*  tooltip={subNav.tooltip ? subNav.tooltip : false}*/}
                  {/*  active_menu={headerNavSelected}*/}
                  {/*/>*/}
                </aside>

                <article className={`flex flex-col w-full h-full overflow-auto memoryScrollbar p-4 ${enableScroll ? 'pl-8 pr-6 pt-0' : 'lg:overflow-hidden px-8'} ${maxHeight ? '' : 'pb-0'} bg-white border border-grayLight border-opacity-50 rounded-md ${asideClass ? asideClass : ''}`}>
                  { children }
                </article>
              </div>
            </section>
          :
            children
          }
        </div>

        {/* MENU BURGER */}
        <div
          className="z-60 fixed bottom-4 left-4 p-3 text-memoryBluePrimary bg-memoryBlueTertiary border border-memoryBluePrimary rounded-full lg:hidden"
          onClick={() => setResponsiveMenuClosed(prev => !prev)}
          role="button"
        >
          <RiMenu2Line className="w-6 h-6" />
        </div>
      </main>
    </>
  )
}

export default LayoutDashboard