import React, { useState, useEffect, useContext } from 'react'
import {useTranslation} from "react-i18next"
import { useHistory } from 'react-router-dom'
// Firebase
import { FirebaseContext } from "../../../firebase/"
// Redux
// Components
import LayoutDashboard from 'components/layout/LayoutDashboard'
import ContentIsEmpty from 'components/elements/divContainer/ContentIsEmpty'
import Headings from 'components/elements/Typography/Headings'
import Pagination from 'components/elements/pagination/Pagination'
import InputSearch from 'components/forms/search/InputSearch'

import {RiListCheck3} from 'react-icons/ri'
// Utils
import { makeCaseAndAccentInsensitiveString } from 'utils/stringUtils'

import {
  PROJECT,
  MAX_PAGINATION_DASHBOARD_EXTENDED,
  PROJECT_JOBS_MENU
} from 'utils/constantsBackOffice'
import TableProjectJobs from "components/applicationUi/TableProjectJobs";
// Hooks
import useSearchItemsProcess from 'hooks/useSearchItemsProcess'
import usePagination from 'hooks/usePagination'
// Others
import 'assets/style/dashboard.css'


const DashboardProjectJobs = ({selectedMenu, currentUser}) => {
  // Translations
  const { t } = useTranslation(['common', 'dashboard', 'messages'])

  const firebase = useContext(FirebaseContext)
  const history = useHistory()

  const [ 
    searchingList,
    setSearchingList, 
    makeItemSearch
  ] = useSearchItemsProcess()

  const [
    currentPage,
    paginatedData,
    totalPages,
    isLoadingPage,
    tableMetrics,
    ,
    returnPrevPage,
    returnNextPage,
    selectPage,
    setDataPagination
  ] = usePagination(MAX_PAGINATION_DASHBOARD_EXTENDED)
  
  const [dataJobs, setDataJobs] = useState([])

  const [dataLoaded, setDataLoaded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredList, setFilteredList] = useState([])
  
  const getTypeTranslation = (type) => {
    switch (type) {
      case 'user':
        return t('member.Members')
      case 'content':
        return t('content.Contents')
      case 'structure':
        return t('structure.Structures')
      case 'attribute':
        return t('customAttribute.customAttributes')
      default:
        return type
    }
  }

  const getTradKeyStatus = (status) => {
    switch(status?.toLowerCase()) {
      case 'request':
      case 'requested':
        return t('Pending')
      case 'in_progress':
      case 'working':
        return t('In-progress')
      case 'success':
      case 'complete':
        return t('Complete')
      case 'failed':
      case 'failure':
        return t('Failed')
      default:
        return status
    }
  }

  const getMoreInfo = () => {
    return '';
  }
  
  const getJobs = () => {
    firebase
      .db
      .collection('projects_client_jobs')
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshot => {
        setDataLoaded(true)
        const jobs_ = snapshot.docs.map(doc => {
          return {...doc.data(), ...{
              translated_type: getTypeTranslation(doc.data().type),
              translated_detail: '',
              translated_status: getTradKeyStatus(doc.data().status),
            }}
        })
        setDataJobs(jobs_)
      });
  }

  const clearSearch = () => {
    setSearchValue('')
    makeItemSearch('', filteredList, PROJECT)
  }

  const searchProjects = (e) => {
    let normalizedValue = makeCaseAndAccentInsensitiveString(e.target.value)
    let data = filteredList

    setSearchValue(normalizedValue)
    makeItemSearch(normalizedValue, data, PROJECT)
  }

  /* Get datas */
  useEffect(() => {
    let jobsSnapUnsubscribe;
    setDataLoaded(false);
    setSearchingList(null);
    setSearchValue('');

    if( currentUser ){
      jobsSnapUnsubscribe = getJobs();
    } else {
      history.push('/dashboard/projects/jobs');
    }

    return () => {
      jobsSnapUnsubscribe && jobsSnapUnsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  /* Update datas */
  useEffect(() => {
      let data = dataJobs

      setFilteredList(data)

      if (searchingList !== null) {
        makeItemSearch(searchValue, data, PROJECT)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataJobs])

  /* Pagination */
  useEffect(() => {
    setDataPagination(searchingList, filteredList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList, searchingList, currentPage])

  useEffect(() => {
    document.title = `Memory - ${t('projects.Projects-management', {ns: 'dashboard'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutDashboard
        currentUser={currentUser}
        // headerNav={SUB_NAV_MEMBERS}
        headerNavSelected={PROJECT_JOBS_MENU}
        selectedMenu={selectedMenu}
        // subNav={ {type: NAV_LINK_ROUTER, items: SUB_NAV_MEMBERS} }
        title={t('projects.Projects-management', {ns: 'dashboard'})}
        description={t('projects.Projects-global-text', {ns:'dashboard'})}
        noticeLink={{
          href: "https://beecomeio.notion.site/Configuration-de-votre-espace-adf43d3fcf564cd0a1d5a25ec3bb1508#9d72072d9c3647738c95962b549b5f53",
          label: t('projects.global-help', {ns:'dashboard'})
        }}
        enableScroll={true}
      >
        <header className="mb-2 sm:mb-8 pt-4">
          <ul className="flex justify-between items-center gap-2 mb-2">
            <li className="flex items-center gap-2">
              <RiListCheck3 className="w-5 h-5 min-w-5" />
              <Headings variant="h2" display="display-4">{t('jobs.Jobs', {ns:'dashboard'})}</Headings>
            </li>
            <li className="flex gap-4"></li>
          </ul>
          <article className="flex flex-col text-sm max-h-10 overflow-auto memoryScrollbar lg:max-h-full lg:overflow-hidden">
            {/*<p>{t('projects.Infos-projects', {ns:'dashboard'})}</p>*/}
            {/*<p>{t('projects.Infos-projects-tip', {ns:'dashboard'})}</p>*/}
          </article>
        </header>

        {/* SEARCH */}
        <>
          {(currentUser?.is_administrator === true ||
            currentUser?.authorizations?.administration?.projects?.list === true) &&
            <span className="flex flex-col gap-2 w-full mb-2 sm:flex-row">
              <InputSearch
                labelHidden
                name="search-user"
                loading={!dataLoaded}
                placeHolder={t('action.Search-in-list')}
                className="inline-flex"
                setstate={(e) => searchProjects(e)}
                state={searchValue}
                clear={true}
                setClear={() => clearSearch()}
              />
            </span>
          }
        </>

        {/* TABLE */}
        {(currentUser?.is_administrator === true ||
          currentUser?.authorizations?.administration?.projects?.list === true) ?

          (dataLoaded && dataJobs?.length === 0) ?
            <ContentIsEmpty>
              {t('projects.empty-projects', {ns:'dashboard'})}
            </ContentIsEmpty>
          :
            <>
              <div className={`flex items-center text-xs mb-2`}>
                <span className="">
                  {tableMetrics}
                </span>
              </div>

              <TableProjectJobs
                data={paginatedData}
                dataLoaded={dataLoaded}
                getTypeTranslation={getTypeTranslation}
                getMoreInfo={getMoreInfo}
                getTradKeyStatus={getTradKeyStatus}
              />

              {dataLoaded &&
                <div className="flex justify-center mt-3">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onClickPage={selectPage}
                    onClickPrevious={returnPrevPage}
                    onClickNext={returnNextPage}
                    isloading_card={isLoadingPage}
                  />
                </div>
              }
            </>
          :
          <ContentIsEmpty>
            {t('alert.access-denied-to-this-page', {ns: 'messages'})}
          </ContentIsEmpty>
        }
      </LayoutDashboard>
    </>
  )
}

export default DashboardProjectJobs
