import React, {useEffect, useContext, useState, Suspense} from 'react'
import i18n from './translates/i18n';
import { FirebaseContext } from './firebase';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { AddUserData } from './redux/userData/AddUserData';
import { RemoveUserData } from './redux/userData/RemoveUserData';
// Components
import Logo from './assets/images/app/svg/Logo.svg';
import './App.css';
import { BarLoader } from 'react-spinners';
// import {Routes, Route} from 'react-router';
import Error404 from './pages/Error404';
import EntityRequest from "./pages/EntityRequest";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Router,
  Redirect,
  useLocation,
} from "react-router-dom";
import LoginPage from './pages/authentication/Login'
import ChangePasswordProcedure from "./pages/authentication/ChangePasswordProcedure";
import ForgetPassword from "./pages/authentication/ForgetPassword";
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import history from './utils/history'
import DashboardProjects from "./pages/dashboard/projects/DashboardProjects";
import DashboardUsers from "./pages/dashboard/members/users/DashboardUsers";
import {
  MEMBERS_MENU, PROJECTS_MENU, SETTINGS_MENU, PROJECT_JOBS_MENU
} from './utils/constantsBackOffice'
import AddRole from "./pages/dashboard/settings/roles/AddRole";
import ProjectPage from "./pages/dashboard/projects/ProjectPage";
import Notifications from './components/modals/Notifications'
import DashboardProjectJobs from "./pages/dashboard/jobs/DashboardProjectJobs";

const RouterApp = () => {

  const dispatch = useDispatch()
  const firebase = useContext(FirebaseContext)
  const location = useLocation();
  const currentNotification = useSelector(state => state?.notifications?.state)

  const [ready, setReady] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [currentUserRights, setCurrentUserRights] = useState();
  const [userIsLogged, setUserIsLogged] = useState(null);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [roleReady, setRoleReady] = useState(false);
  const [dashboardAccess, setDashboardAccess] = useState(false);
  const [backStack, setBackStack] = useState([]);

  useEffect(() => {
    history.listen((location, action) => {
      // if(action !== 'PUSH'){}
      setBackStack(backStack => {
        switch (action) {
          case 'POP':
            return backStack.slice(0, backStack.length - 1);
          case 'PUSH':
            return [...backStack, location];
          case 'REPLACE':
            return [...backStack.slice(0, backStack.length - 1), location];
          default:
            break
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "0f144f1d-1c40-456e-9521-42de9d13e02e";

    (function() {
      var d = document
      var s = d.createElement("script")

      s.src = "https://client.crisp.chat/l.js"
      s.async = 1
      d.getElementsByTagName("head")[0].appendChild(s)
    })();

    setReady(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let listener = firebase.auth.onAuthStateChanged(user => {
      if (user && firebase?.auth?.currentUser) {
        firebase.auth.currentUser.getIdTokenResult(true)
          .catch((error) => {
            console.error(error);
          });

        const docRef = firebase.db.collection('users').doc(user._delegate.uid);

        docRef.onSnapshot((doc) => {
          if (doc.exists) {
            if (doc.data().language){
              i18n.changeLanguage(doc.data().language)

              if(doc.data().language === 'en' || doc.data().language === 'en_GB') {
                moment.locale('en-gb')
              } else {
                moment.locale('fr')
              }
            }

            if(doc.data().is_administrator === true){
              setUserIsAdmin(true)
            }

            if(doc.data().id){
              const roleForUserRef = firebase.db.collection('roles').doc(doc.data().role)
              roleForUserRef.onSnapshot((roleDetails) => {
                if (roleDetails.exists) {
                  dispatch(
                    AddUserData(
                      { ...doc.data(), authorizations: { ...roleDetails.data() } }
                    )
                  )
                  setCurrentUser({ ...doc.data(), authorizations: { ...roleDetails.data() } })
                  setCurrentUserRights(roleDetails.data())
                  setDashboardAccess(roleDetails.data().administration?.access === true)
                  setRoleReady(true)
                } else {
                  dispatch(
                    AddUserData(
                      doc.data()
                    )
                  )
                  setRoleReady(true)
                  console.info('No such role!')
                }
              })
            } else {
              dispatch(
                AddUserData(
                  doc.data()
                )
              )
              setRoleReady(true)
              console.error('No such user !')
            }

            setUserIsLogged(true)
            setCurrentUser(doc.data())
            setReady(true)
          } else { console.error('No such document!') }
        })
      }
      if (!user) {
        dispatch(
          // Remove user data in redux
          RemoveUserData()
        )
        setUserIsLogged(false)
        setCurrentUser(null)
        setReady(true)
        setRoleReady(true)

        i18n.changeLanguage()
      }
    })
    return () => {
      listener()
    };

    // eslint-disable-next-line
  }, [firebase.auth]);

  const loading = () => {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <img src={Logo} alt="" className="w-20 h-20 mb-2" />
        <BarLoader color={"#504fe4"} height={3} width={120} />
        <span className="text-xs text-grayPrimary opacity-70 mt-1">Chargement de Memory</span>
      </div>
    )
  }

  if (!ready || !roleReady) {
    return (
      <>
        {loading()}
      </>
    )
  }
  else {
    return (
      <Suspense fallback={loading()}>
        <Notifications
          theme={currentNotification?.theme}
          message={currentNotification?.message}
          display={currentNotification?.display}
          delay={currentNotification?.delay ? currentNotification.delay : 3000}
        />

        {/*<Routes>*/}
        {/*  <Route path='/entity-request/:id' element={*/}
        {/*    <EntityRequest />*/}
        {/*  } />*/}
        {/*  <Route path='*' element={<Error404 />} />*/}
        {/*</Routes>*/}

        <Router history={history}>
          <Switch>
            <Route exact path='/entity-request/:id'>
              <EntityRequest />
            </Route>

            {/* AUTENTICATION ROUTE */}
            <Route exact path="/login/">
              <LoginPage />
            </Route>
            <Route exact path="/password-forgotten/">
              {!userIsLogged ? <ForgetPassword /> : <Redirect to="/" />}
            </Route>
            <Route path="/password-forgotten/change-your-password/">
              {!userIsLogged ? (
                <ChangePasswordProcedure />
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            {/* LOGGED ROUTE */}
            {/*<Route exact path="/parameters/">*/}
            {/*  {userIsLogged ? (*/}
            {/*    <ParametersPage />*/}
            {/*  ) : (*/}
            {/*    <Redirect to="/login/" />*/}
            {/*  )}*/}
            {/*</Route>*/}

            {/* DASHBOARD - BACK-OFFICE */}
            {/* MEMBERS */}
            <Route exact path="/dashboard/members/members">
              {userIsAdmin || (userIsLogged && dashboardAccess === true) ? (
                <DashboardUsers selectedMenu={MEMBERS_MENU} currentUser={currentUser} />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/dashboard/projects/projects">
              {userIsAdmin || (userIsLogged && dashboardAccess === true) ? (
                <DashboardProjects selectedMenu={PROJECTS_MENU} currentUser={currentUser} />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/dashboard/projects/projects/:id">
              {userIsAdmin || (userIsLogged && dashboardAccess === true && currentUserRights?.administration?.projects?.update ) ? (
                <ProjectPage selectedMenu={PROJECTS_MENU} currentUser={currentUser} />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/dashboard/projects/jobs">
              {userIsAdmin || (userIsLogged && dashboardAccess === true) ? (
                <DashboardProjectJobs selectedMenu={PROJECT_JOBS_MENU} currentUser={currentUser} />
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            {/*<Route exact path="/dashboard/settings/roles">*/}
            {/*  {userIsAdmin || (userIsLogged && dashboardAccess === true && currentUserRights?.administration?.roles?.create ) ? (*/}
            {/*    <DashboardRoles selectedMenu={SETTINGS_MENU} currentUser={currentUser} />*/}
            {/*  ) : (*/}
            {/*    <Redirect to="/" />*/}
            {/*  )}*/}
            {/*</Route>*/}
            <Route exact path="/dashboard/settings/roles/add">
              {userIsAdmin || (userIsLogged && dashboardAccess === true && currentUserRights?.administration?.roles?.create ) ? (
                <AddRole selectedMenu={SETTINGS_MENU} currentUser={currentUser} />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/dashboard/settings/roles/edit/:id">
              {userIsAdmin || (userIsLogged && dashboardAccess === true && currentUserRights?.administration?.roles?.update ) ? (
                <AddRole selectedMenu={SETTINGS_MENU} currentUser={currentUser} />
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route component={Error404} />
          </Switch>
        </Router>
      </Suspense>
    )
  }
}

export default RouterApp