/**
 * Composant de fenêtre modal
 */
import React, {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Button from '../elements/button/Button'
import { RiArrowRightSLine, RiCloseLine, RiLoader4Line } from 'react-icons/ri'
import {DEFAULT_MAIN_COLOR, DEFAULT_SECONDARY_COLOR} from "utils/constantsFront";

export default function Alert(props) {

  const {
    actionPrimary,
    actionSecondary = null,
    btnPrimary,
    btnSecondary = null,
    children,
    disabled,
    isLoading,
    isLoadingModal,
    isLoadingReady,
    layerPosition = '60',
    overflow = 'overflow-hidden',
    readyCountDown = 0,
    state,
    setstate,
    title,
    withCross = false,
  } = props

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={state} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-${layerPosition} inset-0 overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={() => null}
        static={true}
      >
        <div className="flex items-start pt-20 sm:items-end justify-center min-h-screen sm:pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`relative inline-block align-bottom bg-white rounded px-4 pt-5 pb-4 text-left ${overflow} shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6`}>
              {withCross && 
                <RiCloseLine 
                  onClick={() => setstate(false)}
                  className="absolute right-4 top-4 cursor-pointer h-6 w-6 text-grayLight hover:text-grayPrimary"
                />
              }
              
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-semibold">
                    {title}
                  </Dialog.Title>
                  <div className="flex flex-col gap-y-1 mt-2 text-sm text-gray-500 w-full">
                    {children}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex flex-wrap gap-y-4 sm:flex-nowrap justify-between">
                {btnSecondary ?
                  <Button
                    className="w-full sm:w-auto"
                    action={actionSecondary ? () => actionSecondary() : () => setstate(false)}
                    actionType={"action"}
                    icon={<RiCloseLine className="w-4 h-4 mr-1" />}
                    theme={isLoadingModal ? 'disabled' : 'simple'}
                  >
                    {btnSecondary}
                  </Button>
                  :
                  <span></span>
                }
                <Button
                  className="w-full sm:w-auto"
                  action={actionPrimary ? () => actionPrimary() : () => setstate(false)}
                  actionType={"action"}
                  icon={
                    isLoadingReady ?
                      null
                    :
                      isLoadingModal ?
                      <RiLoader4Line className="w-4 h-4 ml-1 animate-spin"/>
                      :
                      <RiArrowRightSLine className="w-4 h-4 ml-1"/>
                  }
                  iconPosition="right"
                  theme={disabled || isLoadingModal || isLoadingReady ? "disabled" : null}
                  loading={isLoading}
                >
                  {btnPrimary}
                  {(isLoadingReady) &&
                    <span className='ml-1 inline-block w-4'>
                      {readyCountDown}
                    </span>
                  }
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
