import React, {useRef} from 'react'


const CheckBox = (props) => {

  const {
    checked,
    children,
    className,
    description,
    disabled = false,
    setstate,
    state,
    value,
  } = props

  let { id, name, type } = props
  const inputRef = useRef()

  if (!type){
    type="checkbox"
  }
  if (!id){
    type="name"
  } 

  return (
    <fieldset>
      
      <div className={`relative flex items-start ${className}`}>
        <div className="flex items-center h-5 cursor-pointer">
          <input
            ref={inputRef}
            id={id}
            aria-describedby="comments-description"
            name={name ? name : id}
            type={type}
            className={`h-4 w-4 ${disabled===true ? "cursor-not-allowed" : "cursor-pointer"} animate`}
            checked={state ? state : checked ? true : false} 
            onChange={(e) => setstate(e)}
            value={value}
            disabled={disabled}
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            className={`font-medium ${disabled===true ? "cursor-not-allowed" : "cursor-pointer"}`}
            onClick={(e) => {
              if(inputRef.current) {
                inputRef.current.click()
              }
            }}
          >
            {children}
          </label>
          
          {description ?
            <p id="comments-description" className="text-gray-500">
              {description}
            </p>
          : null }

        </div>
      </div>
    </fieldset>
  )
}

export default CheckBox
