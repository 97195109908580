/**
 * Fonction permettant de réinitialiser les données de l'utilisateur ancienement connécté.
 */
import {REMOVE_USER_DATA} from './Type'

export const RemoveUserData = () => {

  const initial_data = {}

  return {
    type: REMOVE_USER_DATA,
    data: initial_data,
  }

}