/**
 * Rediucer redux - Liste des actions disponible pour cet étât.
*/
import { ADD_USER_DATA, REMOVE_USER_DATA } from './Type'

const initialState = {
  state:{}
}

const ReducerUserData = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_DATA:
      state = action.data
      return { state }

    case REMOVE_USER_DATA:
      state = action.data
      return { state }

    default: return state
  }
}

export default ReducerUserData ; 