import React, {Fragment} from 'react'
import { useTranslation } from 'react-i18next'
// Components
import Loader from '../elements/loader/PageContentLoader'
import ContentIsEmpty from "../elements/divContainer/ContentIsEmpty"
import TagGroups from './TagGroups'
import {RiEdit2Line, RiMailLine, RiMore2Line, RiQuestionLine, RiTimeLine} from 'react-icons/ri';
import {Menu, Transition} from '@headlessui/react';
import {Fade, Tooltip, tooltipClasses} from '@mui/material';
import moment from 'moment'
import {styled} from "@mui/material/styles";


const TableUsers = ({ users, openEditForm, dataLoaded, state, setState, roles = [], currentUser, openInvitationModal }) => {

  // Translations
  const { t } = useTranslation('common')
  const DATE_FORMAT = currentUser?.dateFormat ? currentUser.dateFormat : t('date.default_system_format', {ns:'common'})

  const handleChangeUser = (e) => {
    if(e.target.checked){
      setState([...state, e.target.value])
    } else {
      setState(state.filter((item) => item !== e.target.value))
    }
  }

  const handleSelectAll = (e) => {
    if(e.target.checked){
      const usersToSelect = users.filter((item) => !state.includes(item.id)).map((item) => item.id)

      setState([...state, ...usersToSelect])
    } else {
      const usersToUnselect = users.map((item) => item.id)
      const newUsersSelected = state.filter((item) => !usersToUnselect.includes(item))

      setState(newUsersSelected)
    }
  }

  const areAllChecked = () => {
    const usersSelected = users.filter((item) => state.includes(item.id)).map((item) => item.id)

    if( usersSelected.length === users.length ){
      return true
    }

    return false
  }

  const getRoleName = (role) => {
    const roleName = roles.filter((item) => item.id === role)

    if(roleName.length > 0 ){
      return roleName[0].name
    }

    return role
  }

  const getGroupsCol = (user) => {
    if( !Array.isArray(user?.groups) || user?.groups.length === 0) {
      return '-'
    }

    return (
      <TagGroups groups={user.groups} />
    )
  }

  const getActivationStatus = (user) => {
    if( user.firstConnection || user.activated ) {
      return (
        <Tooltip
          title={`${t('user.Activated_at', {ns: 'common', val: user.firstConnection ? moment(new Date(user.firstConnection)).format(DATE_FORMAT) : ''})}`}
          arrow={true}
          placement="right"
          disableInteractive={true}
          TransitionComponent={Fade}
          TransitionProps={{timeout: 500}}
        >
          <span className="cursor-pointer inline-flex gap-1 bg-green-100 border border-green-300 text-xs font-semibold text-green-600 inline rounded-full p-0.5 px-2">
            {t('user.Activated', {ns: 'common'})}
            <RiTimeLine className="h-4 w-4" />
          </span>
        </Tooltip>
      )
    } else if( user.invitations && Array.isArray(user.invitations) && user.invitations.length > 0 ) {
      const date = user.invitations[user.invitations.length - 1].sendOn

      return (
        <Tooltip
          title={`${t('user.Send-invitation-last-invitation', {ns: 'common', val: moment(new Date(date)).format(DATE_FORMAT)})}`}
          arrow={true}
          placement="right"
          disableInteractive={true}
          TransitionComponent={Fade}
          TransitionProps={{timeout: 500}}
        >
          <span className="cursor-pointer inline-flex gap-1 bg-yellow-100 border border-yellow-300 text-xs font-semibold text-yellow-700 inline rounded-full p-0.5 px-2">
            {t('user.Invited', {ns: 'common'})}
            <RiTimeLine className="h-4 w-4" />
          </span>
        </Tooltip>
      )
    }

    return '-'
  }

  const getMenu = (user) => {
    return (
        <Menu as="div" className="text-grayPrimary animate z-20">
          {({ open }) => (
              <>
                <div className={`flex pr-2 lg:opacity-${open ? '100':'0'} lg:group-hover:opacity-100`}>
                  <Menu.Button className="">
                    <RiMore2Line className="w-5 h-5 text-grayPrimary"/>
                  </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                      className={`z-10 absolute origin-top-right mt-1 absolute right-0 w-fit rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  >
                    <div className="py-1">
                      {currentUser?.authorizations?.administration?.users?.create && user.activated === false && (
                        <Menu.Item>
                          <button
                              onClick={() => openInvitationModal(user)}
                              className='text-gray-700 hover:text-memoryBluePrimary px-4 py-2 text-sm flex items-center'
                          >
                            <RiMailLine className="w-4 h-4 mr-1"/>
                            {t('action.Send-invitation-mail', {ns: 'common'})}
                          </button>
                        </Menu.Item>
                      )}
                      {currentUser?.authorizations?.administration?.users?.update && (
                        <Menu.Item>
                          <button
                              onClick={() => openEditForm(user)}
                              className='text-gray-700 hover:text-memoryBluePrimary px-4 py-2 text-sm flex items-center'
                          >
                            <RiEdit2Line className="w-4 h-4 mr-1"/>
                            {t('action.Modify', {ns: 'common'})}
                          </button>
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
          )}
        </Menu>
    )
  }

  const getStatusTooltip = () => {
    return (
      <div className="w-72 text-sm font-normal text-black p-4">
        <div className="w-full gap-y-2">
          <div className="flex mb-4 gap-2">
            <span className="inline-flex w-32">
              <span className="mx-auto text-center h-fit inline-flex items-center gap-1 bg-green-100 border border-green-300 text-green-600 inline rounded-full p-0.5 px-2">
                <span className="bg-green-600 rounded w-8 h-2"></span>
                <RiTimeLine className="h-4 w-4" />
              </span>
            </span>
            <span>
              {t('status.Status-tooltip-activated-text', {ns:'common'})}
            </span>
          </div>
          <div className="flex mb-4 gap-2">
            <span className="inline-flex w-32">
              <span className="mx-auto text-center h-fit inline-flex items-center gap-1 bg-yellow-100 border border-yellow-300 text-yellow-700 inline rounded-full p-0.5 px-2">
                <span className="bg-yellow-700 rounded w-8 h-2"></span>
                <RiTimeLine className="h-4 w-4" />
              </span>
            </span>
            <span>
              {t('status.Status-tooltip-invited-text', {ns:'common'})}
            </span>
          </div>
          <div className="flex mb-4 gap-2">
            <span className="inline-flex w-32 text-center">
              <span className="inline-block mx-auto">-</span>
            </span>
            <span>
              {t('status.Status-tooltip-not-invited-text', {ns:'common'})}
            </span>
          </div>
        </div>

        <div className="w-full">
          <span className="font-bold block">
            {t('Details_2', {ns:'common'})}
          </span>
          <span>
              {t('status.Status-tooltip-tooltip-text', {ns:'common'})}
          </span>
        </div>
      </div>
    )
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      maxWidth: 320,
      border: '1px solid #dadde9',
      padding: '0',
      boxShadow: theme.shadows[3],
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #dadde9"
      }
    },
  }))

  return (
    <div className="flex flex-col relative">
      {/* GRID HEAD */}
      <div className={`hidden min850:grid grid-cols-8users w-full text-sm font-bold bg-gray-200 sticky top-0`}>
        <span className="p-2">
          <input type={"checkbox"} value="all" onChange={handleSelectAll} checked={areAllChecked()} />
        </span>
        <span className="p-2 capitalize">{t('Last-name')}</span>
        <span className="p-2 capitalize">{t('First-name')}</span>
        <span className="p-2 capitalize">{t('email.Email')}</span>
        <span className="p-2 capitalize">{t('role.role')}</span>
        <span className="p-2 capitalize min850:inline-flex min850:justify-center">{t('group.Groups')}</span>
        <span className="p-2 capitalize min850:inline-flex min850:items-center min850:justify-center">
          {t('status.Status')}

          <HtmlTooltip
            title={getStatusTooltip()}
            className=""
            arrow={true}
            placement="right-start"
          >
            <div>
              <RiQuestionLine className="ml-2 h-5 w-5 cursor-pointer" />
            </div>
          </HtmlTooltip>
        </span>
        <span className="p-2"></span>
      </div>
      

      {/* GRID BODY */}
      <div className="flex flex-col">
        {dataLoaded ?

          users.length === 0 ?
            <ContentIsEmpty>
              {t('No-element')}
            </ContentIsEmpty>
          :
            users.map((user, index) => (
              <div
                key={`user-${index}`}
                className={`grid w-full group p-4 mb-4 text-sm rounded bg-gray-100 shadow-bottom grid-cols-1 
                min850:grid-cols-8users min850:border-b min850:bg-transparent min850:shadow-none min850:p-0 min850:mb-0 min850:rounded-none min850:hover:bg-gray-100`}
              >
                <span className="p-2 w-full dashboard__gridItem--beforeHide" title={''} data-label={''}>
                  <input type={"checkbox"} value={user.id} onChange={handleChangeUser} checked={state.includes(user.id) ? true : false} />
                </span>
                <span onClick={() => openEditForm(user)} className="dashboard__gridItem cursor-pointer" title={t('Last-name')} data-label={`${t('Last-name')} :`}>{user.last_name}</span>
                <span onClick={() => openEditForm(user)} className="dashboard__gridItem cursor-pointer" title={t('First-name')} data-label={`${t('First-name')} :`}>{user.first_name}</span>
                <span onClick={() => openEditForm(user)} className="dashboard__gridItem cursor-pointer" title={t('email.Email')} data-label={`${t('email.Email')} :`}>{user.email}</span>
                <span onClick={() => openEditForm(user)} className="dashboard__gridItem cursor-pointer" title={getRoleName(user.role)} data-label={`${t('role.Role')} :`}>{getRoleName(user.role)}</span>
                <span className="dashboard__gridItem min850:text-center" data-label={`${t('group.Groups')} :`}>{getGroupsCol(user)}</span>
                <span className="dashboard__gridItem dashboard__gridItem--fullText min850:text-center" data-label={`${t('status.Status')} :`}>{getActivationStatus(user)}</span>
                <span className="flex flex-row justify-end items-center">{getMenu(user)}</span>
              </div>
            ))
        :
          <Loader className="h-48" />
        }
      </div>
    </div>
  )
}

export default TableUsers