import React from "react";
import {RiCloseLine} from "react-icons/ri";

const ClosePanelButton = (props) => {
  const {
    children,
    close,
  } = props

  return (
    <button
      type="button"
      className={`rounded-md bg-purpulePrimary hover:bg-purpuleSecondary text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white`}
      onClick={() => close()}
    >
      <span className="sr-only">
        {children}
      </span>
      <RiCloseLine
        className="h-6 w-6"
        aria-hidden="true"
      />
    </button>
  )
}

export default ClosePanelButton