/**
 * Reducer redux - Liste des actions disponible pour cet étât.
 */
import { PLAY_NOTIFICATIONS, CLOSE_NOTIFICATIONS } from './Type'

const initialState = {
  state:{
    display: false,
    message: '',
    theme: ''
  }
}

const ReducerNotifications = (state = initialState, action) => {
  switch (action.type) {
    case PLAY_NOTIFICATIONS:
      state = action.data
      return { state }

    case CLOSE_NOTIFICATIONS:
      state = action.data
      return { state }

    default: return state
  }
}

export default ReducerNotifications ; 