import {styled} from "@mui/material/styles";
import {Tooltip, tooltipClasses} from "@mui/material";

const WhiteTooltip = styled(({ className, ...props }) => {
  const {maxWidth} = props;
  return <Tooltip {...props} classes={{popper: className}}/>;
})(({ theme, maxwidth, padding }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    maxWidth: maxwidth ? maxwidth : 300,
    padding: padding ? padding : 0,
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[3],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #dadde9",
    }
  },
}))

export default WhiteTooltip