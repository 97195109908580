const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/ //eslint-disable-line

const checkSpecialCaracter = (string) => {
  if(format.test(string)){
    return true
  } else {
    return false
  }
}

export default checkSpecialCaracter

