import React, { useState, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// Redux
import { useDispatch } from 'react-redux'
import { CloseNotifications } from '../../redux/notifications/CloseNotifications'
// Components
import { Transition } from '@headlessui/react'
import { RiAlertLine, RiCloseLine, RiErrorWarningLine, RiThumbUpLine } from 'react-icons/ri'


export default function Notifications(props) {

  // Translations
  const { t } = useTranslation('common')

  const {
    display,
    message,
    theme,
    delay = 3000
  
  } = props

  // Edit data redux
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  let textColor, bgColor, icone
  switch (theme) {
    case 'danger' : 
      textColor = "text-redPrimary"
      bgColor = "bg-redPrimary"
      icone = <RiErrorWarningLine className={`w-5 h-5 ${textColor}`} />
      break     
    case 'warning' : 
      textColor = "text-yellowPrimary"
      bgColor = "bg-yellowPrimary"
      icone = <RiAlertLine className={`w-5 h-5 ${textColor}`} />
      break
    case 'success' : 
      textColor = "text-greenPrimary"
      bgColor = "bg-greenPrimary"
      icone = <RiThumbUpLine className={`w-5 h-5 ${textColor}`} />
      break
    case 'info' : 
      textColor = "text-bluePrimary"
      bgColor = "bg-bluePrimary"
      icone = <RiErrorWarningLine className={`w-5 h-5 ${textColor}`} />
      break
    default : 
      textColor = ""
      bgColor = ""
      icone = <RiErrorWarningLine className={`w-5 h-5 ${textColor}`} />
  }

  useEffect(() => {
    if (display){
      setShow(true)
    }
    else {setShow(false)}

  }, [display])

  useEffect(() => {

    const displayComponent = () => {

      setTimeout(() => {
        dispatch( CloseNotifications() )
      }, 1000);
    }

    if (show){
      let timeout = setTimeout(() => {
        displayComponent()
      }, typeof delay === 'number' && delay > 0 ? delay : 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, setShow])

  const close = () => {
    dispatch( 
      dispatch( CloseNotifications() )
    )
  }

  return (
    <div className={`${!show ? 'hidden' : null}`}>
      <div
        aria-live="assertive"
        className="z-100 fixed bottom-0 w-full md:inset-0 md:mt-20 flex px-1 py-0 sm:mt-14 mb-5 md:mb-20 sm:p-6 pointer-events-none"
      >
        <div className="w-full flex flex-col items-center space-y-4 mt-5">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="-translate-y-2 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="relative sm:max-w-sm w-full bg-white shadow-xl rounded overflow-hidden border border-opacity-50 pointer-events-auto">
              <div className="p-3">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {icone}
                  </div>
                  <div className="ml-3 w-0 flex-1 mr-2">
                    <span className="mt-1 text-sm text-grayPrimary">{message}</span>
                  </div>
                  <button
                    className="p-2 text-grayPrimary animate absolute top-0 right-0"
                    onClick={() => {
                      close()
                    }}
                  >
                    <span className="sr-only">{t('action.Close')}</span>
                    <RiCloseLine className="w-3 h-3" />
                  </button>
                </div>
              </div>
              <div className={`w-full h-1 ${bgColor}`}></div>

            </div>
          </Transition>
        </div>
      </div>
    </div>
  )
}