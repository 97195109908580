import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import Router from './Router';
import history from './utils/history';

function App() {
  return (
    // <Provider store={store} history={history}>
    <Provider store={store} history={history}>
      <Router />
    </Provider>
  );
}

export default App;
