import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const usePagination = (totalCardPerPage) => {

  // Translations
  const { t } = useTranslation('common')

  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const [paginatedData, setPaginatedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const [tableMetrics, setTableMetrics] = useState('')


  const returnPrevPage = () => {
    setIsLoadingPage(true)
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const returnNextPage = () => {
    setIsLoadingPage(true)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const selectPage = (page) => {
    setIsLoadingPage(true)
    setCurrentPage(page)
  }

  const setDataPagination = (searchingList, filteredList = null, nbHits = null) => {
    setIsLoadingPage(true)
    
    const LENGTH = nbHits ? nbHits : filteredList.length /* IF NBHITS is not null => search with agolia */


    if(searchingList === null) {

      const newTotalPages = Math.ceil(LENGTH / totalCardPerPage)
      const nbOfCardsDisplay = currentPage === newTotalPages ? LENGTH : totalCardPerPage * currentPage

      setTotalPages(newTotalPages)

      if(LENGTH === 0) {
        setTableMetrics(`${t('No-element')}`)
      } else {
        setTableMetrics(`${((currentPage - 1) * totalCardPerPage) + 1} ${t('to', {ns:'common'})} ${nbOfCardsDisplay} ${t('of', {ns:'common'})} ${filteredList.length} ${t('elements', {ns:'common'})}`)
      }

      if (LENGTH > totalCardPerPage) {
        if( newTotalPages < currentPage ){
          setCurrentPage(newTotalPages)
        }
        
        if(nbHits === null) {
          const indexOfLastCard = currentPage * totalCardPerPage
          const indexOfFirstCard = indexOfLastCard - totalCardPerPage
          setPaginatedData(filteredList.slice(indexOfFirstCard, indexOfLastCard))
        } else {
          setPaginatedData(filteredList)
        }

        setIsLoadingPage(false)
      } else {
        if(currentPage){
          setCurrentPage(1)
        }
        setPaginatedData(filteredList)
        setIsLoadingPage(false)
      }
    } else {

      const newTotalPages = Math.ceil(searchingList.length / totalCardPerPage)
      const nbOfCardsDisplay = currentPage === newTotalPages ? searchingList.length : totalCardPerPage * currentPage

      setTotalPages(newTotalPages)

      if(searchingList.length === 0) {
        setTableMetrics(`${t('No-element')}`)
      } else {
        setTableMetrics(`${((currentPage - 1) * totalCardPerPage) + 1} ${t('to', {ns:'common'})} ${nbOfCardsDisplay} ${t('of', {ns:'common'})} ${searchingList.length} ${t('elements', {ns:'common'})}`)
      }

      if (searchingList.length > totalCardPerPage) {
        if( newTotalPages < currentPage ){
          setCurrentPage(newTotalPages)
        }
        const indexOfLastCard = currentPage * totalCardPerPage
        const indexOfFirstCard = indexOfLastCard - totalCardPerPage

        setPaginatedData(searchingList.slice(indexOfFirstCard, indexOfLastCard))
        setIsLoadingPage(false)
      } else {
        if(currentPage){
          setCurrentPage(1)
        }
        setPaginatedData(searchingList)
        setIsLoadingPage(false)
      }
    }
  }


  return  [
    currentPage,
    paginatedData, 
    totalPages, 
    isLoadingPage,
    tableMetrics,
    setCurrentPage,
    returnPrevPage,
    returnNextPage,
    selectPage,
    setDataPagination
  ]
}

export default usePagination
