/**
 * Fonction permettant d'ajouter les informations de l'utilisateur actuelement connécté.
 */
import {ADD_USER_DATA} from './Type'

export const AddUserData = (data_to_add) => {

  return {
    type: ADD_USER_DATA,
    data: data_to_add,
  }

}