import React, { useState, useEffect, useContext, useRef } from 'react'
import {Trans, useTranslation} from "react-i18next"
import { useHistory } from 'react-router-dom'
// Firebase
import { FirebaseContext } from "../../../../firebase/"
// Redux
import { useDispatch } from "react-redux"
import { PlayNotifications } from 'redux/notifications/PlayNotifications'
// Components
import LayoutDashboard from 'components/layout/LayoutDashboard'
import ContentIsEmpty from 'components/elements/divContainer/ContentIsEmpty'
import Headings from 'components/elements/Typography/Headings'
import Button from 'components/elements/button/Button'
import ButtonDropDown from "components/elements/button/ButtonDropDown"
import TableUsers from 'components/applicationUi/TableUsers'
import Pagination from 'components/elements/pagination/Pagination'
import Alert from 'components/modals/Alert'

import {RiDeleteBin5Line, RiAddLine, RiArrowDownSLine, RiUserLine, RiMailSendLine} from 'react-icons/ri'
// Utils
import { makeCaseAndAccentInsensitiveString } from 'utils/stringUtils'

import {USER, MAX_USER_SELECTION, MAX_PAGINATION_DASHBOARD} from 'utils/constantsBackOffice'
import { sortListDashboard } from 'utils/sortListDashboard'
// Hooks
import useSearchItemsProcess from 'hooks/useSearchItemsProcess'
import usePagination from 'hooks/usePagination'
// Others
import 'assets/style/dashboard.css'
import Loader from "components/elements/loader/PageContentLoader";
import {Fade, Tooltip} from "@mui/material";
import {SUB_NAV_PROJECTS} from "utils/dataNavigation";


const DashboardUsers = ({selectedMenu, currentUser}) => {
  // Translations
  const { t } = useTranslation(['common', 'dashboard', 'messages'])
  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const history = useHistory()

  const refInputName = useRef()

  const [ 
    searchingList,
    setSearchingList, 
    makeItemSearch
  ] = useSearchItemsProcess()

  const [
    currentPage,
    paginatedData,
    totalPages,
    isLoadingPage,
    tableMetrics,
    ,
    returnPrevPage,
    returnNextPage,
    selectPage,
    setDataPagination
  ] = usePagination(MAX_PAGINATION_DASHBOARD)
  
  const [dataUsers, setDataUsers] = useState([])

  const [dataLoaded, setDataLoaded] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertSelectLength, setAlertSelectLength] = useState(null)
  const [displayUserForm, setDisplayUserForm] = useState(false)
  const [displayImportForm, setDisplayImportForm] = useState(false)
  const [displayUserInvitation, setDisplayUserInvitation] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [filteredList, setFilteredList] = useState([])

  const [selectedUser, setSelectedUser] = useState(undefined)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [tableSelectedMetrics, setTableSelectedMetrics] = useState('')

  const [alertInviteAllUsers, setAlertInviteAllUsers] = useState(false)
  const [inviteAllUsersLoading, setInviteAllUsersLoading] = useState(false)
  const [inviteAllUsersCount, setInviteAllUsersCount] = useState(0)

  const [roles, setRoles] = useState([])
  const [roleFilter, setRoleFilter] = useState(null)
  const [rolesForFilter, setRolesForFilter] = useState([])
  const [statusFilter, setStatusFilter] = useState(null)
  const statusForFilter = [
    {

      id: 'all',
      name: t('status.All-status', {ns:'common'}),
      action: () => setStatusFilter('all')
    },
    {
      id: 'activated',
      name: t('user.Activated', {ns: 'common'}),
      action: () => setStatusFilter('activated')
    },
    {
      id: 'invited',
      name: t('user.Invited', {ns: 'common'}),
      action: () => setStatusFilter('invited')
    },
    {
      id: 'not-invited',
      name: t('user.Not-invited', {ns: 'common'}),
      action: () => setStatusFilter('not-invited')
    },
  ]

  const getFilteredList = sortListDashboard(dataUsers, USER)


  const getUsers = () => {

    if (currentUser) {
      
      const collectionRef = firebase.db
        .collection("users")

      let query = roleFilter && roleFilter !== "_all" ? collectionRef.where("role", "==", roleFilter) : collectionRef

      if( statusFilter && statusFilter !== "all" ){
        switch ( statusFilter ){
          case "activated":
            query = query.where("activated", "==", true)
            break
          case "invited":
            query = query.where("activated", "==", false).where("invited", "==", true)
            break
          case "not-invited":
            query = query.where("activated", "==", false).where("invited", "==", false)
            break
          default:
            break
        }
      }

      query.get()
        .then((snapshot) => {
          const users = snapshot.docs.map((doc) => doc.data())
          setDataUsers(users)
          setDataLoaded(true)
        })
    }
  }

  const getRoles = () => {
    firebase
      .db
      .collection('roles')
      .onSnapshot(snapshot => {
        const roles_ = snapshot.docs.map(doc => doc.data())
        let data = sortListDashboard(roles_)

        setRoles(data)

        let dataForFilter = [
          {
            id: '_all',
            name: t('role.All-roles', {ns:'common'}),
            action: () => setRoleFilter('_all')
          }
        ]

        dataForFilter = dataForFilter.concat(
          data.map((item) => ( 
            {
              id: item.id, 
              name: item.name, 
              action: () => setRoleFilter(item.id) 
            } 
          ))
        )

        setRolesForFilter(dataForFilter)
        setRoleFilter(dataForFilter[0].id)
      })
  }

  const getRoleName = (role) => {
    const roleName = rolesForFilter.filter((item) => item.id === role)
    return roleName.length > 0 ? roleName[0].name : role
  }

  const getStatusName = (status) => {
    const statusName = statusForFilter.filter((item) => item.id === status)
    return statusName.length > 0 ? statusName[0].name : status
  }

  const clearSearch = () => {
    setSearchValue('')
    makeItemSearch('', filteredList, USER)
  }

  const searchUsers = (e) => {
    let normalizedValue = makeCaseAndAccentInsensitiveString(e.target.value)
    let data = filteredList
    
    setSearchValue(normalizedValue)
    makeItemSearch(normalizedValue, data, USER)
  }

  const deleteUsers = () => {
    setDeleteLoading(true)

    const deleteUsers = firebase.functions.httpsCallable("deleteUsers")
    deleteUsers({users : selectedUsers})
      .then((result) => {
        getUsers()
        setAlert(false)
        setDeleteLoading(false)
        setSelectedUsers([])

        if(result.data?.success === true){
          if(result.data?.countFailed > 0 ){
            dispatch(
              PlayNotifications({
                display: true,
                theme: "warning",
                message: t('alert.removed_uncompleted', {ns: 'messages', val: t('member.Members', {ns: 'common'})}),
              })
            )
          } else {
            dispatch(
              PlayNotifications({
                display: true,
                theme: "success",
                message: t('success.removed_withVal', {ns: 'messages', val: t('member.Members', {ns: 'common'})}),
              })
            )
          }
        } else {
          dispatch(
            PlayNotifications({
              display: true,
              theme: "danger",
              message: t('error.removed-error_withVal_plural', {ns: 'messages', val: (t('member.Members', {ns: 'common'}).toLowerCase())}),
            })
          )
        }

      }).catch((error) => {
        getUsers()
        setAlert(false)
        setDeleteLoading(false)
        console.error(error)
        dispatch(
          PlayNotifications({
            display: true,
            theme: "danger",
            message: t('error.removed-error_withVal_plural', {ns: 'messages', val: (t('member.Members', {ns: 'common'}).toLowerCase())}),
          })
        )
      })
  }

  const inviteAllUsers = (relaunch = false, cursor = '') => {
    setInviteAllUsersLoading(true)
    if( !relaunch ){
      setInviteAllUsersCount(0)
    } else {
      console.log('relaunch from ::', cursor)
    }

    const inviteAllUsersFunction = firebase.functions.httpsCallable("inviteAllUsers", {timeout: 540000})
    inviteAllUsersFunction({cursor : cursor ? cursor : ''})
      .then((result) => {
        if( result.data?.finished === true ){
          setAlertInviteAllUsers(false)
          setInviteAllUsersLoading(false)
          setInviteAllUsersCount(0)

          if(result.data?.success === true){
            dispatch(
              PlayNotifications({
                display: true,
                theme: "success",
                message: t('success.users-invited', {ns: 'messages'}),
              })
            )
          } else {
            dispatch(
              PlayNotifications({
                display: true,
                theme: "danger",
                message: t('error.something-wrong-try-again', {ns: 'messages'}),
              })
            )
          }

          // getImportedContents();
        } else {
          if( result.data?.errorCount !== result.data?.totalCount && typeof result.data?.cursor === 'string'){
            setInviteAllUsersCount(prevState => { return prevState + Number(result.data?.totalCount)})
            inviteAllUsers(true, result.data?.cursor);
          } else {
            dispatch(
              PlayNotifications({
                display: true,
                theme: "danger",
                message: t('error.something-wrong-try-again', {ns: 'messages'}),
              })
            )

            setAlertInviteAllUsers(false)
            setInviteAllUsersLoading(false)
            setInviteAllUsersCount(0)
          }
        }
      }).catch((error) => {
      setAlertInviteAllUsers(false)
      setInviteAllUsersLoading(false)
      setInviteAllUsersCount(0)
      console.error(error)
      dispatch(
        PlayNotifications({
          display: true,
          theme: "danger",
          message: t('error.something-wrong-try-again', {ns: 'messages'}),
        })
      )
    });
  }

  const openEditForm = (user) => {
    setSelectedUser(user)
    setDisplayUserForm(true)
  }

  const closeForm = (refresh = false) => {
    setSelectedUser(undefined)
    setDisplayUserForm(false)
    setDisplayUserInvitation(false)

    refresh && getUsers()
  }

  const openInvitationModal = (user) => {
    setSelectedUser(user)
    setDisplayUserInvitation(true)
  }

  /* Get datas */
  useEffect(() => {
    let rolesSnapUnsubscribe
    
    setDataLoaded(false)
    setSearchingList(null)
    setSearchValue('')
    setSelectedUsers([])

    if( currentUser ){
      rolesSnapUnsubscribe = getRoles()
      roleFilter && getUsers()
    } else {
      history.push('/dashboard/members/members')
    }

    return () => {
      rolesSnapUnsubscribe && rolesSnapUnsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  /* Update datas */
  useEffect(() => {
      let data = getFilteredList

      setFilteredList(data)

      if (searchingList !== null) {
        makeItemSearch(searchValue, data, USER)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUsers])

  /* Get datas with role filter */
  useEffect(() => {
    if( roleFilter ){
      getUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleFilter])

  /* Get datas with status filter */
  useEffect(() => {
    if( statusFilter ){
      getUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter])

  /* Update metrics after select users */
  useEffect(() => {
    if( selectedUsers.length > MAX_USER_SELECTION ){
      setSelectedUsers(selectedUsers.slice(0,(MAX_USER_SELECTION)))
      setAlertSelectLength(true)
    }

    if( selectedUsers.length > 0 ){
      setTableSelectedMetrics(`${selectedUsers.length} ${selectedUsers.length > 1 ? t('selected_plural', {ns:'common'}) : t('selected', {ns:'common'}) }`)
    } else {
      setTableSelectedMetrics('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  /* Pagination */
  useEffect(() => {
    setDataPagination(searchingList, filteredList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList, searchingList, currentPage])

  /* Autofocus input modal */
  useEffect(() => {
    displayUserForm === true ? refInputName?.current?.focus() : refInputName?.current?.blur()
  }, [displayUserForm])

  useEffect(() => {
    document.title = `Memory - ${t('users.Users-management', {ns: 'dashboard'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutDashboard
        currentUser={currentUser}
        headerNav={SUB_NAV_PROJECTS}
        // headerNavSelected={MEMBERS_MENU}
        selectedMenu={selectedMenu}
        // subNav={ {type: NAV_LINK_ROUTER, items: SUB_NAV_MEMBERS} }
        title={t('users.Users-management', {ns: 'dashboard'})}
        description={t('users.members-global-text', {ns:'dashboard'})}
        noticeLink={{
          href: "https://beecomeio.notion.site/Configuration-de-votre-espace-adf43d3fcf564cd0a1d5a25ec3bb1508#9d72072d9c3647738c95962b549b5f53",
          label: t('users.global-help', {ns:'dashboard'})
        }}
        enableScroll={true}
      >
        <header className="mb-2 sm:mb-8 pt-4">
          <ul className="flex justify-between items-center gap-2 mb-2">
            <li className="flex items-center gap-2">
              <RiUserLine className="w-5 h-5 min-w-5" />
              <Headings variant="h2" display="display-4">{t('members.Members', {ns:'dashboard'})}</Headings>
            </li>
            <li className="flex gap-4">
              {(currentUser?.is_administrator === true ||
                currentUser?.authorizations?.administration?.users?.delete === true) &&
                <Button
                  className="h-max justify-center sm:justify-items-start sm:self-center"
                  size="small"
                  icon={<RiDeleteBin5Line className="w-4 h-4"/>}
                  iconPosition="right"
                  actionType={"action"}
                  action={() => setAlert(true)}
                  theme={selectedUsers.length > 0 ? 'secondary' : 'disabled'}
                  animate={false}
                />
              }

              {(currentUser?.is_administrator === true || currentUser?.authorizations?.administration?.users?.create === true) &&
                <Tooltip
                  title={t('users.Invite-all-users', {ns: 'dashboard'})}
                  arrow={true}
                  placement="bottom"
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 500}}
                >
                  <span>
                    <Button
                      className="h-max justify-center sm:justify-items-start sm:self-center"
                      size="small"
                      icon={<RiMailSendLine className="w-4 h-4"/>}
                      iconPosition="right"
                      actionType={"action"}
                      action={() => setAlertInviteAllUsers(true)}
                      theme="secondary"
                      animate={false}
                    />
                  </span>
                </Tooltip>
              }

              {(currentUser?.is_administrator === true ||
                currentUser?.authorizations?.administration?.users?.create === true ||
                currentUser?.authorizations?.administration?.users?.import === true) &&
                <ButtonDropDown
                  className="flex items-center"
                  buttonClassName={'h-max flex sm:justify-items-start sm:self-center items-center group font-bold min-w-max bg-purpulePrimary hover:bg-purpuleSecondary border-t border-l border-r border-purpulePrimary hover:border-purpulePrimary text-white text-xs py-1.5 px-4 rounded shadowMainButton'}
                  buttonShadow={true}
                  dropDownClassName="top-full"
                  position="sm:right-0"
                  type="action"
                  theme="color"
                  data={[
                    {
                      id: 1,
                      name: `${t('action.Create', {ns: 'common'})} ${t('user.user_account_one', {ns:'common'})}`,
                      action: () => setDisplayUserForm(true),
                    },
                    {
                      id: 2,
                      name: `${t('action.Import_withVal', {ns: 'common', val: t('user.users_plural', {ns:'common'})})}`,
                      action: () => setDisplayImportForm(true),
                    }
                  ]}
                >
                  <div className="flex items-center">
                    <RiAddLine className="w-4 h-4"/>
                  </div>
                </ButtonDropDown>
              }
            </li>
          </ul>
          <article className="flex flex-col text-sm max-h-10 overflow-auto memoryScrollbar lg:max-h-full lg:overflow-hidden">
            <p>{t('users.infos-members', {ns:'dashboard'})}</p>
            <p>{t('users.infos-members-tip', {ns:'dashboard'})}</p>
          </article>
        </header>

        {/* SEARCH */}
        <>
          {(currentUser?.is_administrator === true ||
            currentUser?.authorizations?.administration?.users?.list === true) &&
            <span className="flex flex-col gap-2 w-full mb-2 sm:flex-row">
              {/*<InputSearch*/}
              {/*  labelHidden*/}
              {/*  name="search-user"*/}
              {/*  loading={!dataLoaded}*/}
              {/*  placeHolder={t('action.Search-in-list')}*/}
              {/*  className="inline-flex"*/}
              {/*  setstate={(e) => searchUsers(e)}*/}
              {/*  state={searchValue}*/}
              {/*  clear={true}*/}
              {/*  setClear={() => clearSearch()}*/}
              {/*/>*/}

              <ButtonDropDown
                className="flex items-center"
                buttonClassName="block w-full text-sm border-gray-300 rounded border px-3 sm:ml-2 md:text-base"
                dropDownClassName="top-full max-h-200px overflow-auto memoryScrollbar"
                type="action"
                data={rolesForFilter}
              >
                <div className="flex items-center justify-between w-full h-8">
                  <p>
                    {roleFilter
                      ? getRoleName(roleFilter)
                      : `${t('action.Select', {ns: 'common'})} ${t('role.role_one', {ns: 'common'})}`
                    }
                  </p>
                  <RiArrowDownSLine className="w-4 h-4 ml-10 text-gray-500"/>
                </div>
              </ButtonDropDown>

              <ButtonDropDown
                className="flex items-center"
                buttonClassName="block w-full text-sm border-gray-300 rounded border px-3 sm:ml-2 md:text-base"
                dropDownClassName="top-full max-h-200px overflow-auto memoryScrollbar"
                type="action"
                data={statusForFilter}
              >
                <div className="flex items-center justify-between w-full h-8">
                  <p>
                    {statusFilter
                      ? getStatusName(statusFilter)
                      : `${t('action.Select', {ns: 'common'})} ${t('status.status_one', {ns: 'common'})}`
                    }
                  </p>
                  <RiArrowDownSLine className="w-4 h-4 ml-10 text-gray-500"/>
                </div>
              </ButtonDropDown>
            </span>
          }
        </>

        {/* TABLE */}
        {(currentUser?.is_administrator === true ||
          currentUser?.authorizations?.administration?.users?.list === true) ?

          (dataLoaded && dataUsers?.length === 0) ?
            <ContentIsEmpty>
              {t('users.empty-users', {ns:'dashboard'})}
            </ContentIsEmpty>
          :
            <>
              {/*<TableMetrics tableMetrics={tableMetrics} tableSelectedMetrics={tableSelectedMetrics} />*/}
              <div className={`flex items-center text-xs mb-2`}>
                <span className="">
                  {tableMetrics}
                  {tableSelectedMetrics?.length > 0 ?
                    <span> (<strong>{tableSelectedMetrics}</strong>)</span>
                    :
                    null
                  }
                </span>
              </div>

              <TableUsers
                users={paginatedData}
                openEditForm={openEditForm}
                openInvitationModal={openInvitationModal}
                dataLoaded={dataLoaded}
                setState={setSelectedUsers}
                state={selectedUsers}
                roles={roles}
                currentUser={currentUser}
              />

              {dataLoaded &&
                <div className="flex justify-center mt-3">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onClickPage={selectPage}
                    onClickPrevious={returnPrevPage}
                    onClickNext={returnNextPage}
                    isloading_card={isLoadingPage}
                  />
                </div>
              }
            </>
          :
          <ContentIsEmpty>
            {t('alert.access-denied-to-this-page', {ns: 'messages'})}
          </ContentIsEmpty>
        }
      </LayoutDashboard>

      {/* ALERTS AND MODALS */}
      {alert &&
        <Alert
          title={`${t('action.Delete', {ns: 'common'})} ${t('user.users_plural', {ns: 'common'})}`}
          state={alert}
          setstate={setAlert}
          actionPrimary={deleteUsers}
          btnPrimary={t('action.Confirm', {ns: 'common'})}
          btnSecondary={t('action.Back', {ns: 'common'})}
          isLoadingModal={deleteLoading}
        >
          <p>{t('alert.delete-permanently_withVal', {ns: 'messages', val: t('user.users_plural', {ns: 'common'})})} <span className="text-gray-600 font-semibold">{selectedUsers.length} {selectedUsers.length > 1 ? (t('user.users', {ns: 'common'}).toLowerCase()) : (t('user.user', {ns: 'common'}).toLowerCase())}</span>.</p>
          <p>{t('alert.what-do-you-want-to-do', {ns: 'messages'})}</p>
        </Alert>
      }

      {alertInviteAllUsers &&
        <Alert
          title={t('users.Invite-all-users', {ns: 'dashboard'})}
          state={alertInviteAllUsers}
          setstate={setAlertInviteAllUsers}
          actionPrimary={inviteAllUsers}
          btnPrimary={t('action.Confirm', {ns: 'common'})}
          btnSecondary={t('action.Back', {ns: 'common'})}
          isLoadingModal={inviteAllUsersLoading}
        >
          {inviteAllUsersLoading ?
            <>
              <p className="text-center text-memoryBluePrimary font-semibold mt-4 text-sm whitespace-pre-line bg-gray-100 rounded py-2 px-4">
                <span className={`block text-base mb-2`}>{t('alert.dont-leave-page_title', {ns: 'messages'})}</span>
                <span>{t('alert.dont-leave-page_default', {ns: 'messages'})}</span>
              </p>
              <div>
                <Loader className="h-8 mt-4 mb-4" />
              </div>
              <p className="text-center">
                <Trans
                  i18nKey="users.members-invited_withVal"
                  components={{ bold: <strong className="text-memoryBluePrimary" />}}
                  ns="dashboard"
                  values={{val: inviteAllUsersCount}}
                />
              </p>
            </>
          :
            <>
              <p>
                <Trans
                  i18nKey="users.Invite-all-users_info"
                  components={{ bold: <strong className="text-memoryBluePrimary" />}}
                  ns="dashboard"
                  values={{val: inviteAllUsersCount}}
                />.
              </p>
              <p>{t('alert.what-do-you-want-to-do', {ns: 'messages'})}</p>
            </>
          }
        </Alert>
      }

      {alertSelectLength &&
        <Alert
          title={`${t('Information', {ns: 'common'})}`}
          state={alertSelectLength}
          setstate={setAlertSelectLength}
          actionPrimary={setAlertSelectLength}
          btnPrimary={t('action.Close', {ns: 'common'})}
          isLoadingModal={deleteLoading}
        >
          <p>{t('alert.select-users-limit', {ns: 'messages', val: MAX_USER_SELECTION})}</p>
        </Alert>
      }

      {/*{displayUserForm &&*/}
      {/*  <UserForm*/}
      {/*    closeForm={(refresh) => closeForm(refresh)}*/}
      {/*    user={selectedUser}*/}
      {/*    refInput={refInputName}*/}
      {/*    roles={roles}*/}
      {/*  />*/}
      {/*}*/}

      {/*{displayUserInvitation &&*/}
      {/*  <UserSendInvitation*/}
      {/*    closeForm={(refresh) => closeForm(refresh)}*/}
      {/*    user={selectedUser}*/}
      {/*  />*/}
      {/*}*/}

      {/*{displayImportForm && */}
      {/*  <UserImportForm*/}
      {/*    closeForm={() => setDisplayImportForm(false)}*/}
      {/*    userType={USER}*/}
      {/*    roles={roles}*/}
      {/*  />*/}
      {/*}*/}
    </>
  )
}

export default DashboardUsers
