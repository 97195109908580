import {
  RiApps2Line,
  RiArticleLine, RiBuildingLine, RiCodeBoxLine, RiInfinityLine,
  RiPencilRulerLine,
  RiSettings4Line,
  RiUserSettingsLine
} from "react-icons/ri";

export const baseRole = {
  name: {
    key : 'name',
    type : 'field',
    input : 'text',
    label: 'name',
    required: true,
    fieldset : false
  },
  all_rights: {
    key : 'all_rights',
    type : 'field',
    input : 'checkbox',
    label: 'all_rights',
    fieldset: false
  },
  main: {
    type: 'main_fieldset_group',
    fields: {
      app: {
        type: 'fieldset_group',
        label: 'app',
        key: 'app',
        fields: {
          app_models_section: {
            type: 'section',
            icon: <RiArticleLine className="w-4 h-4" />,
            key: 'app.models',
            anchor: 'models-and-contents',
            fields: {
              models:{
                type: 'fieldset',
                fields: {
                  create_single_content: {
                    key : 'app.models.create_single_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create_single_content'
                  },
                  create_parent_content: {
                    key : 'app.models.create_parent_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create_parent_content'
                  },
                  create_child_content: {
                    key : 'app.models.create_child_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create_child_content'
                  },
                  update_all_single_content: {
                    key : 'app.models.update_all_single_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_all_single_content'
                  },
                  delete_all_single_content: {
                    key : 'app.models.delete_all_single_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_all_single_content'
                  },
                  update_all_parent_content: {
                    key : 'app.models.update_all_parent_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_all_parent_content'
                  },
                  delete_all_parent_content: {
                    key : 'app.models.delete_all_parent_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_all_parent_content'
                  },
                  update_all_child_content: {
                    key : 'app.models.update_all_child_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_all_child_content'
                  },
                  delete_all_child_content: {
                    key : 'app.models.delete_all_child_content',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_all_child_content'
                  },
                  edit_publication_date: {
                    key : 'app.models.edit_publication_date',
                    type : 'field',
                    input : 'checkbox',
                    label: 'edit_publication_date',
                    option_dependency: 'content.edit_publication_date'
                  },
                  read_metadata: {
                    key : 'app.models.read_metadata',
                    type : 'field',
                    input : 'checkbox',
                    label: 'read_metadata',
                    option_dependency: 'content.metadata'
                  },
                  edit_metadata: {
                    key : 'app.models.edit_metadata',
                    type : 'field',
                    input : 'checkbox',
                    label: 'edit_metadata',
                    option_dependency: 'content.metadata'
                  },
                  as_parent_content_owner: {
                    key : 'as-parent-content-owner',
                    type : 'title'
                  },
                  update_child_content_as_parent_creator: {
                    key : 'app.models.update_child_content_as_parent_creator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_child_content_as_parent_creator'
                  },
                  delete_child_content_as_parent_creator: {
                    key : 'app.models.delete_child_content_as_parent_creator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_child_content_as_parent_creator'
                  },
                  as_parent_content_collaborator: {
                    key : 'as-parent-content-collaborator',
                    type : 'title'
                  },
                  update_parent_content_as_collaborator: {
                    key : 'app.models.update_parent_content_as_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_parent_content_as_collaborator'
                  },
                  delete_parent_content_as_collaborator: {
                    key : 'app.models.delete_parent_content_as_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_parent_content_as_collaborator'
                  },
                  update_child_content_as_parent_collaborator: {
                    key : 'app.models.update_child_content_as_parent_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_child_content_as_parent_collaborator'
                  },
                  delete_child_content_as_parent_collaborator: {
                    key : 'app.models.delete_child_content_as_parent_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_child_content_as_parent_collaborator'
                  },
                  as_child_content_collaborator: {
                    key : 'as-child-content-collaborator',
                    type : 'title'
                  },
                  update_child_content_as_collaborator: {
                    key : 'app.models.update_child_content_as_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_child_content_as_collaborator'
                  },
                  delete_child_content_as_collaborator: {
                    key : 'app.models.delete_child_content_as_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_child_content_as_collaborator'
                  },
                  as_single_content_collaborator: {
                    key : 'as-single-content-collaborator',
                    type : 'title'
                  },
                  update_single_content_as_collaborator: {
                    key : 'app.models.update_single_content_as_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_single_content_as_collaborator'
                  },
                  delete_single_content_as_collaborator: {
                    key : 'app.models.delete_single_content_as_collaborator',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_single_content_as_collaborator'
                  },
                  app_model_help: {
                    type : 'help'
                  }
                }
              }
            }
          },
          app_structures_section: {
            type: 'section',
            icon: <RiBuildingLine className="w-4 h-4" />,
            key: 'app.structures',
            anchor: 'app-structures',
            fields: {
              structure: {
                type: 'fieldset',
                fields: {
                  create_single_content: {
                    key: 'app.structures.update_all_structure_profile',
                    type: 'field',
                    input: 'checkbox',
                    label: 'update_all_structure'
                  }
                }
              }
            }
          }
        }
      },
      administration: {
        type: 'fieldset_group',
        key: 'administration',
        fields: {
          access : {
            key : 'administration.access',
            type : 'field',
            input : 'checkbox',
            label: 'access'
          },
          members: {
            type: 'section',
            icon: <RiUserSettingsLine className="w-4 h-4" />,
            key: 'administration.members_section',
            anchor: 'members',
            fields: {
              users: {
                type: 'fieldset',
                key: 'administration.members',
                fields: {
                  list: {
                    key : 'administration.users.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.users.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.users.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.users.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  },
                  import: {
                    key : 'administration.users.import',
                    type : 'field',
                    input : 'checkbox',
                    label: 'import'
                  },
                  // promote_admin: {
                  //   key : 'administration.users.promote_admin',
                  //   type : 'field',
                  //   input : 'checkbox',
                  //   label: 'promote_admin'
                  // }
                }
              },
              groups: {
                type: 'fieldset',
                key: 'administration.groups',
                fields: {
                  list_group: {
                    key : 'administration.users.list_group',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list_group'
                  },
                  create_group: {
                    key : 'administration.users.create_group',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create_group'
                  },
                  update_group: {
                    key : 'administration.users.update_group',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update_group'
                  },
                  delete_group: {
                    key : 'administration.users.delete_group',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete_group'
                  }
                }
              }
            }
          },
          settings: {
            type: 'section',
            icon: <RiSettings4Line className="w-4 h-4" />,
            key: 'administration.settings_section',
            anchor: 'settings',
            fields: {
              platform_settings: {
                type: 'fieldset',
                key: 'administration.platform_settings',
                fields: {
                  manage: {
                    key : 'administration.platform_settings.manage',
                    type : 'field',
                    input : 'checkbox',
                    label: 'manage'
                  }
                }
              },
              roles: {
                type: 'fieldset',
                label: 'administration.roles',
                key: 'administration.roles',
                fields: {
                  list: {
                    key : 'administration.roles.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.roles.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.roles.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.roles.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              }
            }
          },
          customization: {
            type: 'section',
            icon: <RiPencilRulerLine className="w-4 h-4" />,
            key: 'administration.customization_section',
            anchor: 'customization',
            fields: {
              platform_custom: {
                type: 'fieldset',
                label: 'administration.platform_custom',
                key: 'administration.platform_custom',
                fields: {
                  manage: {
                    key : 'administration.platform_custom.manage',
                    type : 'field',
                    input : 'checkbox',
                    label: 'manage'
                  }
                }
              },
              pages: {
                type: 'fieldset',
                key: 'administration.pages',
                optional: true,
                fields: {
                  list: {
                    key : 'administration.pages.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list',
                    optional: true,
                  },
                  create: {
                    key : 'administration.pages.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create',
                    optional: true,
                  },
                  update: {
                    key : 'administration.pages.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update',
                    optional: true,
                  },
                  delete: {
                    key : 'administration.pages.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete',
                    optional: true,
                  }
                }
              },
            }
          },
          elements: {
            type: 'section',
            icon: <RiApps2Line className="w-4 h-4" />,
            key: 'administration.elements_section',
            anchor: 'elements',
            fields: {
              attributes: {
                type: 'fieldset',
                label: 'administration.attributes',
                key: 'administration.attributes',
                fields: {
                  list: {
                    key : 'administration.attributes.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.attributes.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.attributes.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.attributes.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              },
              models: {
                type: 'fieldset',
                label: 'administration.models',
                key: 'administration.models',
                fields: {
                  list: {
                    key : 'administration.models.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.models.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.models.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.models.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              },
              playlists: {
                type: 'fieldset',
                label: 'administration.playlists',
                key: 'administration.playlists',
                fields: {
                  list: {
                    key : 'administration.playlists.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.playlists.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.playlists.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.playlists.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              },
              structures: {
                type: 'fieldset',
                label: 'administration.structures',
                key: 'administration.structures',
                fields: {
                  list: {
                    key : 'administration.structures.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.structures.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.structures.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.structures.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              },
              segments: {
                type: 'fieldset',
                label: 'administration.segments',
                key: 'administration.segments',
                fields: {
                  list: {
                    key : 'administration.segments.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.segments.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.segments.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.segments.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              },
            }
          },
          imports: {
            type: 'section',
            icon: <RiInfinityLine className="w-4 h-4" />,
            label: 'administration.imports_section',
            key: 'administration.imports-n-batch-action',
            anchor: 'imports',
            fields: {
              imports: {
                type: 'fieldset',
                label: 'administration.imports',
                key: 'administration.imports',
                fields: {
                  list: {
                    key : 'administration.imports.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create_user_import: {
                    key : 'administration.imports.create_user_import',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  create_attribute_import: {
                    key : 'administration.imports.create_attribute_import',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create',
                    optional: true,
                  },
                  create_content_import: {
                    key : 'administration.imports.create_content_import',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create',
                    optional: true,
                  },
                  create_structure_import: {
                    key : 'administration.imports.create_structure_import',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create',
                    optional: true,
                  }
                }
              },
              imported_contents: {
                type: 'fieldset',
                optional: true,
                label: 'administration.imported_contents',
                key: 'administration.imported_contents',
                fields: {
                  list: {
                    key : 'administration.imported_contents.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  publish: {
                    key : 'administration.imported_contents.publish',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.imported_contents.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.imported_contents.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              },
              contents: {
                type: 'fieldset',
                label: 'administration.contents',
                key: 'administration.contents',
                fields: {
                  batch_delete: {
                    key : 'administration.contents.batch_delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              }
            }
          },
          api: {
            type: 'section',
            icon: <RiCodeBoxLine className="w-4 h-4" />,
            label: 'administration.api_section',
            key: 'administration.api',
            anchor: 'api',
            fields: {
              services: {
                type: 'fieldset',
                label: 'administration.services',
                key: 'administration.services',
                optional: true,
                fields: {
                  enable: {
                    key : 'administration.services.enable',
                    type : 'field',
                    input : 'checkbox',
                    label: 'enable'
                  }
                }
              },
              api_settings: {
                type: 'fieldset',
                label: 'administration.api_keys',
                key: 'administration.api_keys',
                optional: true,
                fields: {
                  list: {
                    key : 'administration.api_keys.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.api_keys.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.api_keys.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.api_keys.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              },
              webhooks: {
                type: 'fieldset',
                label: 'administration.webhooks',
                last: true,
                key: 'administration.webhooks',
                optional: true,
                fields: {
                  list: {
                    key : 'administration.webhooks.list',
                    type : 'field',
                    input : 'checkbox',
                    label: 'list'
                  },
                  create: {
                    key : 'administration.webhooks.create',
                    type : 'field',
                    input : 'checkbox',
                    label: 'create'
                  },
                  update: {
                    key : 'administration.webhooks.update',
                    type : 'field',
                    input : 'checkbox',
                    label: 'update'
                  },
                  delete: {
                    key : 'administration.webhooks.delete',
                    type : 'field',
                    input : 'checkbox',
                    label: 'delete'
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}