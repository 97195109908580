import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
// Firebase 
import { FirebaseContext } from '../../firebase'
// Redux
import { useDispatch } from 'react-redux'
import { PlayNotifications } from '../../redux/notifications/PlayNotifications'
// Components
import Button from '../../components/elements/button/Button'
// import Navigation from '../../components/marketing/navigation/NavBarAuthentication'
import InputPassword from '../../components/forms/password/InputPassword'
import Logo from '../../assets/images/app/svg/Logo.svg'
import { BarLoader } from "react-spinners"
import { 
  RiCheckLine,
  RiLockUnlockLine,
  RiTimerLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine, 
  
} from 'react-icons/ri'
// Utils
import history from '../../utils/history'
import checkSpecialCaracter from '../../utils/checkSpecialCharactersInString'


const ChangePasswordProcedure = ({entity}) => {

  // Translations
  const { t } = useTranslation(['common', 'messages'])

  const dispatch = useDispatch()
  const firebase = useContext(FirebaseContext)

  const [redirectAction, setRedirectAction] = useState(false)
  const [ready, setready] = useState(false)
  const [code, setcode] = useState("")
  const [newPassword, setnewPassword] = useState("")
  const [confirmPassword, setconfirmPassword] = useState("")
  const [loading, setloading] = useState(false)

  useEffect(() => {
    document.title = `${entity && entity.custom?.general?.name && entity.custom?.general?.name.length > 0 ? entity.custom?.general?.name : 'Memory'} - ${t('password.password-recovery', {ns: 'common'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const handleSubmit = (e) => {

    e.preventDefault()
    setloading(true)

    if ((newPassword && confirmPassword) || newPassword.length >= 8 || checkSpecialCaracter(newPassword) || newPassword === confirmPassword) {
      setloading(true)

      firebase.confirmPasswordReset(code, newPassword)
      .then(function() {
        dispatch( 
          PlayNotifications( 
            {
              display:true,
              theme:"success",
              message: t('success.modified_withVal', {ns: 'messages', val: t('password.Password', {ns: 'common'})}),
            }
          ) 
        )
        // Redirection page login
        history.push("/catalogue/")
        history.push("/connexion/")
        setloading(false)
      })
      .catch(function() {
        dispatch( 
          PlayNotifications( 
            {
              display: true,
              theme: "danger",
              message: t('error.unknown-error-try-again-later', {ns: 'messages'}),
            }
          ) 
        )
        setloading(false)
      })
    }
  }

  useEffect(() => {

    /* Get parameters in URL */
    function getParams() {
      const searchParams = new URLSearchParams(window.location.search)
      return {
        procedureMode: searchParams.get('mode' || '',),
        procedureCode: searchParams.get('oobCode' || '',),
        procedureApiKey: searchParams.get('apiKey' || '',),
        procedureLang: searchParams.get('lang' || '',),
      }
    }
    const params = getParams(window.location.search)

    if (params.procedureMode === "resetPassword"){
      firebase.verifyPasswordResetCode(params.procedureCode)
      .then((email) => {
        setcode(params.procedureCode)
        dispatch( 
          PlayNotifications( 
            {
              display:true,
              theme:"success",
              message: t('success.enter-new-password', {ns: 'messages'}),
            }
          ) 
        )
        setready(true)
      })
      .catch((error) => {
        console.error(error.code)
        let errorMessage
        if (error.code === "auth/expired-action-code") {
          errorMessage = t('error.expired-action-code', {ns: 'messages'})
        } else if (error.code === "auth/invalid-action-code") {
          errorMessage = t('error.password-change-procedure-invalid', {ns: 'messages'})
        }
        else errorMessage = t('error.something-wrong-try-again-later', {ns: 'messages'})
        dispatch(
          PlayNotifications( 
            {
              display: true,
              theme: "danger",
              message: errorMessage,
              delay: 9000
            }
          ) 
        )
        setRedirectAction(true)
      })
    }
    else {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if( redirectAction===true ){
      history.push('/mot-de-passe-perdu/')
    }
  }, [redirectAction])

  if (!ready){
    return(
      <div className="h-screen flex flex-col items-center justify-center">
        <img src={Logo} alt="" className="w-20 h-20 mb-2" />
        <BarLoader color={"#504fe4"} height={3} width={120} />
          {!redirectAction ? 
            <span className="text-sm text-grayPrimary opacity-70 mt-1 flex items-center">
              <span>{t('Please-wait', {ns: 'common'})}</span> 
              <RiTimerLine className="w-4 h-4 ml-1" />
            </span>
          :
            <Button
              action='/mot-de-passe-perdu/'
              icon={<RiLockUnlockLine className="h-4 w-4 mr-1" />}
              className="mt-5"
              theme="simple"
            >
              {t('password.change-password', {ns: 'common'})}
            </Button>
          }
      </div>
    )
  }
  else{
    return (
      <div className="h-screen flex flex-col">
        {/*<Navigation mainColor={entityMainColor} secondaryColor={entitySecondaryColor} />*/}
        <div className="h-full flex items-center justify-center">
          <div className="w-full h-full px-7">
            <div className="max-w-md mx-auto relative h-full md:py-36 flex flex-col justify-around">
              <div>
                <h4
                  className="text-center mb-4"
                >
                  {t('password.password-recovery', {ns: 'common'})}
                </h4>

                <p className="text-xs text-grayPrimary text-center">
                {t('password.minimum-length-password', {ns: 'common'})}
                </p>
                <span className="flex items-center justify-center text-grayPrimary text-xs mt-2 animate">
                  {newPassword.length < 8 ? <RiCheckboxBlankCircleLine className="h-3 w-3 mr-1" /> : <RiCheckboxCircleLine className="w-4 h-4 mr-1 text-greenPrimary" />}
                  {t('password.height-characters', {ns: 'common'})}
                </span>
                <span className="flex items-center justify-center text-grayPrimary text-xs mt-1">
                  {!checkSpecialCaracter(newPassword) ? <RiCheckboxBlankCircleLine className="h-3 w-3 mr-1" /> : <RiCheckboxCircleLine className="w-4 h-4 mr-1 text-greenPrimary" />}
                  {t('password.one-special-character', {ns: 'common'})}
                </span>                  
 
                <form onSubmit={handleSubmit} className="max-w-xs mx-auto mt-8">
                  <InputPassword
                    state={newPassword} 
                    setstate={setnewPassword}
                    className="mb-4"
                    placeOlder={t('password.new-password', {ns: 'common'})}
                    inputClassName={newPassword.length !== 0 && (newPassword.length <= 8 || !checkSpecialCaracter(newPassword)) ? 'border border-redPrimary' : null}
                  />  
                  <InputPassword 
                    state={confirmPassword} 
                    setstate={setconfirmPassword}
                    placeOlder={t('password.confirmation-new-password', {ns: 'common'})}
                    inputClassName={newPassword !== confirmPassword ? 'border border-redPrimary' : null}
                  />  

                  <Button
                    actionType="form" 
                    className="mx-auto mt-4"
                    theme={(!newPassword && !confirmPassword) || newPassword.length < 8 || !checkSpecialCaracter(newPassword) || newPassword !== confirmPassword ? 'disabled' : null}
                    loading={loading}
                    icon={<RiCheckLine className="mr-1" />}
                  >
                    {t('action.Validate', {ns: 'common'})}
                  </Button>
                </form>
              </div>
              <div>
                <a href="mailto:contact@my-memory.io"
                  className="text-sm text-grayPrimary block text-center mt-2"
                >
                  <span className="border-b">{t('action.Contact-an-administrator', {ns: 'common'})}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }
}

export default ChangePasswordProcedure
