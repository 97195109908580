import React, {forwardRef, useEffect, useRef, useState} from "react"
import { useTranslation } from "react-i18next"
// Components
import {RiCloseLine, RiSearch2Line} from "react-icons/ri"
// Utils
import useOnclickOutside from "react-cool-onclickoutside"
import CheckBox from "../checkBox/CheckBoxSimple";


const InputSearch = forwardRef((props, inputRef) => {

  // Translations
  const { t } = useTranslation('common')

  const {
    className,
    clear = false,
    clickOutsideListener = false,
    disableChangeListener = false,
    getItemObject,
    handleClickItem,
    infiniteScroll = false,
    inputPadding = 'pr-4',
    inputFullSize = false,
    label,
    labelHidden,
    loading,
    loadingSuggestions,
    multiSelect = false,
    name,
    onFocus,
    placeHolder,
    renderItemText,
    required,
    selectAction,
    selectedItems = [],
    selection,
    setClear,
    setstate,
    shadow = false,
    state,
    suggest = false,
    suggestions,
    suggestionsHeight = 'max-h-60',
    unselectAction,
  } = props


  // const inputRef = useRef()
  const changeListenerTimeout = useRef(null)

  const [keyword, setKeyword] = useState(state)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])

  useEffect(() => {
    if( Array.isArray(suggestions)){
      let newSuggestions = suggestions
      if (selection && selection.length > 0) {
        newSuggestions = newSuggestions.filter((item) => !selection.includes(item.id))
      }
      setFilteredSuggestions(newSuggestions)
    }
  }, [suggestions, selection])

  useEffect(() => {
    setKeyword(state)
  }, [state])

  const clearValueClick = () => {
    clearValue()
  }

  const clearValue = () => {
    setClear()
  }

  const renderDefaultItemText = (item) => {
    if( renderItemText ){
      return renderItemText(item)
    }

    return item.name ? item.name : ''
  }

  const getDefaultItemObject = (item) => {
    if( getItemObject ){
      return getItemObject(item)
    }

    return item
  }

  const handleDefaultClickItem = (obj) => {
    if( handleClickItem ){
      handleClickItem(obj)
    } else {
      console.debug('handler is missing')
    }
  }

  const onFocusDefault = (e) => {
    if(onFocus){
      onFocus(e)
    }
  }

  // Closure management clickOutSide
  const refCloseSuggestions = useOnclickOutside((e) => {
    if( !clickOutsideListener ){
      return false
    }

    if( (!e.target.name && !e.target.id) || (
      e.target.name !== `${name}` && e.target.name !== `button-${name}` && e.target.id !== `container-${name}` && e.target.id !== `suggestions-${name}`
    )){
      setClear && setClear()
    }
  })

  if(inputRef?.current) {
    let input = inputRef.current
    const textLength = input.getAttribute('placeholder').length
    let inputSize

    if(textLength < 40) inputSize = textLength - 2
    else inputSize = textLength - 6
    
    input.setAttribute('size',inputSize)
  }

  const handleCheck = (e, object) => {
    if (e.target.checked) {
      selectAction && selectAction(object)
    } else {
      unselectAction && unselectAction(object)
    }
  }

  const isChecked = (prop, value) => {
    let isChecked = false
    if(selectedItems && selectedItems.length > 0){
      selectedItems.forEach(item => {
        if (typeof item === 'object') {
          if (item[prop] === value) {
            isChecked = true
          }
        } else if (item === value) {
          isChecked = true
        }
      })
    }
    return isChecked
  }

  const onChangeHandler = (e) => {
    setKeyword(e.target.value)

    if( disableChangeListener === false ){
      if (changeListenerTimeout.current) {
        clearTimeout(changeListenerTimeout.current)
      }

      changeListenerTimeout.current = setTimeout(() => {
        setstate(e)
        clearTimeout(changeListenerTimeout.current)
      }, 200);
    }
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      setstate(e)
    }
  }
  
  return (
    <div className={`${className}`}>
      {labelHidden ? null :
        <div className="flex items-center justify-between mb-1">
          <label htmlFor={name} className="block text-sm font-medium">
            {label}{required ? ' *' : null}
          </label>
        </div>
      }

      <div
        id={`container-${name}`}
        ref={refCloseSuggestions}
        className={`relative rounded-md ${shadow === true ? 'shadow-sm' : ''} w-full ${inputFullSize!==true && 'sm:w-fit'} z-10`}
        // className={`relative bg-white flex items-center gap-2 w-full ${inputFullSize!==true && 'sm:w-fit'} pl-4 border border-grayLight rounded shadow-sm z-10`}
      >
        {/*<RiSearch2Line className="w-4 h-4 min-w-4 sm:text-sm text-black pointer-events-none" />*/}
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="sm:text-sm  text-black">
            <RiSearch2Line className="w-4 h-4" />
          </span>
        </div>

        <input
          ref={inputRef}
          autoComplete="off"
          className={`block w-full h-full pl-8 ${inputPadding} sm:text-sm border border-gray-300 rounded-md`}
          //className={`block h-8 text-sm p-0 border-0 rounded sm:text-base ${inputFullSize===true ? 'w-full' : ''}`}
          disabled={loading}
          id={name}
          name={name}
          onChange={(e) => onChangeHandler(e)}
          onFocus={(e) => onFocusDefault(e)}
          onKeyPress={(e) => handleKeyPress(e)}
          placeholder={placeHolder}
          type="text"
          value={keyword}
        />

        {(state && clear) ?
          <span
            onClick={() => clearValueClick()}
            className="absolute inset-y-0 right-0 flex items-center cursor-pointer"
          >
            <div className="absolute z-10 inset-y-0 right-0 p-3 flex items-center">
              <span className="text-black sm:text-sm" >
                <RiCloseLine className="w-4 h-4" />
              </span>
            </div>
          </span>
        : null}

        {/* results list */}
        { (suggest && suggestions) &&
          <div
            id={`suggestions-${name}`}
            className={`absolute top-10 left-0 bg-white w-full shadow ${suggestionsHeight} overflow-auto shadow border mt-2 rounded ${loadingSuggestions ? 'text-gray-400' : 'text-gray-700'}`}
          >
            { filteredSuggestions?.length > 0 ?
              filteredSuggestions.map(item => {
                if(multiSelect === true) {
                  return (
                    <div key={item.id} className="text-sm font-semibold px-5 py-1 hover:bg-gray-100">
                      <CheckBox
                        className="mr-5"
                        id={`item-${item.id}`}
                        checked={isChecked('id', item.id)}
                        setstate={(e) => handleCheck(e, getDefaultItemObject(item))}
                        value={item.id}
                        name={`button-${item.id}`}
                      >
                        {renderDefaultItemText(item)}
                      </CheckBox>
                    </div>
                  )
                } else {
                  return (
                    <div key={item.id} className="text-sm font-semibold px-5 py-1 hover:bg-gray-100">
                      <button
                        onClick={() => handleDefaultClickItem(getDefaultItemObject(item))}
                        value={item.id}
                        name={`button-${name}`}
                        className="hover:text-grayPrimary animate text-left w-full"
                        disabled={loadingSuggestions}
                      >
                        {renderDefaultItemText(item)}
                      </button>
                    </div>
                  )
                }
              })
            :
              <div key={'no_results'} className="text-sm font-semibold p-4">
                <p>{t('No-result')}</p>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
})

export default InputSearch