// LOCALES
import {FlagIcon} from "react-flag-kit";

export const LOCALES = ['en_GB', 'fr_FR']
export const LOCALES_OBJECT = [
  // {
  //   code: 'global',
  //   name_locale: {key: 'locales.global', ns: 'common'},
  //   localized: false,
  //   icon: <RiGlobalLine className="w-5 h-5" />
  // },
  {
    code: 'en_GB',
    name: 'english',
    name_locale: {key: 'locales.en_GB', ns: 'common'},
    localized: true,
    icon: <FlagIcon code="GB" className="w-5" />
  },
  {
    code: 'fr_FR',
    name: 'français',
    name_locale: {key: 'locales.fr_FR', ns: 'common'},
    localized: true,
    icon: <FlagIcon code="FR" className="w-5" />
  }
]

//ANALYTICS
export const TRACKING_ID = 'G-PH3EEMDL9L'

// CRISP
export const CRISP_WEBSITE_ID = '0f144f1d-1c40-456e-9521-42de9d13e02e'

// Metrics
export const NB_OF_SKELETON_CARDS = 12
export const NB_OF_CARDS_PLAYLIST_PREVIEW = 21
export const NB_OF_CARDS_PLAYLIST_PREVIEW_GRID = 20
export const NB_OF_CARDS_LAST_CONTENTS_PREVIEW = 10
export const NB_OF_CARDS_LAST_CONTENTS_PREVIEW_GRID = 9
export const NB_MAX_RELATED_PROJECT = 9
export const NB_MAX_FILES = 5
export const NB_MAX_GALLERY_IMG = 10
export const DEFAULT_RESULTS_PER_PAGES = 12
export const MAX_RESULTS_PER_PAGES = 51
export const MAX_FILE_SIZE_GALLERY = {size: 15000000, displaySize: '15', displayUnit: 'Mo', displayUnitKey: 'size.Mo'}
export const MAX_FILE_SIZE_DOCUMENTS = {size: 50000000, displaySize: '50', displayUnit: 'Mo', displayUnitKey: 'size.Mo'}
export const MAX_FILE_SIZE_IMPORT = {size: 1000000, displaySize: '1', displayUnit: 'Mo', displayUnitKey: 'size.Mo'}
export const FAVICON_REQUIRED_WIDTH = 64
export const FAVICON_REQUIRED_HEIGHT = 64
export const AVATAR_MAX_WIDTH = 320
export const AVATAR_MAX_HEIGHT = 320

// Crisp
export const Z_INDEX_CRISP_CHATBOX = 1000000

// Drag type 
export const DRAG_TYPE_GALLERY_UPLOAD = 'DRAG_TYPE_GALLERY_UPLOAD'
export const DRAG_TYPE_GALLERY_IMG = 'DRAG_TYPE_GALLERY_IMG'
export const DRAG_TYPE_MODEL_FORM = 'DRAG_TYPE_MODEL_FORM'

// Available extension React-doc-viewer
export const DOC_VIEWER_AVAILABLE_EXTENSIONS = ['csv', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'jpg', 'jpeg', 'png', 'tiff']

// EDITOR
export const DEFAULT_CHARACTER_LIMIT_EDITOR = 2000

// SUB NAVIGATION
export const NAV_LINK = 'NAV_LINK'
export const NAV_LINK_ROUTER = 'NAV_LINK_ROUTER'
export const NAV_ACTION = 'NAV_ACTION'
export const NAV_EMPTY = 'NAV_EMPTY'

// Sharing
export const FACEBOOK = 'FACEBOOK'
export const TWITTER = 'TWITTER'
export const LINKEDIN = 'LINKEDIN'
export const INSTAGRAM = 'INSTAGRAM'
export const PINTEREST = 'PINTEREST'
export const LINK = 'LINK'
export const SHARE_OPTIONS = [FACEBOOK, TWITTER, LINKEDIN, LINK]

// MODEL TYPE
export const PARENT = 'parent'
export const CHILD = 'child'
export const SINGLE = 'single'

// DATE FORMAT
export const DATE_DD_MM_YYYY = 'DD/MM/YYYY'
export const DATE_YYYY_MM_DD = 'YYYY/MM/DD'

// Help
export const DEFAULT_HELP_LINK = 'https://beecomeio.notion.site/Aide-79990749300348dba7f8f1724347f4f0'

// Theme
export const DEFAULT_MAIN_COLOR = 'memoryBluePrimary'
export const DEFAULT_SECONDARY_COLOR = 'memoryBlueSecondary'
export const DEFAULT_MAIN_COLOR_HEXA = '504FE4'
export const DEFAULT_SECONDARY_COLOR_HEXA = '272670'

export const DEFAULT_COLORS_CODE = {
  memoryBluePrimary: '504FE4',
  memoryBlueSecondary: '272670',
  memoryGrayPrimary: '5F6163',
  memoryGraySecondary: '3e3f42',
  memoryMarsPrimary: 'fe7e58',
  memoryMarsSecondary: 'db3b0a',
  memoryUranusPrimary: '11aca1',
  memoryUranusSecondary: '01746c',
}

export const EMBED_VIDEO_PROVIDER = ['acast', 'apple', 'ausha', 'dailymotion', 'deezer', '.ephoto.fr', 'facebook', 'mixcloud', 'soundcloud', 'spotify', 'ted', 'twitch', 'ubicast', 'vimeo', 'wistia', 'youtube']
export const EMBED_AUDIO_VIDEO_PROVIDER_NAME = ['Acast', 'Apple', 'Ausha', 'Dailymotion', 'Ephoto Dam','Deezer', 'Soundcloud', 'Spotify', 'Ted', 'Twitch', 'Ubicast', 'Vimeo', 'Youtube' ];
export const EMBED_VIDEO_PROVIDER_NAME = ['Dailymotion', 'Ephoto Dam', 'Ted', 'Twitch', 'Ubicast', 'Vimeo', 'Youtube' ];
export const EMBED_MEDIA_PROVIDER_NAME = ['Brevo', 'Ephoto Dam', 'Genially', 'Google Slides', 'Instagram', 'Linkedin', 'Mailjet', 'TikTok', 'X (Twitter)'];


export const TITLE_FONT_SIZE_LIST = [
  {id:'text-base', tradKey: `text.Size-base`},
  {id:'text-lg', tradKey: `text.Size-lg`},
  {id:'text-xl', tradKey: `text.Size-xl`},
  {id:'text-2xl', tradKey: `text.Size-2xl`},
  {id:'text-3xl', tradKey: `text.Size-3xl`},
  {id:'text-4xl', tradKey: `text.Size-4xl`},
  {id:'text-5xl', tradKey: `text.Size-5xl`},
  {id:'text-6xl', tradKey: `text.Size-6xl`},
  {id:'text-7xl', tradKey: `text.Size-7xl`},
  {id:'text-8xl', tradKey: `text.Size-8xl`},
  {id:'text-9xl', tradKey: `text.Size-9xl`},
];

export const TEXT_FONT_SIZE_LIST = [
  {id:'text-xs', tradKey: `text.Size-xs`},
  {id:'text-sm', tradKey: `text.Size-sm`},
  {id:'text-base', tradKey: `text.Size-base`},
  {id:'text-lg', tradKey: `text.Size-lg`},
  {id:'text-xl', tradKey: `text.Size-xl`},
  {id:'text-2xl', tradKey: `text.Size-2xl`},
  {id:'text-3xl', tradKey: `text.Size-3xl`},
  {id:'text-4xl', tradKey: `text.Size-4xl`},
  {id:'text-5xl', tradKey: `text.Size-5xl`},
  {id:'text-6xl', tradKey: `text.Size-6xl`},
  {id:'text-7xl', tradKey: `text.Size-7xl`},
  {id:'text-8xl', tradKey: `text.Size-8xl`},
  {id:'text-9xl', tradKey: `text.Size-9xl`},
];

export const TEXT_BANNER_VERTICAL_PADDING = [
  {id:'padding-0', value: '0', tradKey: `0`},
  {id:'padding-8', value: '2rem', tradKey: `8`},
  {id:'padding-16', value: '4rem', tradKey: `16`},
  {id:'padding-24', value: '6rem', tradKey: `24`},
  {id:'padding-32', value: '8rem', tradKey: `32`},
  {id:'padding-40', value: '10rem', tradKey: `40`},
  {id:'padding-48', value: '12rem', tradKey: `48`},
  {id:'padding-52', value: '13rem', tradKey: `52`}
]